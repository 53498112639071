import * as ActionTypes from '../../types/general';
const initialState = {
  propertyData: {},
  profileData: {},
  profilePic: null,
  accountData: {},
  tenantData: {},
  rentalUnitData: {},
  rentData: [],
  token: null,
  isMobile: false,
  isTablet: false,
  loader: false,
  skeletonLoader: false
};

export const generalReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_TOKEN:
      return { ...state, token: action.payload };

    case ActionTypes.SET_ACCOUNT_DATA:
      return { ...state, accountData: action.payload };

    case ActionTypes.SET_PROFILE_DATA:
      return { ...state, profileData: action.payload };

    case ActionTypes.SET_PROFILE_PIC:
      return { ...state, profilePic: action.payload };

    case ActionTypes.SET_IS_MOBILE:
      return { ...state, isMobile: action.payload };

    case ActionTypes.SET_IS_TABLET:
      return { ...state, isTablet: action.payload };

    case ActionTypes.SET_LOADER:
      return { ...state, loader: action.payload };
    
    case ActionTypes.SET_SKELETON_LOADER:
      return { ...state, skeletonLoader: action.payload };

    case ActionTypes.SET_TENANT_DATA:
      return { ...state, tenantData: action.payload };

    case ActionTypes.SET_RENTAL_UNIT_DATA:
      return { ...state, rentalUnitData: action.payload };

    case ActionTypes.SET_PROPERTY_DATA:
      return { ...state, propertyData: action.payload };

    case ActionTypes.SET_RENT_DATA:
      return { ...state, rentData: action.payload };

    default:
      return state;
  }
};
