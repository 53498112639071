
let api_base_url = 'https://dev.hellohousing.de/api/';
if(window.location.hostname.includes('app')){
    api_base_url='https://app.hellohousing.de/api/'
}

export const api_config = {
    baseUrl: api_base_url,
    authorization: {
        signin: {
            signin_post: 'auth-api/signIn',
            reset_password_post: 'auth-api/signIn/resetPassword',
            password_instructions_post: 'auth-api/signIn/TenantPasswordInstructions',
            tenant_reset_password_post: 'auth-api/signIn/TenantResetPassword',
            tenant_activation_status_post: 'auth-api/signIn/TenantActivationStatus',
            email_verification_instructions_post: 'auth-api/signIn/EmailVerificationInstructions',
            verify_email_post: 'auth-api/signIn/VerifyEmail'
        },
        signup: {
            account_post: '',
            subscription_put: '',
            account_details_put: '',
            authorize_payment_put: '',
            payment_status_get: '',
            activate_account_put: '',
            check_access_token_get: '',
            check_passcode_get: ''
        }
    },
    user_management: {
        account: {
            account_get: 'account-api/accounts/'
        },
        user: {
            user_get: 'user-api/users/',
            user_put: 'user-api/users/'
        },
        messages: {
            messages_get: 'user-api/tenant-portal/messages'
        }   
    },
    meter_management: {
        tenant_portal: {
            tenant_consumption_series: 'meter-api/tenant-portal/tenantConsumptionSeries',
            tenant_consumption: 'meter-api/tenant-portal/tenantConsumption',
            energy_efficiency_levels: 'meter-api/tenant-portal/energyEfficiencyLevels'
        }
    },
    property_management: {
        property: {
            //properties_get: 'property-api/properties',
            property_by_rental_unit_id: 'property-api/tenant-portal/properties'
        },
        rental_unit: {
            rental_units_get: 'property-api/rentalUnits'
        },
        tenant: {
            tenants_get: 'property-api/tenants'
        },
        rent: {
            rent_get: 'property-api/rents'
        },
        utilityBilling: {
            current_billing_period_get: 'property-api/currentBillingPeriod',
            last_billing_period_get: 'property-api/lastBillingPeriod'
        },
        notes: {
            notes_get: 'property-api/tenant-portal/notes'
        }
    },
    document_management: {
        documents: {
            documents_get: 'storage-api/tenant-portal/documents',
            document_get: 'storage-api/documents/'
        }
    }
  };