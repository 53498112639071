import * as Actiontypes from '../../types/general';

export const set_token =(data)=>{
  return {
    type: Actiontypes.SET_TOKEN,
    payload: data,
  };
}

export const set_account_data =(data)=>{
  return {
    type: Actiontypes.SET_ACCOUNT_DATA,
    payload: data,
  };
}

export const set_profile_data = (data) => {
  return {
    type: Actiontypes.SET_PROFILE_DATA,
    payload: data,
  };
};

export const set_profile_pic = (data) => {
  return {
    type: Actiontypes.SET_PROFILE_PIC,
    payload: data,
  };
};

export const set_is_mobile = (data) => {
  return {
    type: Actiontypes.SET_IS_MOBILE,
    payload: data,
  };
};

export const set_is_tablet = (data) => {
  return {
    type: Actiontypes.SET_IS_TABLET,
    payload: data,
  };
};

export const set_loader = (data) => {
  return {
    type: Actiontypes.SET_LOADER,
    payload: data,
  };
};

export const set_skeleton_loader = (data) => {
  return {
    type: Actiontypes.SET_SKELETON_LOADER,
    payload: data,
  };
};

export const set_tenant_data = (data) => {
  return {
    type: Actiontypes.SET_TENANT_DATA,
    payload: data,
  };
};

export const set_rental_unit_data = (data) => {
  return {
    type: Actiontypes.SET_RENTAL_UNIT_DATA,
    payload: data,
  };
};

export const set_property_data = (data) => {
  return {
    type: Actiontypes.SET_PROPERTY_DATA,
    payload: data,
  };
};

export const set_rent_data = (data) => {
  return {
    type: Actiontypes.SET_RENT_DATA,
    payload: data,
  };
};