import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Login from './MainLogin';
import MainApp from './MainApp';
import { check_auth } from '../helpers/Uitls';
import {set_is_mobile, set_is_tablet, set_loader, set_skeleton_loader} from '../redux/action/General/generalActions';
import { LoadingOverlay } from '@mantine/core';
import customLoader from './components/HelperComponents/CustomLoader';

const TABLET_SIZE = 1200;
const MOBILE_SIZE = 500;

class Main extends Component {
  state={
    isTablet: window.innerWidth <= TABLET_SIZE,
    isMobile: window.innerWidth <= MOBILE_SIZE,
  }
  constructor(props) {
    super(props);
    const {profile, account, token}= props;

    if(!account.accountId && !profile.userId && !token){
      localStorage.setItem('redirectUrl', window.location.pathname.toString());
    }
    console.log('window.location.hostname: ', window.location.href.includes('app.hellohousing-mieterportal.de'));
    if(!window.location.href.includes('app.hellohousing-mieterportal.de')){
      let element = `<meta name="robots" content="noindex">`;
      document.head.insertAdjacentHTML("beforeend", element);
    }
  }

  componentDidMount() {
    this.props.set_loader(false);
    this.props.set_skeleton_loader(false);
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    if (window.innerWidth <= TABLET_SIZE) {
      this.setState({ isTablet: true });
      this.props.set_is_tablet(true);
    } else {
      this.setState({ isTablet: false });
      this.props.set_is_tablet(false);
    }

    if (window.innerWidth <= MOBILE_SIZE) {
      this.setState({ isMobile: true });
      this.props.set_is_mobile(true);
    } else {
      this.setState({ isMobile: false });
      this.props.set_is_mobile(false);
    }
  };

  render() {
    const {profile, account, token, loader}= this.props;
    return (
      <div>
        <LoadingOverlay loader={customLoader} style={{height: '100%'}} overlayColor={'black'} overlayOpacity={0.5} visible={loader}/>
        {account.accountId && profile.userId && token ?
        <BrowserRouter>
          <MainApp/>
        </BrowserRouter>
        :
        <BrowserRouter>
          <Login/>
        </BrowserRouter>}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.generalReducer.profileData,
    account: state.generalReducer.accountData,
    token: state.generalReducer.token,
    isMobile: state.generalReducer.isMobile,
    isTablet: state.generalReducer.isTablet,
    loader: state.generalReducer.loader,
  };
};

const actions = {
  set_is_mobile,
  set_is_tablet,
  set_loader,
  set_skeleton_loader
};

export default connect(mapStateToProps, actions)(Main);