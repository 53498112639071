import { Skeleton, Stack } from '@mantine/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import HeadingContainer from '../../components/CommonComponents/HeadingContainer';
import MessagesIcon from '../../../assests/svg/MessagesIcon.svg';
import EmptyState from '../../components/CommonComponents/EmptyState';
import MessagesList from '../../components/CommonComponents/Messages/MessagesList';
import { MessagesGet } from '../../../helpers/fetchApis/Messages';
const staticMessageData=[
    {
        id: "dcd6f833-3074-4428-a898-08dc006fa5ef",
        accountId: "20fc8497-bd0c-4b7f-9293-08dace2f6681",
        propertyId: "b9d1c5c8-23c7-4c6e-a463-5fdec5458d3c",
        utilityBillingId: "d1eadc1d-6fd8-4ec2-87f0-c1a96e6405a2",
        senderId: "192931ae-b714-4152-53de-08dace2f666d",
        subject: "<p>Betriebskostenabrechnung 01.01.2022 - 31.12.2022</p>\n",
        body: "<p><strong>Wohnung:</strong> {{RENTAL_UNIT_NAME}}, {{PROPERTY_STREET}}, {{PROPERTY_ZIPCODE}} {{PROPERTY_CITY}} <br><strong>Abrechnungszeitraum:</strong> {{BILLING_PERIOD}}</p>\n<p>Sehr geehrte/r  {{TENANT_NAME}},&nbsp;</p>\n<p>anbei übersenden wir Ihnen die Betriebskostenabrechnung für die Betriebskostenperiode {{BILLING_PERIOD}}. Die Einzelheiten entnehmen Sie bitte der beigefügten Anlage.</p>\n<ul>\n<li><strong>Ihr Anteil an den Betriebskosten</strong>: {{TOTAL_ALLOCATED_UTILITY_COSTS}}</li>\n<li><strong>Ihre Vorauszahlungen</strong>: {{TOTAL_ADVANCE_PAYMENTS}}</li>\n<li><strong>Ihr Guthaben (+) / Ihre Nachzahlung (-)</strong>: {{BILLING_STATEMENT_BALANCE}}</li>\n</ul>\n<p>Bei Nachzahlung bitten wir um Erstattung innerhalb der nächsten vier Wochen auf das Ihnen bekannte Konto. Ein Guthaben werden wir Ihnen innerhalb der nächsten vier Wochen auf Ihr Konto gutschreiben.</p>\n<p>Mit freundlichen Grüßen</p>\n",
        status: "Publish",
        updatedOn: "2023-12-19T14:09:22.4647223"
    },
    {
        id: "e9d908da-4862-4a48-7f70-08dbf6993c0f",
        accountId: "20fc8497-bd0c-4b7f-9293-08dace2f6681",
        propertyId: "b9d1c5c8-23c7-4c6e-a463-5fdec5458d3c",
        utilityBillingId: "d1eadc1d-6fd8-4ec2-87f0-c1a96e6405a2",
        senderId: "192931ae-b714-4152-53de-08dace2f666d",
        subject: "<p>Betriebskostenabrechnung <a href=\"\" target=\"_self\">Abrechnungszeitraum</a></p>\n",
        body: "<p>Wohnung: <a href=\"\" target=\"_self\">Mieteinheit</a>, <a href=\"\" target=\"_self\">Objektadresse</a> <br>Abrechnungszeitraum: <a href=\"\" target=\"_self\">Abrechnungszeitraum</a></p>\n<p>Sehr geehrte/r  <a href=\"\" target=\"_self\">Mieter</a>,&nbsp;</p>\n<p>anbei übersenden wir Ihnen die Betriebskostenabrechnung für die Betriebskostenperiode <a href=\"\" target=\"_self\">Abrechnungszeitraum</a>. Die Einzelheiten entnehmen Sie bitte der<br>beigefügten Anlage.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>\n<ul>\n<li><strong>Ihr Anteil an den Betriebskosten</strong>: <a href=\"\" target=\"_self\">Betriebskosten</a></li>\n<li><strong>Ihre Vorauszahlungen</strong>: <a href=\"\" target=\"_self\">Vorauszahlungen</a></li>\n<li><strong>Ihr Guthaben (+) / Ihre Nachzahlung (-)</strong>: <a href=\"\" target=\"_self\">Guthaben / Nachzahlung</a></li>\n</ul>\n<p>Bei Nachzahlung bitten wir um Erstattung innerhalb der nächsten vier Wochen auf das Ihnen bekannte Konto. Ein Guthaben werden wir Ihnen innerhalb der nächsten vier Woche auf Ihr Konto gutschreiben.</p>\n<p>Mit freundlichen Grüßen</p>\n",
        status: "Publish",
        updatedOn: "2023-12-19T10:33:02.5546232"
    },
    {
        id: "aec37b36-d935-43ef-a89d-08dc006fa5ef",
        accountId: "20fc8497-bd0c-4b7f-9293-08dace2f6681",
        propertyId: "b9d1c5c8-23c7-4c6e-a463-5fdec5458d3c",
        utilityBillingId: "7bf83d90-2607-4d86-9bc8-30ec23d7353b",
        senderId: "192931ae-b714-4152-53de-08dace2f666d",
        subject: "<p>Betriebskostenabrechnung 01.01.2023 - 31.12.2023</p>\n",
        body: "<p><strong>Wohnung:</strong> <a href=\"\" target=\"_self\">Mieteinheit</a>, <a href=\"\" target=\"_self\">Objektadresse</a> <br><strong>Abrechnungszeitraum:</strong> <a href=\"\" target=\"_self\">Abrechnungszeitraum</a></p>\n<p>Sehr geehrte/r  <a href=\"\" target=\"_self\">Mieter</a>,&nbsp;</p>\n<p>anbei übersenden wir Ihnen die Betriebskostenabrechnung für die Betriebskostenperiode <a href=\"\" target=\"_self\">Abrechnungszeitraum</a>. Die Einzelheiten entnehmen Sie bitte der beigefügten Anlage.</p>\n<ul>\n<li><strong>Ihr Anteil an den Betriebskosten</strong>: <a href=\"\" target=\"_self\">Betriebskosten</a></li>\n<li><strong>Ihre Vorauszahlungen</strong>: <a href=\"\" target=\"_self\">Vorauszahlungen</a></li>\n<li><strong>Ihr Guthaben (+) / Ihre Nachzahlung (-)</strong>: <a href=\"\" target=\"_self\">Guthaben / Nachzahlung</a></li>\n</ul>\n<p>Bei Nachzahlung bitten wir um Erstattung innerhalb der nächsten vier Wochen auf das Ihnen bekannte Konto. Ein Guthaben werden wir Ihnen innerhalb der nächsten vier Wochen auf Ihr Konto gutschreiben.</p>\n<p>Mit freundlichen Grüßen</p>\n",
        status: "Publish",
        updatedOn: "2023-12-19T09:53:38.9821985"
    },
    {
        id: "f606ff10-e2f1-4f23-9ba8-08dbfa18b4de",
        accountId: "20fc8497-bd0c-4b7f-9293-08dace2f6681",
        propertyId: "b9d1c5c8-23c7-4c6e-a463-5fdec5458d3c",
        utilityBillingId: null,
        senderId: "192931ae-b714-4152-53de-08dace2f666d",
        subject: "<p>testing subjec</p>\n",
        body: "<p>testing body <a href=\"\" target=\"_self\">Mieteinhei</a></p>\n",
        status: "Draft",
        updatedOn: "2023-12-13T22:17:32.6915503"
    }
]
class Messages extends Component {
  
  state={
    messages: [],
    skeletonLoader: false
  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const {profile, tenant, rentalUnit, property, account} = this.props;
    console.log('acv: ', account, profile);
    //window.history.pushState({}, undefined, "/documents");
    this.getMessagesByUserId();
  }

  getMessagesByUserId=()=> {
    const {account, profile, property} = this.props;
    this.setState({skeletonLoader: true});
    try{
      MessagesGet(property.id, res=> {
        if(Array.isArray(res)){
          this.setState({messages: res, skeletonLoader: false});
        }else{
            if(res.status === 429){
              this.setState({skeletonLoader: true});
              setTimeout(() => {
                this.getMessagesByUserId();
              }, 3000);
            }else{
              this.setState({skeletonLoader: false});
            }
        }
      });
    }catch(e){
      this.setState({skeletonLoader: false});
    }
  }

  render() {
    const {isMobile, isTablet, tenant, rentalUnit, property, rent} = this.props;
    const {messages, skeletonLoader} = this.state;
    console.log('documentsData: ', messages);
    return (
      <Stack style={{height: '100%'}} spacing={0}>
        <HeadingContainer
            title={'Ihre Nachrichten'}
            icon={MessagesIcon}
            //showProfileImage
            />
        <div className='divider'/>
        <Stack style={{padding: '24px', height: '100%'}}>
            {skeletonLoader ?
            <Stack spacing={40}>
                <Skeleton style={{height: '36px', width: isMobile ? '100%' : isTablet ? '50%' : '35%'}}/>
                <Skeleton style={{height: '14px'}}/>
                <Stack spacing={10}>
                    <Skeleton style={{height: '18.6px', width: '100%'}}/>
                    <Skeleton style={{height: '71px', width: '100%'}}/>
                    <Skeleton style={{height: '71px', width: '100%'}}/>
                    <Skeleton style={{height: '71px', width: '100%'}}/>
                    <Skeleton style={{height: '71px', width: '100%'}}/>
                    <Skeleton style={{height: '71px', width: '100%'}}/>
                    <Skeleton style={{height: '71px', width: '100%'}}/>
                    <Skeleton style={{height: '71px', width: '100%'}}/>
                    <Skeleton style={{height: '71px', width: '100%'}}/>
                </Stack>
            </Stack>
            :
            messages.length > 0 ?
            <MessagesList messages={messages} skeletonLoader={skeletonLoader} isMobile={isMobile} isTablet={isTablet} />
            :
            <Stack style={{height: '100%'}} align={'center'}>
                <EmptyState
                icon={MessagesIcon}
                title={'Es sind noch keine Nachrichten vorhanden.'}
                subText={'Nachrichten, die Ihr Vermieter versendet, werden Ihnen hier angezeigt.'}
                />
            </Stack>}
        </Stack>
      </Stack>
    );
  }
}

const mapStateToProps = (state) => {
  return {
      profile: state.generalReducer.profileData,
      isMobile: state.generalReducer.isMobile,
      isTablet: state.generalReducer.isTablet,
      account: state.generalReducer.accountData,
      skeletonLoader: state.generalReducer.skeletonLoader,
      tenant: state.generalReducer.tenantData,
      rentalUnit: state.generalReducer.rentalUnitData,
      property: state.generalReducer.propertyData,
      rent: state.generalReducer.rentData
  };
};

export default connect(mapStateToProps)(Messages);
