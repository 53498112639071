import { Box, Group, Stack } from '@mantine/core';
import ConsumptionIcon from '../../../assests/svg/Consumption.svg';
import ProfileImage from '../../../assests/svg/TestImage.svg';
import Chart from 'chart.js/auto'
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import 'chartjs-plugin-style';
import GaugeChart from 'react-gauge-chart';
import { formatNumber } from '../../../helpers/Uitls';
import AssessmentCardIcon from '../../../assests/svg/assessmentCardIcon';


const AssessmentCard = (props) => {
    const [percent, setPercent] = useState(0);
    const [percent2, setPercent2] = useState(0);

    const getBackgroundColor=(value)=>{
        if(value === 'A' || value === 'A+'){
            return '#059266';
        }else if(value === 'B' || value === 'C'){
            return '#2CAF09';
        }else if(value === 'D' || value === 'E'){
            return '#BF941A';
        }else if(value === 'F'){
            return '#FF9500'
        }else if(value === 'G'){
            return '#FC8309'
        }else if(value === 'H'){
            return '#E8193C'
        }
    }
    var ctx = document.getElementById('acquisitions');
      useEffect(() => {
        setPercent(props.energyEfficiencyLevels.householdEnergyEfficiency / 250);
        setPercent2(props.energyEfficiencyLevels.tenantEnergyEfficiency / 250);
      }, [props.energyEfficiencyLevels]);
  return (
    <Stack className={props.isTablet ? 'assessment-card-tablet' : 'assessment-card'} spacing={'xs'}>
        <Group className='assessment-card-heading' align={'end'} spacing={'5px'}>
            <span className='assessment-card-heading-text'>Energieeffizienz</span><span className='assessment-card-sub-heading-text'>({`${dayjs(props.endDate).subtract(1, 'year').add(1, 'day').format('DD.MM.YYYY')} - ${dayjs(props.endDate).format('DD.MM.YYYY')}`})</span>
        </Group>
        
        <Group grow noWrap>
            <Stack style={{justifyContent: 'flex-end'}} align={'center'}>
                <Stack className='assessment-card-box-one' spacing={0} style={{ width: '341px', height: '208px'}}>
                    <GaugeChart 
                    id="gauge-chart1" 
                    nrOfLevels={1000} 
                    arcPadding={0} 
                    cornerRadius={0} 
                    colors={["#059266", "#BF941A", "#FF9500", "#FC8309", "#E8193C"]} 
                    percent={percent} 
                    percent2={percent2} 
                    needleColor={'#727293'}
                    needleBaseColor={'#D9D9D9'}
                    needle2Color={'#000000'}
                    needle2BaseColor={'#D9D9D9'}
                    hideText={true}
                    style={{height: '175px'}}
                    />
                    {/* <div><canvas style={{width: 'auto', cursor: 'pointer' }} id="acquisitions"></canvas></div> */}
                    {/* <div id='myChart'></div> */}
                    <Group grow spacing={'110px'}>
                        <Stack align={'center'} spacing={'2px'} style={{marginLeft: '-6px'}}>
                            <Group>
                                <span style={{fontSize: '12px', fontWeight: 'bold'}}>A+</span>
                            </Group>
                            <Group>
                                <span style={{fontSize: '8px', fontWeight: 'lighter'}}>(unter 30 kWh/m<sup>2</sup>)</span>
                            </Group>
                        </Stack>
                        <Stack align={'center'} spacing={'2px'} style={{marginRight: '-6px'}}>
                            <Group>
                                <span style={{fontSize: '12px', fontWeight: 'bold'}}>H</span>
                            </Group>
                            <Group>
                                <span style={{fontSize: '8px', fontWeight: 'lighter'}}>(über 250 kWh/m<sup>2</sup>)</span>
                            </Group>
                        </Stack>
                    </Group>
                </Stack>
                {props.energyEfficiencyLevels.householdEnergyEfficiency && props.energyEfficiencyLevels.tenantEnergyEfficiency ?
                <Group grow style={{alignItems: 'flex-end', width: '341px'}}>
                        <span style={{fontSize: '12px', fontWeight: 'bold', color: '#131358', textAlign: 'center'}}>Verbrauch {Math.round((Math.abs(props.energyEfficiencyLevels.householdEnergyEfficiency - props.energyEfficiencyLevels.tenantEnergyEfficiency) / props.energyEfficiencyLevels.householdEnergyEfficiency) * 100)}% {props.energyEfficiencyLevels.householdEnergyEfficiency < props.energyEfficiencyLevels.tenantEnergyEfficiency ? 'über' : 'unter'} dem Haus-Durchschnitt</span>
                </Group>
                :
                ''}
            </Stack>
            
            
            <Group position='center'>
                <Stack>
                    <Stack className='assessment-card-box-two'>
                        <Group spacing={'5px'}><div style={{border: '0.1px solid rgb(0, 0, 0, 0.45)', borderRadius: '50%'}}><div className='assessment-card-box-two-heading-circle'/></div><span className='assessment-card-box-two-heading-text'>Ihr Verbrauch (je m<sup>2</sup>)</span></Group>
                        <Group spacing={'5px'}>
                            <Group spacing={'xl'}>
                                {props.energyEfficiencyLevels.tenantEnergyEfficiency &&
                                <Group spacing={0}>
                                    <Group position='center' style={{height: '27px', width: '40px', backgroundColor: getBackgroundColor(props.energyEfficiencyLevels.tenantEnergyEfficiencyLevel)}}>
                                        <span className='assessment-card-icon-text'>{props.energyEfficiencyLevels.tenantEnergyEfficiencyLevel}</span>
                                    </Group>
                                    <Group position='center' className='arrow-right' style={{height: '27px', width: '5px', borderLeft: `5px solid ${getBackgroundColor(props.energyEfficiencyLevels.tenantEnergyEfficiencyLevel)}`}}/>
                                </Group>}
                                <div>
                                    <span className='consumption-card-value'>{props.energyEfficiencyLevels.tenantEnergyEfficiency ? Math.round(props.energyEfficiencyLevels.tenantEnergyEfficiency) : '-'}</span><span className='consumption-card-unit'>{'kWh'}</span>
                                </div>
                            </Group>
                        </Group>
                        <Group>
                            {props.energyEfficiencyLevels.tenantEnergyEfficiency ?
                            <span className='assessment-card-box-two-end-text'>Ihr Verbrauch liegt im {props.energyEfficiencyLevels.tenantEnergyEfficiencyLevel}-Bereich und damit {props.energyEfficiencyLevels.householdEnergyEfficiency < props.energyEfficiencyLevels.tenantEnergyEfficiency ? 'über' : 'unter'} dem Haus-Durchschnitt.</span>
                            :
                            <span className='assessment-card-box-two-end-text'>Es liegen noch keine Informationen zu Ihrem Verbrauchslevel vor.</span>}
                        </Group>
                    </Stack>
                    <Stack className='assessment-card-box-three'>
                        <Group spacing={'5px'}><div style={{border: '0.1px solid rgb(0, 0, 0, 0.45)', borderRadius: '50%'}}><div className='assessment-card-box-three-heading-circle' style={{opacity: '0.4'}}/></div><span className='assessment-card-box-three-heading-text'>Haus-Durchschnitt (je m<sup>2</sup>)</span></Group>
                        <Group spacing={'xl'}>
                            {props.energyEfficiencyLevels.householdEnergyEfficiency &&
                            <Group spacing={0}>
                                <Group position='center' style={{height: '27px', width: '40px', backgroundColor: getBackgroundColor(props.energyEfficiencyLevels.householdEnergyEfficiencyLevel)}}>
                                    <span className='assessment-card-icon-text'>{props.energyEfficiencyLevels.householdEnergyEfficiencyLevel}</span>
                                </Group>
                                <Group position='center' className='arrow-right' style={{height: '27px', width: '5px', borderLeft: `5px solid ${getBackgroundColor(props.energyEfficiencyLevels.householdEnergyEfficiencyLevel)}`}}/>
                            </Group>    }
                            <div>
                                <span className='consumption-card-value'>{props.energyEfficiencyLevels.householdEnergyEfficiency ? Math.round(props.energyEfficiencyLevels.householdEnergyEfficiency,  false) : '-'}</span><span className='consumption-card-unit'>{'kWh'}</span>
                            </div>
                        </Group>
                        <Group>
                            {props.energyEfficiencyLevels.householdEnergyEfficiency ?
                            <span className='assessment-card-box-three-end-text'>Der Durchschnitt beinhaltet alle Mietenden in Ihrem Haus.</span>
                            :
                            <span className='assessment-card-box-three-end-text'>Es liegen keine Informationen zum Haus-Durchschnitt vor.</span>
                            }
                        </Group>
                    </Stack>
                </Stack>
            </Group>
        </Group>
    </Stack>
  )
}

export default AssessmentCard;