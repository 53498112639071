import { Group, Progress, Stack, Tooltip } from '@mantine/core';
import ConsumptionIcon from '../../../assests/svg/Consumption.svg';
import ProfileImage from '../../../assests/svg/TestImage.svg';
import HighConsumption from '../../../assests/svg/HighConsumption.svg';
import LowConsumption from '../../../assests/svg/LowConsumption.svg';
import { color } from '@mui/system';

const ConsumptionCard = (props) => {

  return (
    <Stack className={props.isMobile ? 'consumption-card-mobile' : props.value.toString().length > 3 && props.heading === 'Einsparpotential' ? 'consumption-card-extra-width' : 'consumption-card'} spacing={'xs'}>
        <Group className='consumption-card-header'>
            {props.heading}
        </Group>
        <Group className='consumption-card-value-section' style={{justifyContent: 'space-between'}} noWrap={props.isMobile ? false : true}>
            <div>
                <span className='consumption-card-value'>{props.value}</span><span className='consumption-card-unit'>{props.unit}</span>
            </div>
            {props.heading !== 'Einsparpotential' ?
            props.percentage ?
                <Tooltip
                label={<span style={{fontSize: '12px', fontWeight: '500'}}>{`${props.percentage} ${props.highConsumption ? 'mehr als im Vorjahr' : 'weniger als im Vorjahr'}`}</span>}
                color="#e3e3fc"
                style={{color: '#000000'}}
                position="top-end"
                withArrow
                >
                    <div style={{background: props.highConsumption ? '#FFCBCB' : '#E8F4EA', display: 'flex', alignItems: 'center', height: '19px', padding: '0px 8px', justifyContent: 'space-between', borderRadius: '10px'}}>
                        <span style={{fontSize: '10px', fontWeight: '600', color: props.highConsumption ? '#E8193C' : '#059266', marginRight: '5px'}}>{`${props.highConsumption ? '+' : '-'}${props.percentage}`}</span>
                        <img src={props.highConsumption ? HighConsumption : LowConsumption}/>
                    </div>
                </Tooltip>
                
                :
                '-'
            :
            props.percentage ? 
                <div>
                    <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end', color: '#6F6FD3', fontWeight: '400'}}>{`${props.percentage}%`}</div>
                    <Progress className={props.isMobile ? 'consumption-card-progress-bar-mobile' : 'consumption-card-progress-bar'} style={{width: props.isMobile ? '150px' : '100px'}} size={'xs'} value={props.percentage}/>
                </div>
                : 
                '-'
            }
        </Group>
        <Group>
            {props.heading !== 'Einsparpotential' ? 
                props.percentage ?
                <span className='consumption-card-sub-text'>{`${props.percentage ? (props.highConsumption ? '+' : '-') : ''}${props.percentage ? props.percentage : '-'} ${props.percentage ? (props.highConsumption ? 'Anstieg' : 'Senkung') : ''} im Vergleich zum Vorjahr`}</span>
                :
                <span className='consumption-card-sub-text'>{'Es liegt kein Vorjahresverbrauch vor.'}</span>
            :
                props.percentage ?
                <span className='consumption-card-sub-text'>{`${props.percentage ? props.percentage+'%' : '-'} Einsparung möglich`}</span>
                :
                <span className='consumption-card-sub-text'>{'Es liegen keine Werte vor.'}</span>
            }
        </Group>
    </Stack> 
  )
}

export default ConsumptionCard;