import { Group, Stack } from '@mantine/core';
import DescriptionIcon from '@mui/icons-material/Description';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import MessagesIcon from '../../../assests/svg/MessagesIcon.svg';

import dayjs from 'dayjs';
import { useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import HeadingContainer from '../../components/CommonComponents/HeadingContainer';
import { api_config } from '../../../helpers/apis';
import { getAuthToken } from '../../../helpers/Uitls';
import { connect } from 'react-redux';

const MessageDetailView = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [hover, setHover] = useState(null);
    const {message} = location.state;
    const replaceValuesWithPlaceHolders=(html)=>{
        let newHtml= html;
    
        newHtml= newHtml.replaceAll('{{BILLING_PERIOD}}', '<a href="" target="_self">Abrechnungszeitraum</a>');
        newHtml= newHtml.replaceAll('{{TOTAL_ALLOCATED_UTILITY_COSTS}}', '<a href="" target="_self">Betriebskosten</a>');
        newHtml= newHtml.replaceAll('{{BILLING_STATEMENT_BALANCE}}', '<a href="" target="_self">Guthaben / Nachzahlung</a>');
        newHtml= newHtml.replaceAll('{{RECIPIENT_NAME}}', '<a href="" target="_self">Empfänger</a>');
        newHtml= newHtml.replaceAll('{{RENTAL_UNIT_NAME}}', '<a href="" target="_self">Mieteinheit</a>');
        newHtml= newHtml.replaceAll('{{PROPERTY_STREET}}, {{PROPERTY_ZIPCODE}} {{PROPERTY_CITY}}', '<a href="" target="_self">Objektadresse</a>');
        newHtml= newHtml.replaceAll('{{TOTAL_ADVANCE_PAYMENTS}}', '<a href="" target="_self">Vorauszahlungen</a>');
    
        return newHtml;
    }
    const downloadDocumentsById=(id, name)=>{
        fetch(api_config.baseUrl+'storage-api/documents/' + id + '/download',
        {
            method: 'GET',
            headers: {
            'Authorization': getAuthToken()
            }
        })
        .then(response => {
            response.blob()
            .then(data => {
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            
            let url = window.URL.createObjectURL(data);
            a.href = url;
            a.download = name;
            a.click();
            window.URL.revokeObjectURL(url);
            })
        }).catch((e)=> console.log('document_download_failure: ', e))
    }
    console.log('messageDetail: ', location);
    return (
        <Stack style={{height: '100%'}} spacing={0}>

            <HeadingContainer
            title={'Ihre Nachrichten'}
            icon={MessagesIcon}
            //showProfileImage
            rightButton={true}
            rightButtonText={'Zurück'}
            rightButtonClassName={'gray-btn'}
            rightButtonHandleClick={()=> navigate(-1)}
            />
            <div className='divider'/>
            <Stack style={{padding: '24px'}}>
                <Group>
                    <span className='message-card-time'>{dayjs(message.updatedOn).format('DD.MM.YYYY') === dayjs().format('DD.MM.YYYY') ? `heute (${dayjs(message.updatedOn).format('HH:mm')} Uhr)` : dayjs(message.updatedOn).format('DD.MM.YYYY')}</span>
                </Group>
                <Group><div className='message-card-title' dangerouslySetInnerHTML={{__html: message.subject}} /></Group>
                <Group style={{background: 'white', padding: '30px 20px', borderRadius: '4px', border: '1px solid rgb(218, 218, 229)', marginBottom: '10px'}}><div className='message-detail-body' dangerouslySetInnerHTML={{__html: message.body}} /></Group>
                {(message.templateType !== 'General' && message.documentId !== null) && <div className='divider' />}
                {(message.templateType !== 'General' && message.documentId !== null) &&
                <Stack spacing={20}>
                    <Group><span className='message-card-title'>1 Anhang</span></Group>
                    <Group>
                        <Group onClick={()=> downloadDocumentsById(message.documentId, `${message.subject.replace(/<\/?[^>]+(>|$)/g, "")}`)} onMouseEnter={()=> setHover('file1')} onMouseLeave={()=> setHover(null)} style={{cursor: 'pointer'}} spacing={5} justify={'center'} noWrap align='center'>
                            {hover === 'file1' ?
                            <SaveAltIcon 
                            style={{
                            color: '#5655FE',
                            fontSize: '20px'
                            }}/>
                            :
                            <DescriptionIcon 
                            style={{
                            color: '#5655FE',
                            fontSize: '20px'
                            }}/>}
                            <span className='message-card-title' style={{textOverflow: 'ellipsis', overflowX: 'hidden', whiteSpace: 'nowrap'}}>{`${message.subject.replace(/<\/?[^>]+(>|$)/g, "")}`}</span>
                        </Group>
                    </Group>
                </Stack>}
            </Stack>
        </Stack> 
    )
}

const mapStateToProps = (state) => {
    return {
        profile: state.generalReducer.profileData,
        isMobile: state.generalReducer.isMobile,
        isTablet: state.generalReducer.isTablet,
        account: state.generalReducer.accountData,
        skeletonLoader: state.generalReducer.skeletonLoader,
        tenant: state.generalReducer.tenantData,
        rentalUnit: state.generalReducer.rentalUnitData,
        property: state.generalReducer.propertyData,
        rent: state.generalReducer.rentData
    };
  };

export default connect(mapStateToProps)(MessageDetailView);