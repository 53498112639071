import logo from './logo.svg';
import './App.css';
import Main from './layouts/Main';
import { BrowserRouter } from 'react-router-dom';
import { MantineProvider } from '@mantine/core';
import Login from './layouts/MainLogin';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './store';
import { NotificationsProvider } from '@mantine/notifications';
function App() {
  return (
    <MantineProvider withGlobalStyles withNormalizeCSS theme={{ fontFamily: 'Inter, sans-serif' }}>
      <NotificationsProvider position="top-center" limit={2} zIndex={9999} autoClose={5000}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Main/>
          </PersistGate>
        </Provider>
      </NotificationsProvider>
    </MantineProvider>
  );
}

export default App;
