import { Skeleton, Stack } from '@mantine/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import HeadingContainer from '../../components/CommonComponents/HeadingContainer';
import DocumentsIcon from '../../../assests/svg/DocumentsIcon.svg';
import DocumentsList from '../../components/CommonComponents/Documents/DocumentsList';
import { DocumentsGet } from '../../../helpers/fetchApis/Documents';
import EmptyState from '../../components/CommonComponents/EmptyState';

class Documents extends Component {
  
  state={
    documentsData: [],
    skeletonLoader: false
  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const {profile, tenant, rentalUnit, property, account} = this.props;
    console.log('acv: ', account, profile);
    //window.history.pushState({}, undefined, "/documents");
    this.getDocumentsByAccountId();
  }

  getDocumentsByAccountId=()=> {
    const {account, profile} = this.props;
    this.setState({skeletonLoader: true});
    try{
      DocumentsGet(account.accountId, res=> {
        this.setState({documentsData: res ? res.filter((dt)=> dt.referencedObjectId === profile.id) : []}, ()=> this.setState({skeletonLoader: false}));
      })
    }catch(e){
      this.setState({skeletonLoader: false});
    }
    
  }

  render() {
    const {isMobile, isTablet, tenant, rentalUnit, property, rent} = this.props;
    const {documentsData, skeletonLoader} = this.state;
    console.log('documentsData: ', documentsData);
    return (
      <Stack style={{height: '100%'}} spacing={0}>
        <HeadingContainer
            title={'Ihre Dokumente'}
            icon={DocumentsIcon}
            //showProfileImage
            />
        <div className='divider'/>
        <Stack style={{padding: '24px', height: '100%'}}>
            {skeletonLoader ?
            <Stack spacing={40}>
                <Skeleton style={{height: '36px', width: isMobile ? '100%' : isTablet ? '50%' : '35%'}}/>
                <Skeleton style={{height: '14px'}}/>
                <Stack spacing={10}>
                    <Skeleton style={{height: '18.6px', width: '100%'}}/>
                    <Skeleton style={{height: '71px', width: '100%'}}/>
                    <Skeleton style={{height: '71px', width: '100%'}}/>
                    <Skeleton style={{height: '71px', width: '100%'}}/>
                    <Skeleton style={{height: '71px', width: '100%'}}/>
                    <Skeleton style={{height: '71px', width: '100%'}}/>
                    <Skeleton style={{height: '71px', width: '100%'}}/>
                    <Skeleton style={{height: '71px', width: '100%'}}/>
                    <Skeleton style={{height: '71px', width: '100%'}}/>
                </Stack>
            </Stack>
            :
            documentsData.length > 0 ?
            <DocumentsList documentsData={documentsData} tenant={tenant} getDocumentsByAccountId={()=> this.getDocumentsByAccountId()} skeletonLoader={skeletonLoader} isMobile={isMobile} isTablet={isTablet} />
            :
            <Stack style={{height: '100%'}} align={'center'}>
                <EmptyState
                icon={DocumentsIcon}
                title={'Es sind noch keine Dokumente vorhanden.'}
                subText={'Dokumente, die Ihr Vermieter freigibt, werden Ihnen hier angezeigt.'}
                />
            </Stack>}
        </Stack>
      </Stack>
    );
  }
}

const mapStateToProps = (state) => {
  return {
      profile: state.generalReducer.profileData,
      isMobile: state.generalReducer.isMobile,
      isTablet: state.generalReducer.isTablet,
      account: state.generalReducer.accountData,
      skeletonLoader: state.generalReducer.skeletonLoader,
      tenant: state.generalReducer.tenantData,
      rentalUnit: state.generalReducer.rentalUnitData,
      property: state.generalReducer.propertyData,
      rent: state.generalReducer.rentData
  };
};

export default connect(mapStateToProps)(Documents);
