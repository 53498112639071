import { Input } from '@mantine/core';
import SearchIcon from '../../../assests/svg/SearchIcon.svg';

const SearchContainer = (props) => {

  return (
    <Input
    value={props.value}
    placeholder={props.placeholder}
    style={props.style}
    icon={<img src={SearchIcon} />}
    classNames={props.classNames}
    onChange={props.onChange}
    />
  )
}

export default SearchContainer;