import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import { Group, TextInput, Button, Stack, PasswordInput } from '@mantine/core';
import {set_profile_data, set_loader} from '../../../redux/action/General/generalActions';
import Logo from '../../../assests/svg/Logo.svg';
import EyeIcon from '../../../assests/svg/EyeIcon.svg';
import GreenTick from '../../../assests/svg/GreenTick.svg';
import { EmailVerificationInstructionsPost, VerifyEmailPost } from '../../../helpers/fetchApis/Auth';
import { showNotification } from '@mantine/notifications';

class SetPassword extends Component {

    state={
        visible: false,
        passcode: '',
        email: '',
        success: null

    }
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        let email= window.localStorage.getItem('signupEmail');
        this.sendNewPassCode(email);
    }

    sendNewPassCode=(email)=>{
        EmailVerificationInstructionsPost({email: email}, this.props.set_loader, res=> {
            this.props.set_loader(false);
            this.setState({email});

            showNotification({
                icon: <img src={GreenTick} alt='green-tick'/>,
                message: 'Ein neuer Zugangscode wurde zugesendet. Bitte prüfen Sie Ihren Email-Posteingang.'
            })
        });
    }

    handlePassCode=(e)=>{
        this.setState({passcode: e.currentTarget.value});
    }

    handleSubmit=(e)=>{
        const {passcode, email} = this.state;
        e.preventDefault();
        VerifyEmailPost({email: email, passwordResetCode: passcode}, this.props.set_loader, res=> {
            if(res === true){
                window.localStorage.setItem('signupEmail', null);
                this.setState({success: true});
            }
        })
    }

    render() {
        const {visible, passcode, email, success} = this.state;
        console.log('visible: ', visible);
        return (
        <>
            <Stack className="login-view" id="respond-dashboard-page" style={{justifyContent: 'center', alignItems: 'center'}}>
                <img src={'./hello-housing-logo.svg'} alt='hello-housing' style={{marginBottom: '30px'}}/>
                <Stack spacing={10}>
                    {success ?
                    <Stack className='card' style={{width: '340px'}}>
                        <Group><span className='card-title'>Registrierung abgeschlossen</span></Group>
                        <Group><span className='card-sub-text'>Sie haben die Registrierung für das hellohousing Mieterportal erfolgreich abgeschlossen. Sie können Sich jetzt mit Ihrer Email-Adresse {`${email.slice(0,3)}`}xxx@xxx{`${email.slice(email.length - 4, email.length)}`} und dem gewählten Passwort anmelden.</span></Group>
                        <Group grow>
                            <Button className='purple-btn btn-app-text' onClick={()=> window.location.href='/login'}>Zur Anmeldung</Button>
                        </Group>
                    </Stack>
                    :
                    <Stack className='card' spacing={10} style={{width: '340px'}}>
                        <Group><span className='card-title'>Zugangscode eingeben</span></Group>
                        <Group><span className='card-sub-text' style={{fontWeight: '500'}}>Schritt 2 von 2</span></Group>
                        <Group><span className='card-sub-text'>Bitte bestätigen Sie die hinterlegte Email-Adresse {`${email.slice(0,3)}`}xxx@xxx{`${email.slice(email.length - 4, email.length)}`}. Geben Sie dazu den Zugangscode ein, den wir soeben an diese Email-Adresse gesendet haben. Prüfen Sie ggf. auch den Spam-Ordner.</span></Group>
                        <Group>
                            <form onSubmit={this.handleSubmit}
                            >
                                <Stack style={{width: '304px'}}>
                                    <Group grow>
                                        <TextInput
                                            label="Zugangscode"
                                            value={passcode}
                                            onChange={this.handlePassCode}
                                        />
                                    </Group>
                                    <Group>
                                        <span className='card-sub-text'>Klicken Sie <span style={{color: '#4949f5', cursor: 'pointer', fontStyle: 'italic'}} onClick={()=> this.sendNewPassCode(email)}>hier</span> um, den Zugangscode erneut zuzusenden.</span>
                                    </Group>
                                    <Group grow>
                                        <Button className='purple-btn btn-app-text' type="submit">Zugangscode überprüfen</Button>
                                    </Group>
                                </Stack>
                            </form>
                        </Group>
                    </Stack>
                    }
                </Stack>
            </Stack>
        </>
        );
    }
}

const actions = {
    set_profile_data,
    set_loader
};

export default connect(null, actions)(SetPassword);
