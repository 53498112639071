import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import { Group, TextInput, Button, Stack, PasswordInput, Popover, Progress, Image, Text, Box } from '@mantine/core';
import {set_profile_data, set_loader} from '../../../redux/action/General/generalActions';
import Logo from '../../../assests/svg/Logo.svg';
import EyeIcon from '../../../assests/svg/EyeIcon.svg';
import { ResetPasswordPost, TenantActivationStatusPost, TenantResetPasswordPost } from '../../../helpers/fetchApis/Auth';
import { showNotification } from '@mantine/notifications';
import RedCross from '../../../assests/svg/RedCross.svg';

// Password requirement markup
const PasswordRequirement = ({ checks, label }) => {
    return (
      <Text
        color="white"
        sx={{ display: 'flex', alignItems: 'center', opacity: checks ? 1 : 0.5 }}
        mt={7}
        size="sm"
      >
        <Image width={15} src={`${process.env.PUBLIC_URL}/icons/icon-check-badge.svg`} alt="Check icon" />
        <Box ml={10}>{label}</Box>
      </Text>
    )
};

// Requirements for the password validation
const passwordRequirements = [
    { regex: /[0-9]/, label: 'Eine Nummer haben' },                                                 // one digit
    { regex: /[a-z]/, label: 'Mindestens einen Kleinbuchstaben haben' },                            // lowercase letter
    { regex: /[A-Z]/, label: 'Mindestens einen Großbuchstaben haben' },                             // uppercase letter
    { regex: /[$&+,:;=?@#|'<>.^*()%!-]/, label: 'Ein Sonderzeichen enthalten (z. B. %, &, #)' }     // special chars
  ];
  
// Show password strength
const getStrength = (password) => {
    let multiplier = password.length > 5 ? 0 : 1;

    passwordRequirements.forEach((requirement) => {
    if (!requirement.regex.test(password)) {
        multiplier += 1;
    }
    });

    return Math.max(100 - (100 / (passwordRequirements.length + 1)) * multiplier, 10);
}


class ResetPassword extends Component {

    state={
        visible: false,
        password: '',
        confirmPassword: '',
        email: '',
        passwordResetCode: '',
        activationStatus: {},
        welcomeScreen: true,
        passwordPopoverOpened: false,
        isEmailVerified: false
    }
    checks =()=> passwordRequirements.map((requirement, index) => (
        <PasswordRequirement key={index} label={requirement.label} checks={requirement.regex.test(this.state.password)} />
    ));
    strength =()=> getStrength(this.state.password);
    color =()=> this.strength() === 100 ? 'teal' : this.strength() > 50 ? 'yellow' : 'red';
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        let url = new URL(window.location.href)
        this.setState({passwordResetCode: url.search.split('?')[1]});
    }

    handlePasswordChange=(e)=>{
        this.setState({password: e.currentTarget.value});
    }

    handleConfirmPassword=(e)=>{
        this.setState({confirmPassword: e.currentTarget.value});
    }

    handleSubmit=(e)=>{
        e.preventDefault();
        this.props.set_loader(true);
        const {password, confirmPassword, passwordResetCode} = this.state;

        if(password === confirmPassword){
            ResetPasswordPost({passwordResetCode: passwordResetCode, newPassword: password}, this.props.set_loader, res=> {
                this.props.set_loader(false);
                this.setState({success: true});
            })
        }else{
            showNotification({
                message: 'Passwort stimmt nicht überein.',
                icon: <img src={RedCross} alt='red-tick'/>,
            });
            this.props.set_loader(false);
        }
    }

    setPasswordPopoverOpened=(value)=>{
        this.setState({passwordPopoverOpened: value});
    }

    render() {
        const {password, confirmPassword, success, passwordPopoverOpened} = this.state;

        return (
        <>
            <Stack className="login-view" id="respond-dashboard-page" style={{justifyContent: 'center', alignItems: 'center'}}>
                <img src={'./hello-housing-logo.svg'} alt='hello-housing' style={{marginBottom: '30px'}}/>
                {success ?
                <Stack className='card' spacing={10} style={{width: '340px'}}>
                    <Group><span className='card-title'>Passwort geändert!</span></Group>
                    <Group><span className='card-sub-text'>Sie können sich jetzt mit Ihrem neuen Passwort anmelden.</span></Group>
                    <Group grow>
                        <Button className='purple-btn btn-app-text' onClick={()=> window.location.href = '/login'}>Zur Anmeldung</Button>
                    </Group>
                </Stack>
                :
                <Stack className='card' spacing={10} style={{width: '340px'}}>
                    <Group><span className='card-title'>Neues Passwort wählen</span></Group>
                    <Group><span className='card-sub-text'>Legen Sie Ihr neues Passwort fest.</span></Group>
                    <Group>
                        <form onSubmit={this.handleSubmit}>
                            <Stack style={{width: '304px'}}>
                                <Group grow>
                                    {/* Password input with strength popover */}
                                    <Popover
                                        opened={passwordPopoverOpened}
                                        position={window.innerWidth < 993 ? "top" : "left"}
                                        placement="center"
                                        trapFocus={false}
                                        transition="pop"
                                        onFocusCapture={() => this.setPasswordPopoverOpened(true)}
                                        onBlurCapture={() => this.setPasswordPopoverOpened(false)}
                                        styles={(theme) => ({
                                        dropdown: { width: '100%', backgroundColor: 'rgb(19, 19, 92)' },
                                        arrow: { backgroundColor: 'rgb(19, 19, 92)', border: 'none' },
                                        body: { border: 'none' }
                                        }) }
                                        withArrow
                                    >
                                        <Popover.Target>
                                            <PasswordInput
                                            label="Passwort"
                                            value={password}
                                            onChange={this.handlePasswordChange}
                                            visibilityToggleIcon={({ reveal, size }) =>
                                            reveal ? <img src={EyeIcon} size={size} /> : <img src={EyeIcon} size={size} />
                                            }
                                            required
                                            />
                                        </Popover.Target>
                                        <Popover.Dropdown className='password-popover' style={{paddingLeft: '20px'}}>
                                            <Progress color={this.color()} value={this.strength()} size={7} style={{ marginBottom: 16, border: '1px solid white' }} />
                                            <PasswordRequirement label="Mindestens 8 Zeichen lang sein" checks={password.length > 8} />
                                            {this.checks()}
                                        </Popover.Dropdown>
                                    </Popover>
                                </Group>
                                <Group grow>
                                    <PasswordInput
                                        label="Passwort bestätigen"
                                        value={confirmPassword}
                                        onChange={this.handleConfirmPassword}
                                        visibilityToggleIcon={({ reveal, size }) =>
                                        reveal ? <img src={EyeIcon} size={size} /> : <img src={EyeIcon} size={size} />
                                        }
                                    />
                                </Group>
                                <Group grow>
                                    <Button className='purple-btn btn-app-text' type="submit">Passwort speichern</Button>
                                </Group>
                            </Stack>
                        </form>
                    </Group>
                </Stack>}
            </Stack>
        </>
        );
    }
}

const actions = {
    set_profile_data,
    set_loader
};

export default connect(null, actions)(ResetPassword);
