import { Drawer, Group, Stack, Text, Anchor, Center } from '@mantine/core';
import { useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate, matchPath } from "react-router-dom";

import Icon from './Icon';
import SideBarDropDown from "./SidebarDropdown";

// Single menu item
export const MainMenuItem = ({ icon, route, children, href, target, color, ...props }) => {
  const [ hovered, setHovered ] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleNavigate = (event) => {
    event.preventDefault();

    if (route) {
        navigate(route);
    }

    if (href) {
      window.open(href);
    }
  }

  const pathMatches = () => window.location.pathname === '/messages/messageDetail' && route === '/messages' ? true : window.location.pathname === route;
  console.log('pathMatches: ', pathMatches(), pathname, route, window.location.pathname);

  return (
    <Group 
      sx={{
        cursor: 'pointer',
        position: 'relative',
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 20,
        marginBottom: props.isTablet && '20px'
      }}
      onClick={handleNavigate} 
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
      {...props}
    >
      {//pathMatches() && 
      (
        <div 
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: -3,
            width: 6,
            borderRadius: 3,
            backgroundColor: pathMatches() ? (color ? color : 'rgba(86, 85, 255, 1.00)') : 'white'
          }}
        />
      )}
      {icon && <Icon name={icon} color={//pathMatches() || 
        hovered || pathMatches() ? (color ? color : 'rgba(86, 85, 255, 1.00)') : 'rgba(154, 154, 181, 1.00)'} />}
      {!props.isTablet &&
      <Text sx={{ 
        fontSize: '14px',
        fontWeight: '500',
        color: //pathMatches() || 
        hovered || pathMatches() ? (color ? color : 'rgba(86, 85, 255, 1.00)') : 'rgba(154, 154, 181, 1.00)' }}>{children}</Text>}
    </Group>
  )
}

// Main app menu
const MainMenu = (props) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/login";
  }
  return (
    <>
      {props.isTablet &&
      <Stack>
        <Stack style={{marginTop: '35px', marginBottom: '0px'}}>
          <MainMenuItem icon="menu" isTablet={props.isTablet} onClick={()=> setOpenDrawer(!openDrawer)} />
        </Stack>
        <Drawer opened={openDrawer} onClose={()=> setOpenDrawer(false)} size={270} withCloseButton={false} >
          <Stack
          spacing="md"
          justify="space-between"
          sx={{ 
            height: '100vh', 
            paddingBottom: 16,
            position: "sticky",
            top: 0,
            bottom: 0,
          }}>
            <Stack>
              <Center pt={24} pb={12}>
                <Anchor href="/"><img src={`/hello-housing-logo.svg`} alt="Hello Housing Logo" /></Anchor>
              </Center>
              <SideBarDropDown/>
            
              <Stack spacing={6}>
                <MainMenuItem icon="dashboard" route="/dashboard">Übersicht</MainMenuItem>
                {!props.property?.isConsumptionSectionHidden && <MainMenuItem icon="consumption" route="/consumption">Verbräuche</MainMenuItem>}
                {!props.property?.isDocumentSectionHidden && <MainMenuItem icon="document" route="/documents">Dokumente</MainMenuItem>}
                {!props.property?.isMessageSectionHidden && <MainMenuItem icon="messenger" route="/messages">Nachrichten</MainMenuItem>}
                {/* <MainMenuItem icon="document" >Dokumente</MainMenuItem>
                <MainMenuItem icon="messenger" >Nachrichten</MainMenuItem> */}
              </Stack>
            </Stack>
            <Stack spacing={6}>
              <MainMenuItem icon="user" route={'/profile'}>{'Profil'}</MainMenuItem>
              <Group onClick={handleLogout}><MainMenuItem icon="logout" color="red">Abmelden</MainMenuItem></Group>
            </Stack>
          </Stack>
        </Drawer>
      </Stack>
      }
      <Stack spacing={6}>
        <MainMenuItem icon="dashboard" isTablet={props.isTablet} route="/dashboard">Übersicht</MainMenuItem>
        {!props.property?.isConsumptionSectionHidden && <MainMenuItem icon="consumption" isTablet={props.isTablet} route="/consumption">Verbräuche</MainMenuItem>}
        {!props.property?.isDocumentSectionHidden && <MainMenuItem icon="document" isTablet={props.isTablet} route="/documents">Dokumente</MainMenuItem>}
        {!props.property?.isMessageSectionHidden && <MainMenuItem icon="messenger" isTablet={props.isTablet} route="/messages">Nachrichten</MainMenuItem>}
        {/* <MainMenuItem icon="document" isTablet={props.isTablet}>Dokumente</MainMenuItem>
        <MainMenuItem icon="messenger" isTablet={props.isTablet}>Nachrichten</MainMenuItem> */}
      </Stack>
    </>
  )
};

const mapStateToProps = (state) => {
  return {
    profile: state.generalReducer.profileData,
    property: state.generalReducer.propertyData,
    isMobile: state.generalReducer.isMobile,
    isTablet: state.generalReducer.isTablet,
  };
};

export default connect(mapStateToProps)(MainMenu);