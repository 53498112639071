import { Grid, Group, SimpleGrid, Skeleton, Stack } from '@mantine/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import { api_config } from '../../../helpers/apis';
import { NotesGet, TenantGet } from '../../../helpers/fetchApis/Properties';
import { getAuthToken } from '../../../helpers/Uitls';
import DashboardCard from '../../components/CommonComponents/DashboardCard';
import DashboardDocumentsCard from '../../components/CommonComponents/DashboardDocumentsCard';
import DashboardMessagesCard from '../../components/CommonComponents/DashboardMessagesCard';
import DashboardPropertyCard from '../../components/CommonComponents/DashboardPropretyCard';
import DashboardTilesCard from '../../components/CommonComponents/DashboardTilesCard';
import FirstStepSectionCard from '../../components/CommonComponents/FirstStepSectionCard';
import FirstStepSectionCardCopy from '../../components/CommonComponents/FirstStepSectionCardCopy';
import TileDetailsModal from '../../components/Modals/TileDetailsModal';

class Dashboard extends Component {

  state={
    allDashboardCardsAvailable: true,
    tiles: [
      // {
      //     html: "<p>Lorem ipsum dolor sit amet. Hic suscipit saepe et voluptas harum in aliquid exercitationem. Et molestiae galisum ut ipsam animi est dolorem odio et natus iure ut soluta doloremque non voluptatem natus.</p><p><img src=\"https://picsum.photos/200\"/></p><p>Sed tenetur veritatis eos molestiae optio sed voluptas voluptate nam nesciunt fugit non inventore internos. Et quia neque qui soluta dolore et vitae nesciunt vel nihil suscipit quo quod quis sed officiis dicta.</p>",
      //     id: 0,
      //     title: "Test title 1",
      //     propertyId: '0000',
      //     orderNumber: 1,
      //     isHidden: false,

      // },
      // {
      //     html: "<p><img src=\"https://picsum.photos/200\"/></p><p>Lorem ipsum dolor sit amet. Hic suscipit saepe et voluptas harum in aliquid exercitationem. Et molestiae galisum ut ipsam animi est dolorem odio et natus iure ut soluta doloremque non voluptatem natus.</p><p>Sed tenetur veritatis eos molestiae optio sed voluptas voluptate nam nesciunt fugit non inventore internos. Et quia neque qui soluta dolore et vitae nesciunt vel nihil suscipit quo quod quis sed officiis dicta.</p>",
      //     id: 1,
      //     title: "Test title 2",
      //     propertyId: '0000',
      //     orderNumber: 2,
      //     isHidden: false,

      // },
      // {
      //     html: "<p>Lorem ipsum dolor sit amet. Hic suscipit saepe et voluptas harum in aliquid exercitationem. Et molestiae galisum ut ipsam animi est dolorem odio et natus iure ut soluta doloremque non voluptatem natus.</p><p>Sed tenetur veritatis eos molestiae optio sed voluptas voluptate nam nesciunt fugit non inventore internos. Et quia neque qui soluta dolore et vitae nesciunt vel nihil suscipit quo quod quis sed officiis dicta.</p>",
      //     id: 2,
      //     title: "Test title 3",
      //     propertyId: '0000',
      //     orderNumber: 3,
      //     isHidden: false,
      // }
    ],
    allImagesData: [],
    openTileDetailModal: false,
    selectedTile: null
  }
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const {profile, tenant, rentalUnit, property} = this.props;
    window.history.pushState({}, undefined, "/dashboard");
    this.setState({
      allDashboardCardsAvailable: (Object.keys(property).length > 0 && !property?.isConsumptionSectionHidden && !property?.isDocumentSectionHidden && !property?.isMessageSectionHidden)
    });
    this.getNotesByPropertyId();
  }

  componentDidUpdate(prevProps){
    if(prevProps.property !== this.props.property){
      this.setState({
        allDashboardCardsAvailable: (Object.keys(this.props.property).length > 0 && !this.props.property?.isConsumptionSectionHidden && !this.props.property?.isDocumentSectionHidden && !this.props.property?.isMessageSectionHidden)
      });
      
      this.getNotesByPropertyId();
    }
  }

  getNotesByPropertyId=()=>{
    const {property} = this.props;
    console.log('property: ', property);
    NotesGet(property.id, async res=> {
        //this.setState({tiles: res});
        const setTiles=(values)=> this.setState({tiles: values.sort((a, b)=> a.displayOrder - b.displayOrder)});
        if(res.find((tile)=> tile.body.includes('<img')) !== undefined){
            // let tiles= res.map((tile)=> {
            //     let parser = new DOMParser();
            //     let doc = parser.parseFromString(tile.body, 'text/html');

            //     doc.querySelectorAll('img').forEach((img, index) => {
            //         let src = this.getDocumentPromise(img.id);
            //         src.then((value)=> {
            //             console.log('src: ', value);
            //             img.src = value;

            //             if(doc.querySelectorAll('img').length === (index + 1)){
            //                 let modifiedHtmlString = doc.body.innerHTML;
            //                 console.log('src: ', modifiedHtmlString);
            //                 tile.body = modifiedHtmlString;
            //             }
            //         });
            //     });

            //     return tile;
            // });
            setTiles(res);
            let tiles = await Promise.all(
              res.map(async (tile) => {
                let parser = new DOMParser();
                let doc = parser.parseFromString(tile.body, 'text/html');
            
                // Collect all promises for image sources
                const imgPromises = Array.from(doc.querySelectorAll('img')).map(async (img, index) => {
                  const src = await this.getDocumentPromise(img.id);
                  img.src = src;
                });
            
                // Wait for all image sources to be resolved before proceeding
                await Promise.all(imgPromises);
            
                // After all images are processed, update the tile body with the modified HTML
                tile.body = doc.body.innerHTML;
                return tile;
              })
            );
            setTimeout(() => {
              setTiles(tiles);  
            }, 1000);
        }else{
          setTimeout(() => {
            setTiles(res);
          }, 0);
        }
    });
  }

  getDocumentPromise=(id)=> 
    new Promise((resolve, reject) => {
        fetch(api_config.baseUrl + api_config.document_management.documents.document_get + id,
        {
        method: 'GET',
        headers: {
          Authorization: getAuthToken(),
        }
        })
        .then(data2 => {
        data2.json()
        .then(response2 => {
            let {allImagesData} = this.state;
            allImagesData.push({
                id: response2?.result?.id,
                fileLocation: response2?.result?.fileLocation
            });
            this.setState({
                allImagesData,
                noSaveDuringImageUpload: false
            }, ()=> resolve(response2?.result?.fileLocation));
        })}).catch(()=> reject(new Error('Download failed')));
    })

  render() {
    const {allDashboardCardsAvailable, tiles, openTileDetailModal, selectedTile} = this.state;
    const {skeletonLoader, isMobile, isTablet, tenant, rentalUnit, property, rent} = this.props;
    console.log('componentDidMount: ', tenant, rentalUnit, property);
    return (
      <>
        <Stack className="container" id="respond-dashboard-page">
          {skeletonLoader ? 
          <Skeleton style={{height: '236px'}} />
          :
          <Group grow>
            <img style={{borderRadius: '4px', height: '237px', objectFit: 'cover'}} src={property && property.pictureLocation ? property.pictureLocation : './houseDefault.png'}/>
          </Group>}
          <Stack spacing={10}>
            {property && property.propertyAddress && property.propertyAddress.streetNo && <span className='dashboard-property-name' style={{width: 'fit-content'}}><Skeleton visible={skeletonLoader}>{property && property.propertyAddress.streetNo ? property.propertyAddress.streetNo : property.name}</Skeleton></span>}
            {property && property.propertyAddress && (property.propertyAddress.zipCode || property.propertyAddress.city) && <span className='dashboard-property-address' style={{width: 'fit-content'}}><Skeleton visible={skeletonLoader}>{`${property && property.propertyAddress.zipCode} ${property && property.propertyAddress.city}`}</Skeleton></span>}
          </Stack>
          {rent && rent.length > 0 && <div>
            <Skeleton visible={skeletonLoader} className='skeleton-box-shadow'>
              <DashboardPropertyCard isMobile={isMobile} isTablet={isTablet} tenant={tenant} rentalUnit={rentalUnit} property={property} rent={rent} />
            </Skeleton>
          </div>}
          
          {/* <FirstStepSectionCard/> */}
          <Group //grow={!isMobile}
          >
            {/* <div style={{width: isMobile && '100%'}}>
              <Skeleton visible={skeletonLoader} className='skeleton-box-shadow'>
                <Group grow>
                  <DashboardCard
                  heading={'Dokumente'}/>
                </Group>
              </Skeleton>
            </div> */}
            <div style={{width: '100%'}}>
              <Skeleton visible={skeletonLoader} style={{paddingBottom: '5px'}} className='skeleton-box-shadow'>
                {/* <Group grow={allDashboardCardsAvailable}
                noWrap={!allDashboardCardsAvailable}
                > */}
                <SimpleGrid 
                cols={3}
                breakpoints={[
                  { maxWidth: 2000, cols: 3, spacing: 'md' },
                  { maxWidth: 1400, cols: 2, spacing: 'sm' },
                  { maxWidth: 810, cols: 1, spacing: 'sm' },
                ]}>
                  {Object.keys(property).length > 0 && !property?.isConsumptionSectionHidden &&
                  <DashboardCard
                  heading={'Verbräuche'}
                  tenant={tenant}
                  allDashboardCardsAvailable={allDashboardCardsAvailable}
                  />}
                  {!property?.isDocumentSectionHidden && 
                  <DashboardDocumentsCard
                  heading={'Dokumente'}
                  allDashboardCardsAvailable={allDashboardCardsAvailable}
                  />}
                  {!property?.isMessageSectionHidden && 
                  <DashboardMessagesCard
                  heading={'Nachrichten'}
                  allDashboardCardsAvailable={allDashboardCardsAvailable}/>}
                  {!property?.isNotesSectionHidden && tiles.length > 0 &&
                  tiles.sort((a, b)=> a.displayOrder - b.displayOrder)
                  .filter((tile)=> tile.isActive)
                  .map((tile)=>
                  <DashboardTilesCard
                  heading={tile.title}
                  tile={tile}
                  openTileDetail={(selectedTile)=> this.setState({selectedTile, openTileDetailModal: true})}
                  />)}
                </SimpleGrid>
                {/* </Group> */}
                {openTileDetailModal &&
                <TileDetailsModal
                open={openTileDetailModal}
                toggle={()=> this.setState({selectedTile: null, openTileDetailModal: false})}
                tile={selectedTile}
                />}
              </Skeleton>
            </div>
          </Group>
        </Stack>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
      profile: state.generalReducer.profileData,
      isMobile: state.generalReducer.isMobile,
      isTablet: state.generalReducer.isTablet,
      account: state.generalReducer.accountData,
      skeletonLoader: state.generalReducer.skeletonLoader,
      tenant: state.generalReducer.tenantData,
      rentalUnit: state.generalReducer.rentalUnitData,
      property: state.generalReducer.propertyData,
      rent: state.generalReducer.rentData
  };
};

export default connect(mapStateToProps)(Dashboard);
