export const SET_ACCOUNT_DATA = 'SET_ACCOUNT_DATA';
export const SET_PROFILE_DATA = 'SET_PROFILE_DATA';
export const SET_PROFILE_PIC = 'SET_PROFILE_PIC';
export const SET_IS_MOBILE = 'SET_IS_MOBILE';
export const SET_IS_TABLET = 'SET_IS_TABLET';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_LOADER = 'SET_LOADER';
export const SET_SKELETON_LOADER = 'SET_SKELETON_LOADER';
export const SET_TENANT_DATA = 'SET_TENANT_DATA';
export const SET_RENTAL_UNIT_DATA = 'SET_RENTAL_UNIT_DATA';
export const SET_PROPERTY_DATA = 'SET_PROPERTY_DATA';
export const SET_RENT_DATA = 'SET_RENT_DATA';