import { Group, Stack, Tabs } from '@mantine/core';
import ConsumptionIcon from '../../../assests/svg/Consumption.svg';
import ProfileImage from '../../../assests/svg/TestImage.svg';
//import Chart from 'chart.js/auto'
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import 'chartjs-plugin-style';
// import zoomPlugin from 'chartjs-plugin-zoom';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Chart } from "react-chartjs-2";
import { tenantConsumptionSeriesGet } from '../../../helpers/fetchApis/MeterConsumptions';
import { set_loader, set_skeleton_loader } from '../../../redux/action/General/generalActions';
import { connect } from 'react-redux';

const sectionCurrentYear = (ctx, value, month, startMonth) => {
  if(month-startMonth === 1){
    return ctx.p0.parsed.x === month-(month-startMonth) ? value : undefined;
  }else{
    if(startMonth === -1){
      return ctx.p0.parsed.x <= month-1 ? value : undefined
    }
    if(ctx.p0.parsed.x >= startMonth){
      return month !== -1 ? (startMonth <= month ? value : undefined) : (startMonth <= 12 ? value : undefined)
    }
  } 
};
const sectionPreviousYear = (ctx, value, month, startMonth) => month-startMonth === 1 ? (ctx.p0.parsed.x === month-(month-startMonth) ? value : undefined) : ((ctx.p0.parsed.x === startMonth ? startMonth < month : ctx.p0.parsed.x !== month) ? value : undefined);

ChartJS.register(ArcElement, Tooltip, Legend);
// ChartJS.register(zoomPlugin);

let width, height, gradient;
function getGradient(ctx, chartArea) {
  const chartWidth = chartArea.right - chartArea.left;
  const chartHeight = chartArea.bottom - chartArea.top;
  if (!gradient || width !== chartWidth || height !== chartHeight) {
    // Create the gradient because this is either the first render
    // or the size of the chart has changed
    width = chartWidth;
    height = chartHeight;
    gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
    gradient.addColorStop(0, '#E3E3FC');
    gradient.addColorStop(0.5, '#5555FF');
    gradient.addColorStop(1, '#422F8A');
  }

  return gradient;
}

Array.prototype.max = function() {
  return Math.max.apply(null, this);
};

Array.prototype.min = function() {
  return Math.min.apply(null, this);
};

const ConsumptionChartCard = (props) => {
    const [dataChart, setDataChart] = useState(
      [
        {
          dateTime: "2023-01-01T20:22:28.810Z",
          consumption: null,
          lastYearConsumption: null
        },
        {
          dateTime: "2023-02-01T20:22:28.810Z",
          consumption: null,
          lastYearConsumption: null
        },
        {
          dateTime: "2023-03-01T20:22:28.810Z",
          consumption: null,
          lastYearConsumption: null
        },
        {
          dateTime: "2023-04-01T20:22:28.810Z",
          consumption: null,
          lastYearConsumption: null
        },
        {
          dateTime: "2023-05-01T20:22:28.810Z",
          consumption: null,
          lastYearConsumption: null
        },
        {
          dateTime: "2023-06-01T20:22:28.810Z",
          consumption: null,
          lastYearConsumption: null
        },
        {
          dateTime: "2023-07-01T20:22:28.810Z",
          consumption: null,
          lastYearConsumption: null
        },
        {
          dateTime: "2023-08-01T20:22:28.810Z",
          consumption: null,
          lastYearConsumption: null
        },
        {
          dateTime: "2023-09-01T20:22:28.810Z",
          consumption: null,
          lastYearConsumption: null
        },
        {
          dateTime: "2023-10-01T20:22:28.810Z",
          consumption: null,
          lastYearConsumption: null
        },
        {
          dateTime: "2023-11-01T20:22:28.810Z",
          consumption: null,
          lastYearConsumption: null
        },
        {
          dateTime: "2023-12-01T20:22:28.810Z",
          consumption: null,
          lastYearConsumption: null
        },
        ]
    );
    const [monthsToShow, setMonthsToShow] = useState(12);
    const maxMonths = 12;
    const [chartHover, setChartHover] = useState(false);
    const [activeTab, setActiveTab] = useState('fourth');
    const [segmentMonth, setSegmentMonth] = useState(1);
    const [segmentStartMonth, setSegmentStartMonth] = useState(1);
    const [segmentEndMonth, setSegmentEndMonth] = useState(2);
    const [segmentlastYearStartMonth, setSegmentlastYearStartMonth] = useState(1);
    const [segmentlastYearEndMonth, setSegmentlastYearEndMonth] = useState(2);
    const [chart, setChart] = useState(null);
    const [data, setData] = useState(dataChart.map((dt)=> dt.consumption));
    const [oldData, setOldData] = useState(dataChart.map((dt)=> dt.lastYearConsumption));
    const [months, setDataMonths] = useState(dataChart.map((dt)=> dayjs(dt.dateTime).format('MMM YY')));
    var duration = require('dayjs/plugin/duration')
    var localeData = require('dayjs/plugin/localeData')
    var locale = require('dayjs/locale/de');
    dayjs.locale('de')
    dayjs.extend(localeData)
    dayjs.extend(duration);
    dayjs.duration({ months: 12 });

    const onChartHoverInOut = ()=>{
        setChartHover(!chartHover);
    }

    const getOrCreateTooltip = (chart) => {
        let tooltipEl = chart.canvas.parentNode.querySelector('div');
      
        if (!tooltipEl) {
          tooltipEl = document.createElement('div');
          tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
          tooltipEl.style.borderRadius = '3px';
          tooltipEl.style.color = 'white';
          tooltipEl.style.opacity = 1;
          tooltipEl.style.pointerEvents = 'none';
          tooltipEl.style.position = 'absolute';
          tooltipEl.style.transform = 'translate(-50%, 0)';
          tooltipEl.style.transition = 'all .1s ease';
      
          const table = document.createElement('table');
          table.style.margin = '0px';
      
          tooltipEl.appendChild(table);
          chart.canvas.parentNode.appendChild(tooltipEl);
        }
      
        return tooltipEl;
      };
      
      const externalTooltipHandler = (context) => {
        // Tooltip Element
        const {chart, tooltip} = context;
        const tooltipEl = getOrCreateTooltip(chart);

        console.log('externalTooltipHandler: ', context);

        tooltipEl.style.marginTop = '10px'
        tooltipEl.style.backgroundColor = 'white';
        tooltipEl.style.color = '#11263C'
        tooltipEl.style.boxShadow = '0 3px 3px rgba(0,0,0,.2)'
        
        // Hide if no tooltip
        if (tooltip.opacity === 0) {
          tooltipEl.style.opacity = 0;
          return;
        }
      
        // Set Text
        if (tooltip.body) {
          const titleLines = tooltip.title || [];
          const bodyLines = tooltip.body.map(b => b.lines);

          console.log('bodyLines: ', bodyLines);
      
          const tableHead = document.createElement('thead');
      
        //   titleLines.forEach(title => {
        //     const tr = document.createElement('tr');
        //     tr.style.borderWidth = 0;
      
        //     const th = document.createElement('th');
        //     th.style.borderWidth = 0;
        //     const text = document.createTextNode(title);
      
        //     th.appendChild(text);
        //     tr.appendChild(th);
        //     tableHead.appendChild(tr);
        //   });
      
          const tableBody = document.createElement('tbody');
        //   bodyLines.forEach((body, i) => {
            //const colors = tooltip.labelColors[i];

            //console.log('body: ', body);
      
            // const span = document.createElement('span');
            // span.style.background = colors.backgroundColor;
            // span.style.borderColor = colors.borderColor;
            
            // span.style.height = '10px';
            // span.style.width = '10px';
            // span.style.display = 'inline-block';
      
            const tr = document.createElement('tr');
            tr.style.backgroundColor = 'inherit';
            tr.style.borderWidth = 0;
      
            const td = document.createElement('td');
            td.style.borderWidth = 0;

            const div = document.createElement('div');
            div.style.display= 'flex';
            div.style.flexDirection= 'column';
            // div.style.width= 
            // (bodyLines.toString().includes('Aktuell:') && bodyLines.toString().includes('Vorjahr:')) ?
            // ((bodyLines.toString().split('Aktuell:')[1].split(',Vorjahr:')[0].length > 3 && bodyLines.toString().split('Aktuell:')[1].split(',Vorjahr:')[1].length > 3) ? '180px' : bodyLines.toString().split('Aktuell:')[1].split(',Vorjahr:')[1].length > 3 ? '150px' : bodyLines.toString().split('Aktuell:')[1].split(',Vorjahr:')[0].length > 3 ? '150px' : '130px')
            // :
            // '130px';
      
            const text = document.createTextNode(bodyLines.toString().includes('Aktuell') && bodyLines.toString().includes('Vorjahr') ? 'Aktuell - Vorjahr' : bodyLines.toString().includes('Aktuell') ? 'Aktuell' : bodyLines.toString().includes('Vorjahr') && 'Vorjahr' );
            const span1 = document.createElement('span');
            span1.style.color= '#5041BC';
            span1.style.fontSize= '9px';
            span1.append(text);


            console.log('length: ', bodyLines.toString());
            const span2 = document.createElement('span');
            span2.style.color= '#11263C';
            span2.style.fontSize= '16px';
            span2.style.fontWeight= 'bold';
            span2.append((bodyLines.toString().includes('Aktuell') && bodyLines.toString().includes('Vorjahr') ? bodyLines.toString().split('Aktuell:')[1].split(',Vorjahr:')[0] + 'kWh - ' + bodyLines.toString().split('Aktuell:')[1].split(',Vorjahr:')[1] : bodyLines.toString().includes('Aktuell') ? bodyLines.toString().split('Aktuell:')[1] : bodyLines.toString().split('Vorjahr:')[1]) + 'kWh');
            //span.appendChild(body.toString().includes('Aktuell') ? 'Aktuell' : 'Vorjahr');
            //td.appendChild(span);
            const span3 = document.createElement('span');
            span3.style.color= 'rgb(60, 60, 67, 0.6)';
            span3.style.fontSize= '10px';
            span3.append(bodyLines.toString().includes('Aktuell') && bodyLines.toString().includes('Vorjahr') ? 
            tooltip.title +' '+' - '+ (tooltip.title.toString().split(' ')[0] +' '+ (parseInt(tooltip.title.toString().split(' ')[1]) - 1).toString())
            :
            bodyLines.toString().includes('Vorjahr') ?
            (tooltip.title.toString().split(' ')[0] +' '+ (parseInt(tooltip.title.toString().split(' ')[1]) - 1).toString())
            :
            tooltip.title);

            div.append(span1);
            div.append(span2);
            div.append(span3);
            td.appendChild(div);
            tr.appendChild(td);
            tableBody.appendChild(tr);
        
      
          const tableRoot = tooltipEl.querySelector('table');
      
          // Remove old children
          while (tableRoot.firstChild) {
            tableRoot.firstChild.remove();
          }
      
          // Add new children
          tableRoot.appendChild(tableHead);
          tableRoot.appendChild(tableBody);
        }
      
        const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;
      
        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1;
        tooltipEl.style.borderRadius='10px';
        tooltipEl.style.whiteSpace = 'nowrap';
        tooltipEl.style.left = props.isMobile ? '320px' : positionX + tooltip.caretX + 'px';
        tooltipEl.style.top = props.isMobile ? '0px' : positionY + tooltip.caretY + 'px';
        tooltipEl.style.font = tooltip.options.bodyFont.string;
        tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
        tooltipEl.style.marginTop = !props.isMobile && (context.tooltip.caretY <= 40 ? '-90px' : '-110px');
        tooltipEl.style.marginLeft = !props.isMobile && (context.chart.width - context.tooltip.caretX <= 100 ? '-100px' : '0px');
        tooltipEl.style.transition = 'all 0.5s ease 0s';
        tooltipEl.style.zIndex = 9999999;
      };
    
    
        // const data = dataChart.map((dt)=> dt.consumption);
        // const oldData = dataChart.map((dt)=> dt.lastYearConsumption);
        // const months = dataChart.map((dt)=> dayjs(dt.dateTime).format('MMM YY'));
        //months.map((month)=> month.includes('.') === false && month.substring );
        // useEffect(() => {
        //   data = dataChart.map((dt)=> dt.consumption);
        //   oldData = dataChart.map((dt)=> dt.lastYearConsumption);
        //   months = dataChart.map((dt)=> dayjs(dt.dateTime).format('MMM YY'));
        // }, []);
        console.log('data: ', data, oldData);
        let x = !props.isMobile ? {
          min: maxMonths - monthsToShow + (dataChart.length > 12 ? 1 : 0),
          max: maxMonths,
          grid: {
              color: "rgba(0, 0, 0, 0)",
          },
          border: {
              display: false
          },
          ticks: {
              maxRotation: 0,
              minRotation: 0,
              padding: 15,
              color: '#727293',
              font: {
                weight: function(context) {
                  if(context.tick.label.includes(dayjs(props.endDate).format('MMM YY'))){
                    return 'bold';
                  } 
                  // else if(props.selectedMonthSelector === 'lastYearTotal'){
                  //   if(context.tick.label.includes(dayjs(props.endDate).add(1, 'year').format('MMM YY'))){
                  //     return 'bold';
                  //   }
                  // }
                  if(context.tick.label.includes(dayjs(props.startDate).format('MMM YY'))){
                    return 'bold';
                  } 
                  // else if(props.selectedMonthSelector === 'lastYearTotal'){
                  //   if(context.tick.label.includes(dayjs(props.startDate).add(1, 'year').format('MMM YY'))){
                  //     return 'bold';
                  //   }
                  // }
                },
                size: function(context) {
                  if(!props.isMobile && activeTab === 'fourth'){
                    return '10px';
                  }  
                }
              }
          },
        } : {
        grid: {
            color: "rgba(0, 0, 0, 0)",
        },
        border: {
            display: false
        },
        ticks: {
            maxTicksLimit: props.isMobile && 6,
            maxRotation: 0,
            minRotation: 0,
            padding: 15,
            color: '#727293',
            font: {
              weight: function(context) {
                if(context.tick.label.includes(dayjs(props.endDate).format('MMM YY'))){
                  return 'bold';
                } 
                // else if(props.selectedMonthSelector === 'lastYearTotal'){
                //   if(context.tick.label.includes(dayjs(props.endDate).add(1, 'year').format('MMM YY'))){
                //     return 'bold';
                //   }
                // }
                if(context.tick.label.includes(dayjs(props.startDate).format('MMM YY'))){
                  return 'bold';
                } 
                // else if(props.selectedMonthSelector === 'lastYearTotal'){
                //   if(context.tick.label.includes(dayjs(props.startDate).add(1, 'year').format('MMM YY'))){
                //     return 'bold';
                //   }
                // }
              },
              size: function(context) {
                if(!props.isMobile && activeTab === 'fourth'){
                  return '10px';
                }  
              }
            }
        },
        }
        let singlePointerCondition = data.filter((dt)=> dt !== null).length === 1 ? true : false;
        const chartData = {
          labels: months,//dayjs.monthsShort(), //dayjs.monthsShort()
          datasets: [
            {
                label: 'Aktuell',
                pointHoverRadius: 6,
                pointHoverBackgroundColor: 'rgb(66, 47, 138, 0.87)',
                pointHoverBorderColor: '#ffffff',
                pointHoverBorderWidth: 2,
                pointBackgroundColor: singlePointerCondition ? 'rgb(66, 47, 138, 0.87)' : 'transparent',
                pointBorderColor: singlePointerCondition ? '#ffffff' : 'transparent',
                pointRadius: singlePointerCondition && 4,
                pointBorderWidth: singlePointerCondition && 2,
                data: data,
                fill: true,
                backgroundColor: function(context) {
                    const chart = context.chart;
                    const {ctx, chartArea} = chart;
                    var gradient = ctx.createLinearGradient(0, 0, 0, 600);
                    
                    gradient.addColorStop(0, 'rgb(116, 89, 217, 0.1)');   
                    gradient.addColorStop(1, 'rgb(255, 255, 255, 0)');
                    

                    return gradient;
                },
                borderColor: function(context) {
                    const chart = context.chart;
                    const {ctx, chartArea} = chart;
            
                    if (!chartArea) {
                    // This case happens on initial chart load
                    return;
                    }
                    return getGradient(ctx, chartArea);
                },
                tension: 0.4,
                segment: {
                  backgroundColor: ctx => sectionCurrentYear(ctx, props.highConsumption ? 'rgb(255, 203, 203, 0.5)' : 'rgb(232, 244, 234, 0.65)', segmentEndMonth, segmentStartMonth)
                },
                //pointStyle: false,
                //labels: [data.min(),10,15,data.max()]
            },
            {
                label: 'Vorjahr',
                pointHoverRadius: 6,
                pointHoverBackgroundColor: '#E3DBF1',
                pointHoverBorderColor: '#ffffff',
                pointHoverBorderWidth: 2,
                pointBackgroundColor: singlePointerCondition ? '#E3DBF1' : 'transparent',
                pointBorderColor: singlePointerCondition ? '#ffffff' : 'transparent',
                pointRadius: singlePointerCondition && 4,
                pointBorderWidth: singlePointerCondition && 2,
                data: oldData,
                fill: true,
                borderColor: '#E3DBF1',
                tension: 0.4,
                backgroundColor: 'rgb(255, 255, 255, 0.01)',
                segment: props.selectedMonthSelector === 'lastYearTotal' && {
                  backgroundColor: ctx => sectionPreviousYear(ctx, props.highConsumption ? 'rgb(255, 203, 203, 0.5)' : 'rgb(232, 244, 234, 0.65)', segmentlastYearEndMonth, segmentlastYearStartMonth)
                },
            }
          ]
        }
        const chartOptions= {
          locale: 'de',
          animation: {
              duration: 100
          },
          responsive: true,
          interaction: {
              intersect: false,
              mode: 'index',
          },
          plugins: {
              legend: {
                  position: 'top',
                  align: 'end'
              },
              
              events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
              tooltip: {
                  enabled: false,
                  position: 'nearest',
                  external: externalTooltipHandler,
                  //intersect: false
              },
              legend: !props.isMobile && {
                  align: 'end',
                  labels:{
                      boxHeight: 1,
                      //color: '#131358', '#A9A9A9']
                  }
              }
          },
          zoom: {
            pan: {
                enabled: true,
                mode: 'x'
            },
            zoom: {
                pinch: {
                    enabled: true       // Enable pinch zooming
                },
                wheel: {
                    enabled: true       // Enable wheel zooming
                },
                mode: 'x',
            }
          },
          scales: {
              x: {...x, offset: true},
              y: {
                  // min: (data.find((dt)=> dt !== null) === undefined && oldData.find((dt)=> dt !== null) === undefined) ? 0 : data.min() < oldData.min() ? data.min() : oldData.min(),
                  // max: (data.find((dt)=> dt !== null) === undefined && oldData.find((dt)=> dt !== null) === undefined) ? 
                  // 20 
                  // : 
                  // (data.max() > oldData.max() ? 
                  //   ((Math.ceil(data.max() / 10) * 10) + (data.find((dt)=> dt === (Math.ceil(data.max() / 10) * 10)) !== undefined ? 
                  //     ((Math.ceil(data.max() / 10) * 10) < 100 ? 
                  //       0 //increment this number if you want to have extra number over y-axis if y-axis data reaches max y-axis label
                  //       : 
                  //       0 //increment this number if you want to have extra number over y-axis if y-axis data reaches max y-axis label for number greater than 100
                  //     ) 
                  //     : 
                  //     0))
                  //   : 
                  //   ((Math.ceil(oldData.max() / 10) * 10) + (oldData.find((dt)=> dt === (Math.ceil(oldData.max() / 10) * 10)) !== undefined ? 
                  //     ((Math.ceil(oldData.max() / 10) * 10) < 100 ? 
                  //       0 //increment this number if you want to have extra number over y-axis if y-axis data reaches max y-axis label
                  //       : 
                  //       0 //increment this number if you want to have extra number over y-axis if y-axis data reaches max y-axis label for number greater than 100
                  //       ) 
                  //     : 0))),
                  ticks: {
                      maxTicksLimit: 5,
                      padding: 15,
                      color: '#727293'
                  },
                  title: {
                      text: 'kWh',
                      display: !props.isMobile && true,
                      color: '#A9A9A9'
                  },
                  border: {
                      display: false
                  },
                  offset: true
              },
          },
        }
        const setMonths= (tab)=>{
          switch(tab){
            case 'first':
              return 3;
            case 'second':
              return 6;
            case 'third':
              return 9;
            case 'fourth':
              return 12;
            default:
              return 12;
          }
        }
        const setActiveTabHandler=(value)=>{
          setActiveTab(value);
          setMonthsToShow(setMonths(value));
        }

        
        useEffect(() => {
          if(props.selectedDate && props.consumptionType && props.selectedTenant){
            props.setLoader(true);
            tenantConsumptionSeriesGet(props.consumptionType, props.selectedDate, props.selectedTenant, props.setLoader, res=> {
              setDataChart(res);
              setData(res.map((dt)=> dt.consumption));
              setOldData(res.map((dt)=> dt.lastYearConsumption));
              let months = res.map((dt)=> dayjs(dt.dateTime).format('MMM YY'));

              setDataMonths(months);
              setSegmentMonth(months.findIndex((month)=> dayjs(props.consumptionDate).format('MMM YY') === month));
              setSegmentStartMonth(months.findIndex((month)=> dayjs(props.startDate).format('MMM YY') === month));
              setSegmentEndMonth(months.findIndex((month)=> dayjs(props.endDate).format('MMM YY') === month));
              console.log('date: ', props.startDate, props.endDate);
            
              let lastYearEndDateIndex = months.findIndex((month)=> dayjs(props.endDate).add(1, 'year').format('MMM YY') === month);
              let lastYeatStartDateIndex = months.findIndex((month)=> dayjs(props.startDate).add(1, 'year').format('MMM YY') === month);
              setSegmentlastYearStartMonth(lastYeatStartDateIndex === -1 ? 0 : lastYeatStartDateIndex);
              setSegmentlastYearEndMonth(lastYearEndDateIndex === -1 ? 0 : lastYearEndDateIndex);
              props.setLoader(false);
            });
          }
        },[props.selectedDate, props.consumptionType, props.selectedTenant]);

        useEffect(() => {
          console.log('segment: ', segmentStartMonth, segmentEndMonth);
          console.log('date: ', dayjs(props.startDate).format('MMM YY'), dayjs(props.endDate).format('MMM YY'));
          setSegmentMonth(months.findIndex((month)=> dayjs(props.consumptionDate).format('MMM YY') === month));
          setSegmentStartMonth(months.findIndex((month)=> dayjs(props.startDate).format('MMM YY') === month));
          setSegmentEndMonth(months.findIndex((month)=> dayjs(props.endDate).format('MMM YY') === month));
          console.log('index: current', months.findIndex((month)=> dayjs(props.startDate).format('MMM YY') === month), months.findIndex((month)=> dayjs(props.endDate).format('MMM YY') === month));
          console.log('index:', months.findIndex((month)=> dayjs(props.startDate).add(1, 'year').format('MMM YY') === month), months.findIndex((month)=> dayjs(props.endDate).add(1, 'year').format('MMM YY') === month));

          let lastYearEndDateIndex = months.findIndex((month)=> dayjs(props.endDate).add(1, 'year').format('MMM YY') === month);
          let lastYeatStartDateIndex = months.findIndex((month)=> dayjs(props.startDate).add(1, 'year').format('MMM YY') === month);
          console.log('date: Index', lastYearEndDateIndex, lastYeatStartDateIndex);
          setSegmentlastYearStartMonth(lastYeatStartDateIndex === -1 ? 0 : lastYearEndDateIndex);
          setSegmentlastYearEndMonth(lastYearEndDateIndex === -1 ? 0 : lastYearEndDateIndex);
        }, [activeTab, props.startDate, props.endDate]);

  return (
    <Stack className={props.isTablet ? 'consumption-chart-card-tablet' :'consumption-chart-card'} spacing={20}>
        <Group className='consumption-chart-card-heading' style={{width: '100%', justifyContent: 'space-between'}}>
            <Stack spacing={5}>
              <span className='consumption-chart-card-heading-text'>Verbräuche {props.consumptionType === 'HeatingTotal' ? 'Gesamt' : props.consumptionType === 'RoomHeating' ? 'Heizung' : props.consumptionType === 'WarmWater' && 'Warmwasser'}</span>
              <Group className='consumption-chart-card-sub-heading'>
                {props.percentage && <span className='consumption-chart-card-sub-heading-text'><span style={{color: props.highConsumption ? '#E8193C' : '#059266', fontWeight: '700'}}>{props.highConsumption ? '+'+props.percentage : '-'+props.percentage}</span> {props.highConsumption ? 'höher als im Vorjahr' : 'niedriger als im Vorjahr'}</span>}
              </Group>
            </Stack>
            {!props.isMobile && <Tabs value={activeTab} onTabChange={setActiveTabHandler}>
              <Tabs.List>
                <Tabs.Tab style={{height: '35px'}} value="first"><span className='consumption-chart-card-heading-month-selector'>3 Monate</span></Tabs.Tab>
                <Tabs.Tab style={{height: '35px'}} value="second"><span className='consumption-chart-card-heading-month-selector'>6 Monate</span></Tabs.Tab>
                <Tabs.Tab style={{height: '35px'}} value="third"><span className='consumption-chart-card-heading-month-selector'>9 Monate</span></Tabs.Tab>
                <Tabs.Tab style={{height: '35px'}} value="fourth"><span className='consumption-chart-card-heading-month-selector'>12 Monate</span></Tabs.Tab>
              </Tabs.List>
            </Tabs>}
        </Group>
        <Group style={{overflowX: 'scroll'}}>
            {/* <div><canvas style={{width: 'auto', cursor: 'pointer' }} onMouseEnter={()=> onChartHoverInOut()} onMouseLeave={()=> onChartHoverInOut()} id="acquisitions"></canvas></div> */}
            {/* {props.isMobile ?
            <div style={{overflowX: 'scroll'}}>
              <div class="chart-container" style={{position: 'relative',  width:'900px'}}>
                <Chart type='line' height={props.isMobile && '300px'} data={chartData} options={chartOptions}/>
              </div>
            </div>
            :
            <Chart type='line' height={props.isMobile && '300px'} data={chartData} options={chartOptions}/>} */}
            <Chart type='line' height={props.isMobile && '300px'} data={chartData} options={chartOptions}/>
        </Group>
    </Stack>
  )
}
const mapStateToProps = (state) => {
  return {
    profile: state.generalReducer.profileData,
    account: state.generalReducer.accountData,
    isMobile: state.generalReducer.isMobile,
    isTablet: state.generalReducer.isTablet,
    skeletonLoader: state.generalReducer.skeletonLoader
  };
}


const actions={
  set_loader,
  set_skeleton_loader
}

export default connect(mapStateToProps, actions)(ConsumptionChartCard);