import { useState } from 'react';
import { Group, Loader, Progress, Skeleton, Stack, Table, Tooltip } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { set_loader, set_skeleton_loader } from '../../../redux/action/General/generalActions';

const DashboardTilesCard = (props) => {
    let navigate= useNavigate();
    const [loader, setLoader] = useState(false);

  return (
    <Stack className='dashboard-general-card' onClick={()=> props.openTileDetail(props.tile)} style={{minWidth: '360px', cursor: 'pointer'}} justify='space-around' spacing={'lg'}>
        {<>
            <Group position='apart'>
                <Group noWrap align='flex-end'>
                    <span className='dashboard-general-card-heading'>Aushang</span>
                </Group>
            </Group>
            {loader ?
            <Stack style={{height: 'inherit', marginTop: '18px'}} spacing={40}>
                {[...Array(3)].map((e, i) => 
                    <Group noWrap position='apart'>
                        <Skeleton style={{width: '50%', height: '20px'}}/>
                        <Skeleton style={{width: '25%', height: '20px'}}/>
                    </Group>
                )}
            </Stack>
            : props.tile !== null &&
            <Stack style={{overflow: 'hidden'}}>
                {props.tile.title && <span className='tile-preview-title'>{props.tile.title}</span>}
                <div className='tile-preview-body' style={{height: '110px'}} dangerouslySetInnerHTML={{__html: props.tile.body}}/>
            </Stack>}
            <Group position='right'>
                <Group className='dashboard-general-card-all' onClick={()=> props.openTileDetail(props.tile)} align={'flex-end'}>
                    <span style={{cursor: 'pointer'}}>Mehr anzeigen</span>
                </Group>
            </Group>
        </>}
    </Stack> 
  )
}
const mapStateToProps = (state) => {
    return {
      profile: state.generalReducer.profileData,
      property: state.generalReducer.propertyData,
      account: state.generalReducer.accountData,
      isMobile: state.generalReducer.isMobile,
      isTablet: state.generalReducer.isTablet,
      skeletonLoader: state.generalReducer.skeletonLoader
    };
  }
  
  
  const actions={
    set_loader,
    set_skeleton_loader
}
export default connect(mapStateToProps, actions)(DashboardTilesCard);