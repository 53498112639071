import { Group, Stack } from '@mantine/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Temporary solution to make the PropertySelect component look nice
const defaultPropertyAvatar = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAG4AAABuCAYAAADGWyb7AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAO2SURBVHja7Ns9aBtnHIDx/1kfsWXlXOhBS30mWxW4rc6Qq9whBKPRXXzQrbRaWxoZ0tWhW4pl6CxDx6LLolFoyBJVGhq6VGDRqdGVdjhDc8gi6OR7M8SmJighTlL7Tn2e9Q5xvD/uvfc+pCml5LihUBLKi4hox3BDpSQThpOsUiKnMCkmpVKapNPpsaZJKCJ5TSk1VEoy4/EkC1j8u3QpM9Y0CedERMLwCLSEFIaTrIjInIhIFEWMSEKKomcn2BxDkcyAA46AI+CAI+AIOOAIOAKOgAOOgCPggCPgCDgCDjgCjoADjoAj4Ag44Ag4mnW4IBh5/2e4dNwPsF5vN4NglO31Hn0YBKMFz/Pzg8FBVtdzH3S7d4GLY57n9yqVvdK0bbqeY6ok4Ag4Ag44Ao6AA26GmvUnK+kZBPNrtdYftVprVddzkW0X/tra2vjHNA0LuJhWq7XuV6uNG0EwMo4R51zXX3bd9rJtFw4dZ+3B5maxBFxMqtfbzWq1se55/o0X7dPp9Bc7nX5pZ6cROU7xV8cpzif5LNSUUsMnT8LFOB6c5/m969dvTx1c0zSi3d0v7m9v//RJr/co+zq/v7lZ/NNxivu2ffVmktDm5zOHiYV7m5mmEW1tbbRsu2Am4SwE7rl0PSeOU+xvb39WiDvcTN4O6HpOLGtlfNZXP0EwklqtVXDddpPFyTmDlcvrD8vl9Su6njNOFi6u+2Ct0+m/8qwyGPgGcOeQbRcOK5VPu7ZduCkiq6e3OU6x5DhF8Ty/t7PTeMd128s8ObngLGtl3Gze+dl1v108RnvZAsTa3f1yudu926tUNh4Cd4Ht7X31u2WtfHzGFaRVqWys2nbhELgLyDSN6E2W7rZ9dR84Ai4OBcEoD1wCe/wYOAKOZu4G/Pk6nf0fB4ODwdLSwuVS6aNvgEtA1Wrjh2q18fWp24a/79277ZmmcY2pMqbV6+3vTqOJiHie//6tW3tc4+Kc5/lL06fO/jXgCDgCDjgCjoAj4ICjiyzWj7xM07C63e97L9nltd+Al8vrVxxnbepv6/rCe8C9Md67/8kHsbqeM04+4WOqpHMr1p+gnzXP838ZDA5+m7bNtgufzwpa7P87QC+GY6rkGkfAEXDAEXAEHHAEHAFHwAFHwBFwwBFwBBwBBxwBR8ABR8ARcAQccAQcnQVO0xiIhMIhl5RSKe1fuEwmNcYu/mmaJul0aizy7P9xIiJDpVRmMjnKHh0pRiiGYJp2coJpoYjkT+BERIYMUSLKi4g8HQDQflcjAOF6KQAAAABJRU5ErkJggg==';


class SideBarDropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }


  componentDidMount() {
    console.log('property: ', this.props.property);
  }

  render() {
    const {property} = this.props;
    return (
      <Group className="sidebar-dropdown-container" spacing={10}>
        <img src={property.pictureLocation ? property.pictureLocation : defaultPropertyAvatar} width={40} height={40} style={{borderRadius: '4px'}}/>
        {<Stack spacing={0}>
            <div className="sidebar-dropdown-title">{property.propertyAddress && property.propertyAddress.streetNo ? property.propertyAddress.streetNo : property.name}</div>
            <div className="sidebar-dropdown-address">{property.propertyAddress &&  `${property.propertyAddress.zipCode ? property.propertyAddress.zipCode : ''} ${property.propertyAddress.city ? property.propertyAddress.city : ''}`}</div>
        </Stack>}
      </Group>
    );
  }
}

const mapStateToProps = (state) => {
  return {
      profile: state.generalReducer.profileData,
      isMobile: state.generalReducer.isMobile,
      isTablet: state.generalReducer.isTablet,
      account: state.generalReducer.accountData,
      skeletonLoader: state.generalReducer.skeletonLoader,
      tenant: state.generalReducer.tenantData,
      rentalUnit: state.generalReducer.rentalUnitData,
      property: state.generalReducer.propertyData
  };
};

export default connect(mapStateToProps)(SideBarDropDown);
