import { Button } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import ConsumptionIcon from '../../../assests/svg/Consumption.svg';
import ProfileImage from '../../../assests/svg/TestImage.svg';

const HeadingContainer = (props) => {

  const navigate = useNavigate();
  return (
    <div className='header'>
        <div className='heading-container'>
            {props.icon && <img src={props.icon} width={18} style={{marginRight: '10px'}}/>}
            <span className='heading'>{props.title}</span>
        </div>
        {props.showProfileImage  &&
        <div className='profile-image' onClick={()=> navigate('/profile')}>
            <img src={ProfileImage}/>
        </div>}
        {props.rightButton  &&
        <div className='profile-image'>
            <Button className={`${props.rightButtonClassName} btn-app-text`} onClick={()=> props.rightButtonHandleClick()}>{props.rightButtonText}</Button>
        </div>}
    </div>
  )
}

export default HeadingContainer;