import {Anchor, Center, Group, Menu, Popover, Stack, UnstyledButton} from "@mantine/core";
import MainMenu, { MainMenuItem } from "./MainMenu";

import Icon from "./Icon";
import { Link } from "react-router-dom";
import { useState } from "react";
import SideBarDropDown from "./SidebarDropdown";
import { connect } from "react-redux";
import { useDisclosure } from "@mantine/hooks";

const AppSidebar = (props) => {
  const [hover, setHover] = useState(false);
  const [opened, { close, open }] = useDisclosure(false);
  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/login";
  }
  
  return (
    <Stack 
      spacing="md"
      justify="space-between"
      sx={{ 
        height: '100vh', 
        paddingBottom: 16,
        position: "sticky",
        top: 0,
        bottom: 0,
        minWidth: '54px'
      }}
    >

      {/* Main options and menu */}
      <Stack>
        {!props.isTablet &&
        <>
          <Center pt={24} pb={12}>
            <Anchor href="/"><img src={`/hello-housing-logo.svg`} alt="Hello Housing Logo" /></Anchor>
          </Center>
          <SideBarDropDown/>
        </>}
        <MainMenu/>
      </Stack>
      <Stack spacing={6}>
        <MainMenuItem icon="user" route={'/profile'}>{!props.isTablet && 'Profil'}</MainMenuItem>
        <Group onClick={handleLogout}><MainMenuItem icon="logout" color="red">{!props.isTablet && 'Abmelden'}</MainMenuItem></Group>
      </Stack>
      {/* <Menu trigger="hover" openDelay={100} closeDelay={200} width={'target'}>
        <Menu.Target>
          <div><MainMenuItem icon="user" route={'/profile'}>{!props.isTablet && 'Konto'}</MainMenuItem></div>
        </Menu.Target>
        <Menu.Dropdown>
            <MainMenuItem icon="user" route={'/profile'}>{!props.isTablet && 'Profil'}</MainMenuItem>
            <Group onClick={handleLogout}><MainMenuItem icon="logout" color="red">{!props.isTablet && 'Abmelden'}</MainMenuItem></Group>
        </Menu.Dropdown>
      </Menu> */}
    </Stack>
  )
}

const mapStateToProps = (state) => {
  return {
    profile: state.generalReducer.profileData,
    property: state.generalReducer.propertyData,
    isMobile: state.generalReducer.isMobile,
    isTablet: state.generalReducer.isTablet,
  };
};

export default connect(mapStateToProps)(AppSidebar);