import { api_config } from "../apis";
import { showNotification } from "@mantine/notifications";
import { APIClientGET } from "./APIClient";
import { clearStorageRedirectLogin, getAuthToken } from "../Uitls";
import RedCross from '../../assests/svg/RedCross.svg';
import du from "../language/du";

export async function MessagesGet(propertyId, res) {
      await APIClientGET({
        url: api_config.user_management.messages.messages_get,
        headers: {
            'Content-Type': 'application/json',
            Authorization: getAuthToken(),
          }
      })
        .then(response => {
            console.log('response.result: MessagesGet: ', response);
            if (response.status === 200 && response.data.status) {
                res(response.data.result);
            } else if (response.data.status === false && response.status !== 400 && response.status !== 404) {
                showNotification({
                    message: <div dangerouslySetInnerHTML={{__html: du.general.text_general_error}}/>,
                    id: 'general_error',
                    icon: <img src={RedCross} alt='red-tick'/>,
                });
                res(response.data.result);
            }else{
                res(response.data.result);
            }
        })
        .catch(e => {
            if(e.response.status === 401) {
                clearStorageRedirectLogin();
            }
            console.log('response.result: MessagesGet: ', e.response.data);
            res(e.response);
            if (e.response.data.status === false && e.response.status !== 400 && e.response.status !== 404){
                showNotification({
                    message: <div dangerouslySetInnerHTML={{__html: du.general.text_general_error}}/>,
                    id: 'general_error',
                    icon: <img src={RedCross} alt='red-tick'/>,
                });
            }
        });
}  