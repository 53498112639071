import { Button, Group, Stack } from '@mantine/core';

const EmptyState = (props) => {

  return (
    <Stack className={`empty-state-container ${props.className}`}>
        {props.icon &&
        <Group className={`empty-state-icon ${props.iconClassName}`} align={'center'} position='center'>
            <img src={props.icon} alt='empty-state-icon'/>
        </Group>}
        {props.title &&
        <Group className='empty-state-title'>
            {props.title}
        </Group>}
        {props.subText &&
        <Group className={`empty-state-subtext ${props.subTextClassName}`}>
            {props.subText}
        </Group>}
        {props.buttonText &&
        <Group className='empty-state-cta-button'>
            <Button className={`purple-btn btn-app-text`} onClick={()=> props.onClick()}>{props.buttonText}</Button>
        </Group>}
    </Stack> 
  )
}

export default EmptyState;