import React, { Component } from 'react';
import { Route, Routes} from 'react-router-dom';
import { Group, Box } from '@mantine/core';
import Login from './views/login/Login';
import SignUp from './views/signup/SignUp';
import ForgotPassword from './views/forgotPassword/ForgotPassword';
import EmailVerification from './views/signup/EmailVerification';
import ResetPassword from './views/resetpassword/ResetPassword';
class LoginLayout extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  render() {
    
    return (
        <Group spacing={0}
        sx={{
          alignItems: 'stretch',
          margin: 0
        }}>
            <Box style={{background: 'rgb(247, 247, 250)', flex: '1 1 0%'}}>
              <Routes>
                  <Route path='/*' element={<Login/>}></Route>
                  <Route path='/login' element={<Login/>}></Route>
                  <Route path='/signup' element={<SignUp/>}></Route>
                  <Route path='/emailVerification' element={<EmailVerification/>}></Route>
                  <Route path='/forgotPassword' element={<ForgotPassword/>}></Route>
                  <Route path='/resetPassword' element={<ResetPassword/>}></Route>
              </Routes>
            </Box>
        </Group>
    );
  }
}

export default LoginLayout;