import { Anchor, Breadcrumbs, Group, Input, Progress, Skeleton, Stack, Table, Tooltip } from '@mantine/core';
//import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { color } from '@mui/system';
import FolderIcon from '@mui/icons-material/Folder';
import DescriptionIcon from '@mui/icons-material/Description';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownward from '@mui/icons-material/ArrowDownward';

import dayjs from 'dayjs';
import { useState } from 'react';
import { api_config } from '../../../../helpers/apis';
import { getAuthToken } from '../../../../helpers/Uitls';
import SearchIcon from '../../../../assests/svg/SearchIcon.svg';
import TenantIconBlue from '../../../../assests/svg/TenantIconBlue.svg';
import SearchContainer from '../../HelperComponents/SearchContainer';
import { TableSortLabel } from '@mui/material';

const DocumentsList = (props) => {
    const tenantBreadCrumb = <Group spacing={5}><img src={TenantIconBlue} width='10px'/>{props.tenant.fullName}</Group>;
    const [selectedDirectory, setSelectedDirectory] = useState(null);
    const [breadcrumbs, setBreadcrumbs] = useState([{title: tenantBreadCrumb, href: 'documents/root'}]);
    const [search, setSearch] = useState(null);
    const [hoverHeader, setHoverHeader] = useState(null);
    const [sortHeaderColumn, setSortHeaderColumn] = useState(null);
    const [sortType, setSortType] = useState(false);
    const [loaderForRow, setLoaderForRow] = useState(null);

    console.log('props.documentsData: ', props.documentsData);

    const downloadDocumentsById=(id, name)=>{
        setLoaderForRow(id);
        fetch(api_config.baseUrl+'storage-api/documents/' + id + '/download',
        {
            method: 'GET',
            headers: {
            'Authorization': getAuthToken()
            }
        })
        .then(response => {
            response.blob()
            .then(data => {
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            
            let url = window.URL.createObjectURL(data);
            a.href = url;
            a.download = name;
            a.click();
            window.URL.revokeObjectURL(url);
            setLoaderForRow(null);
            })
        }).catch((e)=> setLoaderForRow(null))
    }

    const openFolder=(id, name)=> {
        setSelectedDirectory(id);
        let newBreadcrumbs= breadcrumbs;
        newBreadcrumbs.find((bc)=> bc.href === id) === undefined ?
        newBreadcrumbs.splice(newBreadcrumbs.length, 0, {title: name, href: id})
        :
        newBreadcrumbs.splice(newBreadcrumbs.findIndex((bc)=> bc.href === id), newBreadcrumbs.length - (newBreadcrumbs.findIndex((bc)=> bc.href === id)+1), {title: name, href: id})
        setBreadcrumbs(newBreadcrumbs);
    }

    const convertFileSize=(sizeString)=>{
        if(sizeString){
          if(sizeString.includes('KB')){
            return parseFloat(sizeString) / 1000;
          }else if(sizeString.includes('GB')){
            return parseFloat(sizeString) * 1000;
          }else if(sizeString.includes('TB')){
            return parseFloat(sizeString) * 1000000;
          }else if(sizeString.includes('B') && (!sizeString.includes('M') || !sizeString.includes('G') || !sizeString.includes('T') || !sizeString.includes('K'))){
            return parseFloat(sizeString) / 1000000;
          }else {
            return parseFloat(sizeString);
          }
        }else{
          return 0;
        }
      }

    return (
        <Stack spacing={40}>
            <SearchContainer
            value={search}
            placeholder={'Suche nach Dokumenten'}
            style={{width: props.isMobile ? '100%' : props.isTablet ? '50%' : '35%'}}
            classNames={{
                input: 'search-input'
            }}
            onChange={(event) => setSearch(event.currentTarget.value)}
            />
            <Breadcrumbs 
            separator={
            <ArrowForwardIosIcon 
            style={{
              color: '#5655FE',
              fontSize: '15px',
              overflow: 'scroll'
              
            }}/>}
            styles={{    
              root: { overflowX: 'scroll' },
            }}
            classNames={{
              root: 'documents-breadcrumbs-scrollbar'
            }}
            >
                {breadcrumbs.map((breadcrumb, index)=> 
                    <Anchor key={index} style={{color: '#5555FF'}}>
                        <span style={{fontSize: '14px', fontWeight: '500', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} 
                        onClick={()=> {
                            if(breadcrumb.href === 'documents/root'){
                                props.getDocumentsByAccountId();
                                setSelectedDirectory(null);
                                setBreadcrumbs([{title: tenantBreadCrumb, href: 'documents/root'}]);
                            }else{
                                openFolder(breadcrumb.href, breadcrumb.title)
                            }
                        }}>{breadcrumb.title}</span>
                    </Anchor>
                )}
            </Breadcrumbs>
            {props.documentsData.length > 0 && 
            <Table className='documents-table'>
                <thead className='documents-table-header-column'>
                    <tr>
                        <td style={{padding: '0px 16px'}} width={'50%'}>
                            <Group align={'center'} spacing={5} style={{width: '100px'}} onClick={()=> {
                                setSortType(!sortType);
                                setSortHeaderColumn('Name');
                            }} onMouseEnter={()=> setHoverHeader('Name')} onMouseLeave={()=> setHoverHeader(null)}>
                                <span>Name</span>
                                <div>
                                    {sortType ?
                                    <ArrowDownward
                                    style={{
                                    color: '#727293',
                                    fontSize: '16px',
                                    display: (sortHeaderColumn === 'Name' || hoverHeader === 'Name') ? 'block' : 'none'
                                    }}/>
                                    :
                                    <ArrowUpwardIcon
                                    style={{
                                    color: '#727293',
                                    fontSize: '16px',
                                    display: (sortHeaderColumn === 'Name' || hoverHeader === 'Name') ? 'block' : 'none'
                                    }}/>}
                                </div>
                            </Group>
                        </td>
                        <td style={{padding: '0px 16px', textAlign: 'right'}}>
                            <Group align={'center'} position={'right'} spacing={5} style={{width: '100%'}} onClick={()=> {
                                setSortType(!sortType);
                                setSortHeaderColumn('Geändert');
                            }} onMouseEnter={()=> setHoverHeader('Geändert')} onMouseLeave={()=> setHoverHeader(null)}>
                                <div>
                                    {sortType ?
                                    <ArrowDownward
                                    style={{
                                    color: '#727293',
                                    fontSize: '16px',
                                    display: (sortHeaderColumn === 'Geändert' || hoverHeader === 'Geändert') ? 'block' : 'none'
                                    }}/>
                                    :
                                    <ArrowUpwardIcon
                                    style={{
                                    color: '#727293',
                                    fontSize: '16px',
                                    display: (sortHeaderColumn === 'Geändert' || hoverHeader === 'Geändert') ? 'block' : 'none'
                                    }}/>}
                                </div>
                                <span>Geändert</span>
                            </Group>
                        </td>
                        {/* <td style={{padding: '0px 16px'}}>
                        <Group position='right' spacing={5} style={{width: '100%'}} onClick={()=> {
                                setSortType(!sortType);
                                setSortHeaderColumn('Größe');
                            }} onMouseEnter={()=> setHoverHeader('Größe')} onMouseLeave={()=> setHoverHeader(null)}>
                                <div>
                                    {sortType ?
                                    <ArrowDownward
                                    style={{
                                    color: '#727293',
                                    fontSize: '16px',
                                    display: (sortHeaderColumn === 'Größe' || hoverHeader === 'Größe') ? 'block' : 'none'
                                    }}/>
                                    :
                                    <ArrowUpwardIcon
                                    style={{
                                    color: '#727293',
                                    fontSize: '16px',
                                    display: (sortHeaderColumn === 'Größe' || hoverHeader === 'Größe') ? 'block' : 'none'
                                    }}/>}
                                </div>
                                <span>Größe</span>
                            </Group>
                        </td> */}
                    </tr>
                </thead>
                <tbody className='documents-table-tbody'>
                    {props.documentsData
                    .filter((document)=> search ? document.name.toLowerCase().includes(search.toLowerCase()) : document)
                    .sort((a,b)=> sortHeaderColumn &&
                    (sortHeaderColumn === 'Name' ? (sortType ? a.name.toLowerCase().localeCompare(b.name.toLowerCase()) : b.name.toLowerCase().localeCompare(a.name.toLowerCase()))
                    :
                    sortHeaderColumn === 'Geändert' ? sortType ? dayjs(a.updatedOn).isBefore(b.updatedOn, 'minutes') : dayjs(b.updatedOn).isBefore(a.updatedOn, 'minutes') 
                    :
                    sortHeaderColumn === 'Größe' && sortType ? convertFileSize(a.fileSize) - convertFileSize(b.fileSize) : convertFileSize(b.fileSize) - convertFileSize(a.fileSize)))
                    .map((document)=> 
                        document.parentDirectoryId === selectedDirectory &&
                        (document.id === loaderForRow ?
                        <tr>
                            <td colSpan={3} style={{padding: 0}}>
                                <Skeleton style={{width: '100%', height: '71px'}}/>
                            </td>
                            
                        </tr>
                        :
                        <tr className='documents-table-row' onClick={()=> document.isDirectory ? openFolder(document.id, document.name) : downloadDocumentsById(document.id, document.name)}>
                            <td className='documents-table-column'>
                                <Group>
                                    {document.isDirectory ?
                                    <FolderIcon 
                                    style={{
                                    color: '#5655FE',
                                    fontSize: '20px'
                                    }}/>
                                    :
                                    <DescriptionIcon 
                                    style={{
                                    color: '#5655FE',
                                    fontSize: '20px'
                                    }}/>}
                                    <span>{document.name}</span> 
                                </Group>
                            </td>
                            <td className='documents-table-column' style={{fontWeight: '400', textAlign: 'right'}}>{dayjs(document.updatedOn).format('DD.MM.YYYY')}</td>
                            {/* <td className='documents-table-column' style={{fontWeight: '400', textAlign: 'right'}}>{document.fileSize}</td> */}
                        </tr>)
                    )}
                </tbody>
            </Table>}
        </Stack> 
    )
}

export default DocumentsList;