import { api_config } from "../apis";
import { showNotification } from "@mantine/notifications";
import { APIClientGET, APIClientPUT } from "./APIClient";
import { clearStorageRedirectLogin, getAuthToken } from "../Uitls";
import RedCross from '../../assests/svg/RedCross.svg';
import du from "../language/du";

export async function UserGet(id, res) {
      await APIClientGET({
        url: api_config.user_management.user.user_get + id,
        headers: {
            'Content-Type': 'application/json',
            Authorization: getAuthToken(),
          }
      })
        .then(response => {
            console.log('response.result: UserGet: ', response);
            if (response.status === 200 && response.data.status) {
                res(response.data.result);
            } else //if (response.data.status === false && response.status !== 400 && response.status !== 404) 
            {
                showNotification({
                    message: <div dangerouslySetInnerHTML={{__html: du.general.text_general_error}}/>,
                    id: 'general_error',
                    icon: <img src={RedCross} alt='red-tick'/>,
                });
                res(response.data.result);
            }
        })
        .catch(e => {
            if(e.response.status === 401) {
                clearStorageRedirectLogin();
            }
            console.log('response.result: UserGet: ', e);
            showNotification({
                message: <div dangerouslySetInnerHTML={{__html: du.general.text_general_error}}/>,
                id: 'general_error',
                icon: <img src={RedCross} alt='red-tick'/>,
            });
        });
}

export async function UserPut(data, id, res) {
    await APIClientPUT({
        url: api_config.user_management.user.user_put + id,
        headers: {
          'Content-Type': 'application/json',
          Authorization: getAuthToken(),
        },
        data: data
    })
      .then(response => {
          console.log('response.result: UserPut: ', response);
          if (response.status === 200 && response.data.status) {
              res(response.data.result);
          } else {
              showNotification({
                message: <div dangerouslySetInnerHTML={{__html: du.general.text_general_error}}/>,
                id: 'general_error',
                icon: <img src={RedCross} alt='red-tick'/>,
              });
              res(response.data.result);
          }
      })
      .catch(e => {
        if(e.response.status === 401) {
            clearStorageRedirectLogin();
        }
          console.log('response.result: UserPut: ', e);
          if(e.response.data.message === 'EMAIL_EXISTS'){
            showNotification({
                message: 'Es ist ein Fehler aufgetreten. Die Email-Adresse ist nicht verfügbar.',
                icon: <img src={RedCross} alt='red-tick'/>,
            });
            res(e.response.data.message);
          }else{
            showNotification({
                message: <div dangerouslySetInnerHTML={{__html: du.general.text_general_error}}/>,
                id: 'general_error',
                icon: <img src={RedCross} alt='red-tick'/>,
            });
          }
      });
}

export async function UserPasswordPut(data, id, res) {
    await APIClientPUT({
        url: api_config.user_management.user.user_put + id + '/changePassword',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getAuthToken(),
        },
        data: data
    })
      .then(response => {
          console.log('response.result: UserPasswordPut: ', response);
          if (response.status === 200 && response.data.status) {
              res(response.data.result);
          } else {
              showNotification({
                  message: 'Ein Fehler ist aufgetreten.',
                  icon: <img src={RedCross} alt='red-tick'/>,
              });
              res(response.data.result);
          }
      })
      .catch(e => {
            if(e.response.status === 401) {
                clearStorageRedirectLogin();
            }
            console.log('response.result: UserPasswordPut: ', e);
            showNotification({
                message: 'Ein Fehler ist aufgetreten.',
                icon: <img src={RedCross} alt='red-tick'/>,
            });
      });
}