import { Component } from 'react';
import { Accordion, Button, Group, Progress } from '@mantine/core';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import oneIcon from '../../../assests/svg/firstStepSectionIcons/1.svg';
import twoIcon from '../../../assests/svg/firstStepSectionIcons/2.svg';
import threeIcon from '../../../assests/svg/firstStepSectionIcons/3.svg';
import fourIcon from '../../../assests/svg/firstStepSectionIcons/4.svg';
import fiveIcon from '../../../assests/svg/firstStepSectionIcons/5.svg';
import sixIcon from '../../../assests/svg/firstStepSectionIcons/6.svg';
import sevenIcon from '../../../assests/svg/firstStepSectionIcons/7.svg';
import firstStepContent from '../../../assests/first-step-content.json';
import UtilityBillingAssignedIcon from '../../../assests/svg/utilityBillingAssigned';
import HelpLogo from '../../../assests/svg/HelpLogo.svg';
import { Typography } from '@mui/material';


class FirstStepSectionCard extends Component {
    state={
        expanded: false,
        subExpanded: false,
        validateFirstSection: {
            property: true,
            rentalUnit: false,
            tenant: true,
            meter: false,
            meterReading: false,
            receipt: false,
            utilityBilling: false
        },
        firstStepIcons: [
            oneIcon,
            twoIcon,
            threeIcon,
            fourIcon,
            fiveIcon,
            sixIcon,
            sevenIcon
        ],
  }

  constructor(props) {
    super(props)
  }

  componentDidMount() {

  }

  handleChange = () => {
    this.setState({expanded: !this.state.expanded});
  };

  render(){
    const {expanded, subExpanded, firstStepIcons, validateFirstSection} = this.state;
    console.log('subExpanded: ', subExpanded);
    return (
        <Group grow>
            <Accordion expanded={expanded} onChange={this.handleChange}
            styles={{
                chevron: {
                '&[data-rotate]': {
                    transform: 'rotate(90deg)',
                },
                },
            }}>
                <Accordion.Item value={'main-accordion'}>
                    <Accordion.Control className={'dashboard-first-step-guide'} style={{background: 'white', borderRadius: '4px', boxShadow: expanded && 'none'}}>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div className='dashboard-first-step-guide-title'>Ihre ersten Schritte mit hellohousing</div>
                    <Group className='dashboard-first-step-guide-title' spacing={5}>
                      <span style={{fontSize: '14px', color: '#13135C', fontWeight: '500'}}>{Object.values(this.state.validateFirstSection).filter((value)=> value !== false && value !== undefined).length} / {firstStepContent.length}</span>
                      {expanded ? <ExpandMoreIcon style={{color: '#13135C'}} /> : <NavigateNextIcon style={{color: '#13135C'}} />}
                    </Group>  
                  </div>
                  <Progress value={(Object.values(this.state.validateFirstSection).filter((value)=> value !== false && value !== undefined).length / 7) * 100} size={'sm'} color={'#5353FE'} radius={`0px 0px ${expanded ? '0px 0px' : '4px 4px'}`} className={'dashboard-first-step-guide-progressbar'} />
                    </Accordion.Control>
                    <Accordion.Panel style={{background: 'white', borderBottomLeftRadius: '4px', borderBottomRightRadius: '4px', boxShadow: '0 1px 3px rgba(0, 0, 0, 0.08)', padding: '16px 0px'}}>
                            {firstStepContent.map((item, index)=>
                            {
                            return <Accordion expanded={subExpanded === index} onChange={()=> this.setState({subExpanded: subExpanded === `sub-accordion-${index}` ? false : `sub-accordion-${index}`})} className={'dashboard-frist-step-sub-accordions'}
                            chevron={<NavigateNextIcon style={{color: '#13135C'}}/>}
                            styles={{
                                chevron: {
                                '&[data-rotate]': {
                                    transform: 'rotate(90deg)',
                                },
                                },
                            }}>
                                <Accordion.Item value={`sub-accordion-${index}`}>
                                    <Accordion.Control className='first-step-accordion-content' style={{background: 'white'}}>
                                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                                {validateFirstSection[item.stateValidationAttribute] ? 
                                                <span className='first-step-done'>
                                                {UtilityBillingAssignedIcon('#5555FF')}
                                                </span>
                                                : 
                                                <div className='first-step-numbering'
                                                style={{marginRight: '10px'}}
                                                >
                                                <img src={firstStepIcons[index]} alt={index}/>
                                                </div>}
                                                <span className='first-step-title' style={{opacity: subExpanded === index ? '1' : validateFirstSection[item.stateValidationAttribute] && '0.5'}}>
                                                {item.title}
                                                </span>
                                            </div>
                                            <NavigateNextIcon style={{color: '#13135C', transform: subExpanded === `sub-accordion-${index}` && 'rotate(90deg)'}} />
                                        </div>
                                    </Accordion.Control>
                                    <Accordion.Panel style={{background: 'white'}}>
                                        <div dangerouslySetInnerHTML={{__html: item.description}}/>
                                        <div style={{display: 'flex'}}>
                                        <div>
                                            <Button className={'purple-btn'}>
                                                {item.buttonText}
                                            </Button>
                                        </div>
                                        <div style={{display: 'flex', width: 'auto', alignItems: 'center', marginLeft: '20px', cursor: 'pointer'}} onClick={()=> window.Intercom('showArticle', item.articleNo)}>
                                            <img src={HelpLogo} />
                                            <span style={{color: '#5555FF', marginLeft: '5px', fontSize: '14px', marginTop: '3px'}}>{item.buttonKnowledgeBase}</span>
                                            
                                        </div>
                                        {item.articleNo2 !== '' && item.buttonKnowledgeBase2 !== '' && 
                                        <div style={{display: 'flex', width: 'auto', alignItems: 'center', marginLeft: '20px', cursor: 'pointer'}} onClick={()=> window.Intercom('showArticle', item.articleNo2)}>
                                            <img src={HelpLogo} />
                                            <span style={{color: '#5555FF', marginLeft: '5px', fontSize: '14px', marginTop: '3px'}}>{item.buttonKnowledgeBase2}</span>
                                            
                                        </div>}
                                        </div>
                                    </Accordion.Panel>
                                </Accordion.Item>
                            </Accordion>
                            })}
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>
            {/* <Accordion       
            chevron={<NavigateNextIcon style={{color: '#13135C'}}/>}
            styles={{
                chevron: {
                '&[data-rotate]': {
                    transform: 'rotate(90deg)',
                },
                },
            }} >
                <Accordion.Item value="customization">
                    <Accordion.Control className={'dashboard-first-step-guide'} style={{background: 'white', borderRadius: '4px', boxShadow: expanded && 'none'}}>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div className='dashboard-first-step-guide-title'>Ihre ersten Schritte mit hellohousing</div>
                        <div className='dashboard-first-step-guide-title'>
                            <span style={{fontSize: '14px', color: '#13135C', fontWeight: '500'}}>{Object.values(this.state.validateFirstSection).filter((value)=> value !== false && value !== undefined).length} / {firstStepContent.length}</span>
                        </div>  
                        </div>
                        <Progress value={(Object.values(this.state.validateFirstSection).filter((value)=> value !== false && value !== undefined).length / 7) * 100} size={'sm'} color={'#5353FE'} radius={`0px 0px ${expanded ? '0px 0px' : '4px 4px'}`} className={'dashboard-first-step-guide-progressbar'} />
                    </Accordion.Control>
                    <Accordion.Panel>Colors, fonts, shadows and many other parts are customizable to fit your design needs</Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value="focus-ring">
                    <Accordion.Control>No annoying focus ring</Accordion.Control>
                    <Accordion.Panel>With new :focus-visible pseudo-class focus ring appears only when user navigates with keyboard</Accordion.Panel>
                </Accordion.Item>
            </Accordion> */}
        </Group>
      );
  }
}

export default FirstStepSectionCard;