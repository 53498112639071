import { api_config } from "../apis";
import { showNotification } from "@mantine/notifications";
import { APIClientPOST } from "./APIClient";
import RedCross from '../../assests/svg/RedCross.svg';
import du from "../language/du";
import { clearStorageRedirectLogin } from "../Uitls";

export async function SignInPost(data, set_loader, res) {
      await APIClientPOST({
        url: api_config.authorization.signin.signin_post,
        data: data
      })
        .then(response => {
            console.log('response.result: SignInPost: ', response);
            if (response.status === 200 && response.data.status) {
                res(response.data.result);
            } else {
                if(response.data.message === 'FORBIDDEN'){
                    showNotification({
                        message: <div dangerouslySetInnerHTML={{__html: du.general.text_general_error}}/>,
                        id: 'general_error',
                        icon: <img src={RedCross} alt='red-tick'/>,
                        autoClose: false
                    });
                }else{
                    showNotification({
                        id: 'user-sigin',
                        message: <div dangerouslySetInnerHTML={{__html: du.general.text_general_error}}/>,
                        icon: <img src={RedCross} alt='red-tick'/>,
                    });
                }
                res(response.data.result);
                set_loader(false);
            }
        })
        .catch(e => {
            if(e.response.status === 401) {
              clearStorageRedirectLogin();
            }
            console.log('response.result: SignInPost: ', e.response.data.message);
            if(e.response.data.message === 'FORBIDDEN'){
                showNotification({
                    id: 'user-sigin',
                    message: <div dangerouslySetInnerHTML={{__html: du.general.text_general_error}}/>,
                    icon: <img src={RedCross} alt='red-tick'/>,
                    autoClose: false
                });
            }else{
                showNotification({
                    id: 'user-sigin',
                    message: <div dangerouslySetInnerHTML={{__html: du.general.text_general_error}}/>,
                    icon: <img src={RedCross} alt='red-tick'/>
                });
            }
            set_loader(false);
        });
}

export async function TenantResetPasswordPost(data, set_loader, res) {
    await APIClientPOST({
      url: api_config.authorization.signin.tenant_reset_password_post,
      data: data
    })
      .then(response => {
          console.log('response.result: TenantResetPasswordPost: ', response);
          if (response.status === 200 && response.data.status) {
              res(response.data.result);
          } else {
              showNotification({
                message: <div dangerouslySetInnerHTML={{__html: du.general.text_general_error}}/>,
                id: 'general_error',
                icon: <img src={RedCross} alt='red-tick'/>
              });
              res(response.data.result);
              set_loader(false);
          }
      })
      .catch(e => {
          if(e.response.status === 401) {
            clearStorageRedirectLogin();
          }
          console.log('response.result: TenantResetPasswordPost: ', e);
          showNotification({
            message: <div dangerouslySetInnerHTML={{__html: du.general.text_general_error}}/>,
            id: 'general_error',
            icon: <img src={RedCross} alt='red-tick'/>
          });
          set_loader(false);
      });
}

export async function ResetPasswordPost(data, set_loader, res) {
    await APIClientPOST({
      url: api_config.authorization.signin.reset_password_post,
      data: data
    })
      .then(response => {
          console.log('response.result: ResetPasswordPost: ', response);
          if (response.status === 200 && response.data.status) {
              res(response.data.result);
          } else {
              showNotification({
                message: <div dangerouslySetInnerHTML={{__html: du.general.text_general_error}}/>,
                id: 'general_error',
                icon: <img src={RedCross} alt='red-tick'/>
              });
              res(response.data.result);
              set_loader(false);
          }
      })
      .catch(e => {
          if(e.response.status === 401) {
            clearStorageRedirectLogin();
          }
          console.log('response.result: ResetPasswordPost: ', e);
          showNotification({
            message: <div dangerouslySetInnerHTML={{__html: du.general.text_general_error}}/>,
            id: 'general_error',
            icon: <img src={RedCross} alt='red-tick'/>
          });
          set_loader(false);
      });
}

export async function PasswordInstructionsPost(data, set_loader, res) {
    await APIClientPOST({
      url: api_config.authorization.signin.password_instructions_post,
      data: data
    })
      .then(response => {
          console.log('response.result: PasswordInstructionsPost: ', response);
          if (response.status === 200 && response.data.status) {
              res(response.data.result);
          } else {
              showNotification({
                message: <div dangerouslySetInnerHTML={{__html: du.general.text_general_error}}/>,
                id: 'general_error',
                icon: <img src={RedCross} alt='red-tick'/>
              });
              res(response.data.result);
              set_loader(false);
          }
      })
      .catch(e => {
          if(e.response.status === 401) {
            clearStorageRedirectLogin();
          }
          console.log('response.result: PasswordInstructionsPost: ', e);
          showNotification({
            message: <div dangerouslySetInnerHTML={{__html: du.general.text_general_error}}/>,
            id: 'general_error',
            icon: <img src={RedCross} alt='red-tick'/>
          });
          set_loader(false);
      });
}

export async function TenantActivationStatusPost(data, set_loader, res) {
    await APIClientPOST({
      url: api_config.authorization.signin.tenant_activation_status_post,
      data: data
    })
      .then(response => {
          console.log('response.result: TenantActivationStatusPost: ', response);
          if (response.status === 200 && response.data.status) {
              res(response.data.result);
          } else {
              showNotification({
                message: <div dangerouslySetInnerHTML={{__html: du.general.text_general_error}}/>,
                id: 'general_error',
                icon: <img src={RedCross} alt='red-tick'/>
              });
              res(response.data.result);
              set_loader(false);
          }
      })
      .catch(e => {
          if(e.response.status === 401) {
            clearStorageRedirectLogin();
          }
          console.log('response.result: TenantActivationStatusPost: ', e);
          showNotification({
            message: <div dangerouslySetInnerHTML={{__html: du.general.text_general_error}}/>,
            id: 'general_error',
            icon: <img src={RedCross} alt='red-tick'/>
          });
          set_loader(false);
      });
}

export async function EmailVerificationInstructionsPost(data, set_loader, res) {
    await APIClientPOST({
      url: api_config.authorization.signin.email_verification_instructions_post,
      data: data
    })
      .then(response => {
          console.log('response.result: EmailVerificationInstructions: ', response);
          if (response.status === 200 && response.data.status) {
              res(response.data.result);
          } else {
              showNotification({
                message: <div dangerouslySetInnerHTML={{__html: du.general.text_general_error}}/>,
                id: 'general_error',
                icon: <img src={RedCross} alt='red-tick'/>
              });
              res(response.data.result);
              set_loader(false);
          }
      })
      .catch(e => {
          if(e.response.status === 401) {
            clearStorageRedirectLogin();
          }
          console.log('response.result: EmailVerificationInstructions: ', e);
          showNotification({
            message: <div dangerouslySetInnerHTML={{__html: du.general.text_general_error}}/>,
            id: 'general_error',
            icon: <img src={RedCross} alt='red-tick'/>
          });
          set_loader(false);
      });
}

export async function VerifyEmailPost(data, set_loader, res) {
    await APIClientPOST({
      url: api_config.authorization.signin.verify_email_post,
      data: data
    })
      .then(response => {
          console.log('response.result: VerifyEmailPost: ', response);
          if (response.status === 200 && response.data.status) {
              res(response.data.result);
          } else {
              showNotification({
                message: <div dangerouslySetInnerHTML={{__html: du.general.text_general_error}}/>,
                id: 'general_error',
                icon: <img src={RedCross} alt='red-tick'/>
              });
              res(response.data.result);
              set_loader(false);
          }
      })
      .catch(e => {
          if(e.response.status === 401) {
            clearStorageRedirectLogin();
          }
          console.log('response.result: VerifyEmailPost: ', e);
          showNotification({
            message: <div dangerouslySetInnerHTML={{__html: du.general.text_general_error}}/>,
            id: 'general_error',
            icon: <img src={RedCross} alt='red-tick'/>
          });
          set_loader(false);
      });
}