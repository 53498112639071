import React, { Component } from 'react';
import { BrowserRouter, Route, Routes, redirect } from 'react-router-dom';
import RoutesModuleGeneral from '../routes/general';
import { connect } from 'react-redux';

import AppSidebar from "./components/Sidebar/AppSidebar";
import { Group, Box, Stack } from '@mantine/core';
import Dashboard from './views/dashboard/Dashboard';
import Consumption from './views/consumption/Consumption';
import Profile from './views/profile/Profile';
import { UserGet } from '../helpers/fetchApis/Users';
import {set_profile_data, set_loader, set_property_data, set_rental_unit_data, set_tenant_data, set_rent_data} from '../redux/action/General/generalActions';
import { PropertyGet, RentalUnitGet, RentGet, TenantGet } from '../helpers/fetchApis/Properties';
import AppBottomNavBar from './components/BottomNavBar/AppBottomNavBar';
import Documents from './views/documents/Documents';
import Messages from './views/messages/Messages';
import MessageDetailView from './views/messages/MessageDetailView';

class MainApp extends Component {

  constructor(props) {
    super(props);
    document.querySelector('meta[name="description"]').setAttribute("content", 'Im hellohousing Mieterportal finden Sie Ihre aktuellen Verbräuche, alle Dokumente und tauschen Nachrichten aus.');
    document.title= 'hellohousing Mieterportal';
  }

  componentDidMount() {
    const {profile, set_profile_data, set_property_data, set_rental_unit_data, set_tenant_data, set_rent_data} = this.props;
    TenantGet(profile.id, tenantRes=> {
      set_tenant_data(tenantRes);
      RentalUnitGet(tenantRes.rentalUnitId, ruRes=> {
        set_rental_unit_data(ruRes);
        PropertyGet(ruRes.id, propertyRes=> {
          set_property_data(propertyRes);
          RentGet(profile.id, rentRes=>{
            set_rent_data(rentRes);
          });
        });
      });
    });
  }

  render() {
    const {isTablet, isMobile, property} = this.props;

    console.log('props: ', this.props);
    console.log('property: ', property);
    return (
      <Stack>
        <Group spacing={0}
        sx={{
          alignItems: 'stretch',
          margin: 0
        }}
        noWrap>
            {!isMobile && <Box
            sx={(theme) => ({
                borderRight: `1px solid rgba(218, 218, 229, 1)`,
                padding: 0,
                width: isTablet ? 55 : 270,
                position: "relative",
              })}>
                <AppSidebar/>
            </Box>}
            <Box style={{background: 'rgb(247, 247, 250)', flex: '1 1 0%', paddingBottom: isMobile && '40px'}}>
              <Routes>
                  <Route path='/*' element={<Dashboard/>}></Route>
                  <Route path='/dashboard' element={<Dashboard/>}></Route>
                  {!property.isConsumptionSectionHidden && <Route path='/consumption' element={<Consumption/>}></Route>}
                  <Route path='/profile' element={<Profile/>}></Route>
                  {!property.isDocumentSectionHidden && <Route path='/documents' element={<Documents/>}></Route>}
                  {!property.isMessageSectionHidden && <Route path='/messages' element={<Messages/>}></Route>}
                  {!property.isMessageSectionHidden && <Route path='/messages/messageDetail' element={<MessageDetailView/>}></Route>}
              </Routes>
            </Box>
        </Group>
        {isMobile && <AppBottomNavBar/>}
      </Stack>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    profile: state.generalReducer.profileData,
    property: state.generalReducer.propertyData,
    isMobile: state.generalReducer.isMobile,
    isTablet: state.generalReducer.isTablet
  };
};

const actions = {
  set_profile_data,
  set_loader,
  set_property_data,
  set_rental_unit_data,
  set_tenant_data,
  set_rent_data
};

export default connect(mapStateToProps, actions)(MainApp);