import { Group, Stack } from '@mantine/core';

import dayjs from 'dayjs';
import { useState } from 'react';
import SearchContainer from '../../HelperComponents/SearchContainer';
import { useNavigate } from "react-router-dom";

const MessagesList = (props) => {
    const navigate = useNavigate();
    const [search, setSearch] = useState(null);
    const replaceValuesWithPlaceHolders=(html)=>{
        let newHtml= html;
    
        newHtml= newHtml.replaceAll('{{BILLING_PERIOD}}', '<a href="" target="_self">Abrechnungszeitraum</a>');
        newHtml= newHtml.replaceAll('{{TOTAL_ALLOCATED_UTILITY_COSTS}}', '<a href="" target="_self">Betriebskosten</a>');
        newHtml= newHtml.replaceAll('{{BILLING_STATEMENT_BALANCE}}', '<a href="" target="_self">Guthaben / Nachzahlung</a>');
        newHtml= newHtml.replaceAll('{{RECIPIENT_NAME}}', '<a href="" target="_self">Empfänger</a>');
        newHtml= newHtml.replaceAll('{{RENTAL_UNIT_NAME}}', '<a href="" target="_self">Mieteinheit</a>');
        newHtml= newHtml.replaceAll('{{PROPERTY_STREET}}, {{PROPERTY_ZIPCODE}} {{PROPERTY_CITY}}', '<a href="" target="_self">Objektadresse</a>');
        newHtml= newHtml.replaceAll('{{TOTAL_ADVANCE_PAYMENTS}}', '<a href="" target="_self">Vorauszahlungen</a>');
    
        return newHtml;
    }
    console.log('props.messages: ', props.messages);
    return (
        <Stack spacing={40}>
            <SearchContainer
            value={search}
            placeholder={'Suche nach Nachrichten'}
            style={{width: props.isMobile ? '100%' : props.isTablet ? '50%' : '35%'}}
            classNames={{
                input: 'search-input'
            }}
            onChange={(event) => setSearch(event.currentTarget.value)}
            />
            <Stack spacing={20}>
                {props.messages.length > 0 && 
                props.messages.filter((message)=> search ? (message.subject.includes(search) || message.body.includes(search)) : message)
                .map((message)=> 
                <Group key={`message-${message.id}`} align='flex-start' className={'message-card'} noWrap={true} onClick={()=> navigate('/messages/messageDetail', {state: {message: message}})}>
                    <Stack justify={'space-between'} style={{overflowX: 'hidden', textOverflow: 'ellipsis', width: '100%', height: '100%'}}>
                        <Group style={{width: '100%'}} position='apart' noWrap>
                            <Group align={'center'} style={{height: '24px', flex: '1 1 auto', overflowY: 'auto'}} spacing={10} noWrap>
                                <span className={'message-card-title'} dangerouslySetInnerHTML={{__html: message.subject}}></span>
                            </Group>
                            <Group noWrap>
                                <span className='message-card-time'>{dayjs(message.updatedOn).format('DD.MM.YYYY') === dayjs().format('DD.MM.YYYY') ? `heute (${dayjs(message.updatedOn).format('HH:mm')} Uhr)` : dayjs(message.updatedOn).format('DD.MM.YYYY')}</span>
                            </Group>
                        </Group>
                        <span className={'message-card-description'} dangerouslySetInnerHTML={{__html: message.body}}/>
                    </Stack>
                </Group>
                )}
            </Stack>
            
        </Stack> 
    )
}

export default MessagesList;