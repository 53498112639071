import { Accordion, Group, Progress, Stack } from '@mantine/core';
import ConsumptionIcon from '../../../assests/svg/Consumption.svg';
import ProfileImage from '../../../assests/svg/TestImage.svg';
import HighConsumption from '../../../assests/svg/HighConsumption.svg';
import LowConsumption from '../../../assests/svg/LowConsumption.svg';
import { color } from '@mui/system';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useState } from 'react';
import spartippsContent from '../../../assests/spartipps-content.json';
import { connect } from 'react-redux';

const TipsCard = (props) => {
    const [value, setValue] = useState(null);
    const [showMore, setShowMore] = useState(false);
    const accordian = (limit)=> (
    <Accordion value={value} onChange={setValue}>
        {spartippsContent.map((item, index)=>
        index <= limit &&
        <Accordion.Item value={`tips-accordian-${index}`} className='tips-accordian-item'>
            <Accordion.Control style={{borderBottomLeftRadius: value === `tips-accordian-${index}` && '0px', borderBottomRightRadius: value === `tips-accordian-${index}` && '0px'}}>
                <Group style={{justifyContent: 'space-between'}} noWrap>
                    <Stack spacing={8}>
                        <span className='tips-accordian-item-type'>{item.type}</span>
                        <span className={props.isTablet ? 'tips-accordian-item-heading-tablet' : 'tips-accordian-item-heading'}>{item.title}</span>
                    </Stack>
                    <Group>
                        <NavigateNextIcon style={{color: '#13135C', transform: value === `tips-accordian-${index}` ? 'rotate(270deg)' : 'rotate(90deg)'}} />
                    </Group>
                </Group>
            </Accordion.Control>
            <Accordion.Panel>
                <Stack>
                    <Group noWrap align={'flex-start'} spacing={10}>
                        <img src={item.image} className='tips-accordian-item-image' width={'60px'} height={'60px'}/>
                        <Stack spacing={6}>
                            <p className='tips-accordian-item-description'>{item.description}</p>
                            <span className='tips-accordian-item-more' onClick={()=> window.open(item.link)}>Mehr erfahren</span>
                        </Stack>
                    </Group>
                </Stack>
            </Accordion.Panel>
        </Accordion.Item>)}
    </Accordion>);
    console.log('props.isTablet: ', props);
    return (
        <Stack className={props.isTablet ? 'tips-card-tablet' : 'tips-card'} spacing={'xs'}>
            <Group className='tips-card-header'>
                <span>Spartipps</span>
            </Group>
            <Stack spacing={5}>
                {showMore ? accordian(spartippsContent.length - 1) : accordian(2)}
                {spartippsContent.length > 3 &&
                <Group>
                    <span onClick={()=> !showMore ? setShowMore(true) : setShowMore(false)} className='tips-accordian-item-more-tips'>{!showMore ? 'Weitere Spartipps' : 'Schließen'}</span>
                </Group>}
            </Stack>
        </Stack> 
    )
}

export default TipsCard;