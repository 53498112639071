import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import { Group, TextInput, Button, Stack, PasswordInput } from '@mantine/core';
import {set_profile_data, set_account_data, set_token, set_loader} from '../../../redux/action/General/generalActions';
import Logo from '../../../assests/svg/Logo.svg';
import EyeIcon from '../../../assests/svg/EyeIcon.svg';
import { check_auth } from '../../../helpers/Uitls';
import { SignInPost } from '../../../helpers/fetchApis/Auth';
import { AccountGet } from '../../../helpers/fetchApis/Accounts';
import { hideNotification } from '@mantine/notifications';
import { UserGet } from '../../../helpers/fetchApis/Users';

class Login extends Component {

    state={
        visible: false,
        password: '',
        email: '',
    }
    constructor(props) {
        super(props);
        document.querySelector('meta[name="description"]').setAttribute("content", 'Im hellohousing Mieterportal finden Sie Ihre aktuellen Verbräuche, alle Dokumente und tauschen Nachrichten aus. Hier anmelden.');
        document.title= 'hellohousing Mieterportal - Anmeldung';
    }

    componentDidMount() {
        window.history.pushState({}, undefined, "/login");
    }

    handlePasswordChange=(e)=>{
        this.setState({password: e.currentTarget.value});
    }

    handleUsernameChange=(e)=>{
        this.setState({email: e.currentTarget.value});
    }

    handleSubmit= (e)=>{
        this.props.set_loader(true);
        e.preventDefault();
        console.log('submitworking');

        SignInPost({
            email: this.state.email,
            password: this.state.password
        }, this.props.set_loader, res => {
            this.props.set_token(res.token);
            this.props.set_profile_data({id: res.id});
            this.props.set_loader(false);
            hideNotification('user-sigin');
            AccountGet(res.accountId, accountRes => {
                this.props.set_account_data(accountRes);
                if(accountRes &&  accountRes.accountId){
                    UserGet(this.props.profile.id, profileRes=> {
                        if(profileRes.isEmailVerified){
                            this.props.set_profile_data({...this.props.profile, ...profileRes});
                            let redirectUrl = localStorage.getItem('redirectUrl');
                            console.log('redirectUrl: ', redirectUrl);
                            window.history.pushState({}, undefined, redirectUrl.length > 0 ? redirectUrl : "/dashboard");
                        }else{
                            this.props.set_account_data({});
                            window.localStorage.setItem('signupEmail', profileRes.email);
                            window.location.href='/emailVerification';
                        }
                    });
                }
            });
        });

        
        // this.props.set_profile_data({
        //     email: this.state.email,
        //     password: this.state.password
        // })
        // window.history.pushState({}, undefined, "/dashboard");
    }

    render() {
        const {visible, password, email} = this.state;
        console.log('visible: ', visible);
        return (
        <>
            <Stack className="login-view" id="respond-dashboard-page" style={{justifyContent: 'center', alignItems: 'center'}}>
                <img src={'./hello-housing-logo.svg'} alt='hello-housing' style={{marginBottom: '30px'}}/>
                <Stack className='card' spacing={10}>
                    <Group><span className='card-title'>Willkommen!</span></Group>
                    <Group>
                        <form onSubmit={this.handleSubmit}
                        >
                            <Stack style={{width: '304px'}}>
                                <Group grow>
                                    <TextInput
                                        label="E-Mail Adresse"
                                        value={email}
                                        onChange={this.handleUsernameChange}
                                    />
                                </Group>
                                <Group grow>
                                    <PasswordInput
                                        label="Passwort"
                                        value={password}
                                        onChange={this.handlePasswordChange}
                                        visibilityToggleIcon={({ reveal, size }) =>
                                        reveal ? <img src={EyeIcon} size={size} /> : <img src={EyeIcon} size={size} />
                                        }
                                    />
                                </Group>
                                <Group style={{justifyContent: 'space-between'}}>
                                    <span className='forget-password' onClick={()=> {window.location.href='/forgotPassword'}}>Passwort vergessen?</span>
                                    <Button className='purple-btn btn-text' type="submit">Anmelden</Button>
                                </Group>
                            </Stack>
                        </form>
                    </Group>
                </Stack>
            </Stack>
        </>
        );
    }
}
const mapStateToProps = (state) => {
    return {
      profile: state.generalReducer.profileData,
      isMobile: state.generalReducer.isMobile,
      isTablet: state.generalReducer.isTablet
    };
};
const actions = {
    set_profile_data,
    set_account_data,
    set_token,
    set_loader
};

export default connect(mapStateToProps, actions)(Login);
