import { Group, Loader, Progress, Skeleton, Stack, Table, Tooltip } from '@mantine/core';
import ProfileImage from '../../../assests/svg/TestImage.svg';
import HighConsumption from '../../../assests/svg/HighConsumption.svg';
import LowConsumption from '../../../assests/svg/LowConsumption.svg';
import ConsumptionIcon from '../../../assests/svg/Consumption.svg';
import DocumentsIcon from '../../../assests/svg/DocumentsIcon.svg';
import { color } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { tenantConsumptionGet } from '../../../helpers/fetchApis/MeterConsumptions';
import { set_loader, set_skeleton_loader } from '../../../redux/action/General/generalActions';
import dayjs from 'dayjs';
import { useState } from 'react';
import EmptyState from './EmptyState';
import { DocumentsGet } from '../../../helpers/fetchApis/Documents';
import FolderIcon from '@mui/icons-material/Folder';
import DescriptionIcon from '@mui/icons-material/Description';
import { api_config } from '../../../helpers/apis';
import { getAuthToken } from '../../../helpers/Uitls';
import customLoader from '../HelperComponents/CustomLoader';

const DashboardDocumentsCard = (props) => {
    let navigate= useNavigate();
    const [documentsData, setDocumentsData] = useState([]);
    const [loader, setLoader] = useState(false);
    
    
    const getDocumentsByAccountId=()=> {
        const {account, profile} = props;
        setLoader(true);
        DocumentsGet(account.accountId, res=> {
            setDocumentsData(res ? res.filter((dt)=> dt.parentDirectoryId === null && dt.isDirectory === false) : []);
            setLoader(false);
        });
    }

    const downloadDocumentsById=(id, name)=>{
        setLoader(true);
        fetch(api_config.baseUrl+'storage-api/documents/' + id + '/download',
        {
            method: 'GET',
            headers: {
            'Authorization': getAuthToken()
            }
        })
        .then(response => {
            response.blob()
            .then(data => {
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            
            let url = window.URL.createObjectURL(data);
            a.href = url;
            a.download = name;
            a.click();
            window.URL.revokeObjectURL(url);
            setLoader(false);
            })
        })
    }

    useEffect(() => {
        getDocumentsByAccountId();
    }, []);
  return (
    <Stack className='dashboard-general-card' //style={{maxWidth: !props.allDashboardCardsAvailable && '360px'}} 
    spacing={'lg'}>
        {<>
            <Group position='apart'>
                <Group noWrap align='flex-end'>
                    <span className='dashboard-general-card-heading'>{props.heading}</span>
                </Group>
                <Group className='dashboard-general-card-all' onClick={()=> navigate('/documents')} align={'flex-end'}>
                    <span style={{cursor: 'pointer'}}>Alle</span>
                </Group>
            </Group>
            {loader ?
            <Stack style={{height: 'inherit', marginTop: '18px'}} spacing={40}>
                {[...Array(3)].map((e, i) => 
                    <Group noWrap position='apart'>
                        <Skeleton style={{width: '50%', height: '20px'}}/>
                        <Skeleton style={{width: '25%', height: '20px'}}/>
                    </Group>
                )}
            </Stack>
            :
            documentsData.length > 0 ?
            <Table verticalSpacing="sm" style={{tableLayout: 'fixed'}}>
                <tbody>
                    {documentsData.sort((a,b)=> dayjs(a.updatedOn).isBefore(dayjs(b.updatedOn)))
                    .map((document, index)=> 
                    index < 3 &&
                    <tr style={{cursor: 'pointer'}} onClick={()=> downloadDocumentsById(document.id, document.name)}>
                        <td width={'72%'} height={'55px'} className='dashboard-consumption-card-name remove-border'>
                            <Group id={`dashboard-consumption-card-${document.id}`} noWrap spacing={10}>
                                {document.isDirectory ?
                                <FolderIcon 
                                style={{
                                color: '#5655FE',
                                fontSize: '20px'
                                }}/>
                                :
                                <DescriptionIcon 
                                style={{
                                color: '#5655FE',
                                fontSize: '20px'
                                }}/>}
                                <span className='dashboard-consumption-card-name-columns' style={{maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{document.name}</span>
                            </Group>
                        </td>
                        <td width={'28%'} height={'55px'} className='dashboard-consumption-card-percentage remove-border'>
                            <Group position='right' width={'100%'}>
                                {dayjs(document.updatedOn).format('DD.MM.YYYY')}
                            </Group>
                        </td>
                    </tr>)}
                </tbody>
            </Table>
            :
            <Stack align={'center'} justify={'center'} style={{height: '100%'}} className='empty-state-container-dashboard'>
                <EmptyState
                icon={DocumentsIcon}
                title='Es sind noch keine Dokumente vorhanden.'
                subText='Dokumente, die Ihr Vermieter freigibt, werden Ihnen hier angezeigt.'
                subTextClassName='empty-state-subtext-dashboard'
                />
            </Stack>
            }
        </>}
    </Stack> 
  )
}
const mapStateToProps = (state) => {
    return {
      profile: state.generalReducer.profileData,
      property: state.generalReducer.propertyData,
      account: state.generalReducer.accountData,
      isMobile: state.generalReducer.isMobile,
      isTablet: state.generalReducer.isTablet,
      skeletonLoader: state.generalReducer.skeletonLoader
    };
  }
  
  
  const actions={
    set_loader,
    set_skeleton_loader
}
export default connect(mapStateToProps, actions)(DashboardDocumentsCard);