import { api_config } from "../apis";
import { showNotification } from "@mantine/notifications";
import { APIClientGET } from "./APIClient";
import { clearStorageRedirectLogin, getAuthToken } from "../Uitls";
import RedCross from '../../assests/svg/RedCross.svg';
import du from "../language/du";

export async function PropertyGet(id, res) {
      await APIClientGET({
        url: api_config.property_management.property.property_by_rental_unit_id + `?rentalUnitId=${id}`,
        headers: {
            'Content-Type': 'application/json',
            Authorization: getAuthToken(),
          }
      })
        .then(response => {
            console.log('response.result: AccountGet: ', response);
            if (response.status === 200 && response.data.status) {
                res(response.data.result);
            } else if (response.data.status === false && response.status !== 400 && response.status !== 404) {
                showNotification({
                    message: <div dangerouslySetInnerHTML={{__html: du.general.text_general_error}}/>,
                    id: 'general_error',
                    icon: <img src={RedCross} alt='red-tick'/>,
                });
                res(response.data.result);
            }
        })
        .catch(e => {
            if(e.response.status === 401) {
              clearStorageRedirectLogin();
            }
            console.log('response.result: AccountGet: ', e);
            if (e.response.data.status === false && e.response.status !== 400 && e.response.status !== 404){
              showNotification({
                  message: <div dangerouslySetInnerHTML={{__html: du.general.text_general_error}}/>,
                  id: 'general_error',
                  icon: <img src={RedCross} alt='red-tick'/>,
              });
          }
        });
}

export async function RentalUnitGet(id, res) {
    await APIClientGET({
      url: api_config.property_management.rental_unit.rental_units_get + `/${id}`,
      headers: {
          'Content-Type': 'application/json',
          Authorization: getAuthToken(),
        }
    })
      .then(response => {
          console.log('response.result: AccountGet: ', response);
          if (response.status === 200 && response.data.status) {
              res(response.data.result);
          } else {
              showNotification({
                message: <div dangerouslySetInnerHTML={{__html: du.general.text_general_error}}/>,
                id: 'general_error',
                icon: <img src={RedCross} alt='red-tick'/>,
              });
              res(response.data.result);
          }
      })
      .catch(e => {
          if(e.response.status === 401) {
            clearStorageRedirectLogin();
          }
          console.log('response.result: AccountGet: ', e);
          showNotification({
            message: <div dangerouslySetInnerHTML={{__html: du.general.text_general_error}}/>,
            id: 'general_error',
            icon: <img src={RedCross} alt='red-tick'/>,
          });
      });
}

export async function TenantGet(id, res) {
    await APIClientGET({
      url: api_config.property_management.tenant.tenants_get + `/${id}`,
      headers: {
          'Content-Type': 'application/json',
          Authorization: getAuthToken(),
        }
    })
      .then(response => {
          console.log('response.result: AccountGet: ', response);
          if (response.status === 200 && response.data.status) {
              res(response.data.result);
          } else {
              showNotification({
                message: <div dangerouslySetInnerHTML={{__html: du.general.text_general_error}}/>,
                id: 'general_error',
                icon: <img src={RedCross} alt='red-tick'/>,
              });
              res(response.data.result);
          }
      })
      .catch(e => {
          if(e.response.status === 401) {
            clearStorageRedirectLogin();
          }
          console.log('response.result: AccountGet: ', e);
          showNotification({
            message: <div dangerouslySetInnerHTML={{__html: du.general.text_general_error}}/>,
            id: 'general_error',
            icon: <img src={RedCross} alt='red-tick'/>,
          });
      });
}

export async function RentGet(id, res) {
    await APIClientGET({
      url: api_config.property_management.rent.rent_get + `?offset=0&fetch=100&tenantId=${id}`,
      headers: {
          'Content-Type': 'application/json',
          Authorization: getAuthToken(),
        }
    })
      .then(response => {
          console.log('response.result: RentGet: ', response);
          if (response.status === 200 && response.data.status) {
              res(response.data.result);
          } else {
              showNotification({
                message: <div dangerouslySetInnerHTML={{__html: du.general.text_general_error}}/>,
                id: 'general_error',
                icon: <img src={RedCross} alt='red-tick'/>,
              });
              res(response.data.result);
          }
      })
      .catch(e => {
          if(e.response.status === 401) {
            clearStorageRedirectLogin();
          }
          console.log('response.result: RentGet: ', e);
          showNotification({
            message: <div dangerouslySetInnerHTML={{__html: du.general.text_general_error}}/>,
            id: 'general_error',
            icon: <img src={RedCross} alt='red-tick'/>,
          });
      });
}

export async function CurrentBillingPeriodGet(id, res) {
    await APIClientGET({
      url: api_config.property_management.utilityBilling.current_billing_period_get + `?propertyId=${id}`,
      headers: {
          'Content-Type': 'application/json',
          Authorization: getAuthToken(),
        }
    })
      .then(response => {
          console.log('response.result: CurrentBillingPeriodGet: ', response);
          if (response.status === 200 && response.data.status) {
              res(response.data.result);
          } else {
              showNotification({
                message: <div dangerouslySetInnerHTML={{__html: du.general.text_general_error}}/>,
                id: 'general_error',
                icon: <img src={RedCross} alt='red-tick'/>,
              });
              res(response.data.result);
          }
      })
      .catch(e => {
          if(e.response.status === 401) {
            clearStorageRedirectLogin();
          }
          console.log('response.result: CurrentBillingPeriodGet: ', e);
          showNotification({
            message: <div dangerouslySetInnerHTML={{__html: du.general.text_general_error}}/>,
            id: 'general_error',
            icon: <img src={RedCross} alt='red-tick'/>,
          });
      });
}

export async function LastBillingPeriodGet(id, res) {
    await APIClientGET({
      url: api_config.property_management.utilityBilling.last_billing_period_get + `?propertyId=${id}`,
      headers: {
          'Content-Type': 'application/json',
          Authorization: getAuthToken(),
        }
    })
      .then(response => {
          console.log('response.result: LastBillingPeriodGet: ', response);
          if (response.status === 200 && response.data.status) {
              res(response.data.result);
          } else {
              showNotification({
                message: <div dangerouslySetInnerHTML={{__html: du.general.text_general_error}}/>,
                id: 'general_error',
                icon: <img src={RedCross} alt='red-tick'/>,
              });
              res(response.data.result);
          }
      })
      .catch(e => {
          if(e.response.status === 401) {
            clearStorageRedirectLogin();
          }
          console.log('response.result: LastBillingPeriodGet: ', e);
          showNotification({
            message: <div dangerouslySetInnerHTML={{__html: du.general.text_general_error}}/>,
            id: 'general_error',
            icon: <img src={RedCross} alt='red-tick'/>,
          });
      });
}

export async function NotesGet(id, res) {
  await APIClientGET({
    url: api_config.property_management.notes.notes_get + `?propertyId=${id}`,
    headers: {
        'Content-Type': 'application/json',
        Authorization: getAuthToken(),
      }
  })
    .then(response => {
        console.log('response.result: NotesGet: ', response);
        if (response.status === 200 && response.data.status) {
            res(response.data.result);
        } else if (response.data.status === false && response.status !== 400 && response.status !== 404) {
            showNotification({
                message: <div dangerouslySetInnerHTML={{__html: du.general.text_general_error}}/>,
                id: 'general_error',
                icon: <img src={RedCross} alt='red-tick'/>,
            });
            res(response.data.result);
        }
    })
    .catch(e => {
        if(e.response.status === 401) {
          clearStorageRedirectLogin();
        }
        console.log('response.result: NotesGet: ', e);
        if (e.response.data.status === false && e.response.status !== 400 && e.response.status !== 404){
          showNotification({
              message: <div dangerouslySetInnerHTML={{__html: du.general.text_general_error}}/>,
              id: 'general_error',
              icon: <img src={RedCross} alt='red-tick'/>,
          });
      }
    });
}