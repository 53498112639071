import { Group, Loader, Progress, Skeleton, Stack, Table, Tooltip } from '@mantine/core';
import ProfileImage from '../../../assests/svg/TestImage.svg';
import HighConsumption from '../../../assests/svg/HighConsumption.svg';
import LowConsumption from '../../../assests/svg/LowConsumption.svg';
import ConsumptionIcon from '../../../assests/svg/Consumption.svg';
import messagesIcon from '../../../assests/svg/MessagesIcon.svg';
import { color } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { tenantConsumptionGet } from '../../../helpers/fetchApis/MeterConsumptions';
import { set_loader, set_skeleton_loader } from '../../../redux/action/General/generalActions';
import dayjs from 'dayjs';
import { useState } from 'react';
import EmptyState from './EmptyState';
import FolderIcon from '@mui/icons-material/Folder';
import DescriptionIcon from '@mui/icons-material/Description';
import { api_config } from '../../../helpers/apis';
import { getAuthToken } from '../../../helpers/Uitls';
import customLoader from '../HelperComponents/CustomLoader';
import { MessagesGet } from '../../../helpers/fetchApis/Messages';

const DashboardMessagesCard = (props) => {
    let navigate= useNavigate();
    const [messagesData, setMessagesData] = useState([]);
    const [loader, setLoader] = useState(false);
    
    
    const getMessagesByPropertyId=()=> {
        const {account, profile, property} = props;
        setLoader(true);
        MessagesGet(property.id, res=> {
            if(Array.isArray(res)){
                setMessagesData(res);
            }else{
                if(res.status === 429){
                    setLoader(true);
                    setTimeout(() => {
                        getMessagesByPropertyId();
                    }, 3000);
                }
            }
            setLoader(false);
        });
    }

    useEffect(() => {
        getMessagesByPropertyId();
    }, []);
  return (
    <Stack className='dashboard-general-card' //style={{maxWidth: !props.allDashboardCardsAvailable && '360px'}} 
    spacing={'lg'}>
        {<>
            <Group position='apart'>
                <Group noWrap align='flex-end'>
                    <span className='dashboard-general-card-heading'>{props.heading}</span>
                </Group>
                <Group className='dashboard-general-card-all' onClick={()=> navigate('/messages')} align={'flex-end'}>
                    <span style={{cursor: 'pointer'}}>Alle</span>
                </Group>
            </Group>
            {loader ?
            <Stack style={{height: 'inherit', marginTop: '18px'}} spacing={40}>
                {[...Array(3)].map((e, i) => 
                    <Group noWrap position='apart'>
                        <Skeleton style={{width: '50%', height: '20px'}}/>
                        <Skeleton style={{width: '25%', height: '20px'}}/>
                    </Group>
                )}
            </Stack>
            :
            messagesData.length > 0 ?
            <Table verticalSpacing="sm" style={{tableLayout: 'fixed'}}>
                <tbody>
                    {messagesData.sort((a,b)=> dayjs(a.updatedOn).isBefore(dayjs(b.updatedOn)))
                    .map((message, index)=> 
                    index < 3 &&
                    <tr style={{cursor: 'pointer'}} onClick={()=> navigate('/messages/messageDetail', {state: {message: message}})}>
                        <td width={'72%'} height={'55px'} className='dashboard-consumption-card-name remove-border'>
                            <Group noWrap spacing={10}>
                                <span className='dashboard-message-card-subject' //dangerouslySetInnerHTML={{__html: message.subject}} 
                                style={{maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                                    {message.subject.replace(/<[^>]*>?/gm, '')}
                                </span>
                            </Group>
                        </td>
                        <td width={'28%'} height={'55px'} className='dashboard-consumption-card-percentage remove-border'>
                            <Group position='right' width={'100%'}>
                                {dayjs(message.updatedOn).format('DD.MM.YYYY')}
                            </Group>
                        </td>
                    </tr>)}
                </tbody>
            </Table>
            :
            <Stack align={'center'} justify={'center'} style={{height: '100%'}} className='empty-state-container-dashboard'>
                <EmptyState
                icon={messagesIcon}
                title='Es sind noch keine Nachrichten vorhanden.'
                subText='Nachrichten, die Ihr Vermieter versendet, werden Ihnen hier angezeigt.'
                subTextClassName='empty-state-subtext-dashboard'
                />
            </Stack>
            }
        </>}
    </Stack> 
  )
}
const mapStateToProps = (state) => {
    return {
      profile: state.generalReducer.profileData,
      property: state.generalReducer.propertyData,
      account: state.generalReducer.accountData,
      isMobile: state.generalReducer.isMobile,
      isTablet: state.generalReducer.isTablet,
      skeletonLoader: state.generalReducer.skeletonLoader
    };
  }
  
  
  const actions={
    set_loader,
    set_skeleton_loader
}
export default connect(mapStateToProps, actions)(DashboardMessagesCard);