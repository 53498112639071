import {store} from "../store";
import Redirection from "./Redirection";

/**
 * 
 * @param { string | number } number - number to convert
 * @param { boolean } displayWithCurrency - if true, will display with currency (for now Euro only)
 * @returns Number formatted as XX,XXX.XX (€)
 */
 export const formatNumber = (number, displayWithCurrency = false) => {
    const formattedNumber = number
      .toFixed(2)
      .toString()
      .replace('.', ',')
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  
    return `${formattedNumber}${displayWithCurrency ? ' €' : ''}`;
  }

export function check_auth(page = null) {

    const state = store.getState();

    const account = state.generalReducer.accountData;
    console.log('state: ', account);

    if(Object.keys(account).length === 0){
        console.log('working');
        localStorage.clear();
        window.location.href= '/login';
        //window.history.pushState({}, undefined, "/login");
    }
    // const profile = state.generalReducer.profile;

    // var auth = checkProperty(profile, "auth");
  
    // var token = checkProperty(auth, "token");
  
  
    // if (page.props.location.pathname == "/login") {
    //   if (auth == "" || token == "") {
  
    //     return true;
  
    //   } else {
    //     let lastRoute= localStorage.getItem('lastRoute');
    //     lastRoute ? window.location.href = lastRoute :
    //     Redirection.redirection_dashboard();
    //   }
  
    // }
  
    // if (auth == "") {
    //   localStorage.setItem('lastRoute', window.location.href.includes('localhost') ? window.location.href.split('http://localhost:3000')[1] : window.location.href.includes('dev.hellohousing.de') ? window.location.href.split('https://dev.hellohousing.de')[1] : window.location.href.split('https://app.hellohousing.de')[1]);
    //   Redirection.redirection_login();
    // }
  
    // if (token == "") {
    //   localStorage.setItem('lastRoute', window.location.href.includes('localhost') ? window.location.href.split('http://localhost:3000')[1] : window.location.href.includes('dev.hellohousing.de') ? window.location.href.split('https://dev.hellohousing.de')[1] : window.location.href.split('https://app.hellohousing.de')[1]);
    //   Redirection.redirection_login();
    // }
  
  
    return true;
  
  
}

export function clearStorageRedirectLogin(){
  localStorage.clear();
  setTimeout(() => {
    window.location.reload();  
  }, 1000);
}

export function getAuthToken() {
    // grab current state
    const state = store.getState();
    // get the token out of it
    const authToken = state.generalReducer.token;
    // return the token 
    return authToken
  }

export function checkProperty(obj, value, dValue = '') {
    if (obj == '') {
      return dValue;
    }
  
    if (typeof obj != 'object') {
      return dValue;
    }
  
    if (obj == null || value == null) {
      return dValue;
    }
    if (typeof obj[value] === 'undefined') {
      return dValue;
    }
    if (obj[value] === '' || obj[value] == null) {
      return dValue;
    }
    return obj[value];
  };