import { Drawer, Group, Stack, Text, Anchor, Center } from '@mantine/core';
import { useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate, matchPath } from "react-router-dom";
import Icon from '../Sidebar/Icon';

// Single menu item
export const MainMenuItemMobile = ({ icon, route, children, href, target, color, ...props }) => {
  const [ hovered, setHovered ] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleNavigate = (event) => {
    event.preventDefault();

    if (route) {
        navigate(route);
    }

    if (href) {
      window.open(href);
    }
  }

  const pathMatches = () => window.location.pathname === route;
  console.log('pathMatches: ', pathMatches(), pathname, route, window.location.pathname);

  return (
    <Stack 
      sx={{
        cursor: 'pointer',
        position: 'relative',
        alignItems: 'center'
      }}
      spacing={6}
      onClick={handleNavigate} 
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
      {...props}
    >
      {icon && <Icon name={icon} color={//pathMatches() || 
        hovered || pathMatches() ? (color ? color : 'rgba(86, 85, 255, 1.00)') : 'rgba(154, 154, 181, 1.00)'} />}
      <Text sx={{ 
        fontSize: '10px',
        fontWeight: '500',
        color: //pathMatches() || 
        hovered || pathMatches() ? (color ? color : 'rgba(86, 85, 255, 1.00)') : 'rgba(154, 154, 181, 1.00)' }}>{children}</Text>
    </Stack>
  )
}

// Main app menu
const MainMenuMobile = (props) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/login";
  }
  return (
      <Group spacing={6} grow>
        <MainMenuItemMobile icon="dashboard" isTablet={props.isTablet} route="/dashboard">Übersicht</MainMenuItemMobile>
        {!props.property?.isConsumptionSectionHidden && <MainMenuItemMobile icon="consumption" isTablet={props.isTablet} route="/consumption">Verbräuche</MainMenuItemMobile>}
        {!props.property?.isDocumentSectionHidden && <MainMenuItemMobile icon="document" isTablet={props.isTablet} route="/documents">Dokumente</MainMenuItemMobile>}
        {!props.property?.isMessageSectionHidden && <MainMenuItemMobile icon="messenger" isTablet={props.isTablet} route="/messages">Nachrichten</MainMenuItemMobile>}
        <MainMenuItemMobile icon="user" isTablet={props.isTablet} route="/profile">Profil</MainMenuItemMobile>
        <Group onClick={handleLogout}><MainMenuItemMobile icon="logout" color="red">Abmelden</MainMenuItemMobile></Group>
      </Group>
  )
};

const mapStateToProps = (state) => {
  return {
    profile: state.generalReducer.profileData,
    isMobile: state.generalReducer.isMobile,
    isTablet: state.generalReducer.isTablet,
    property: state.generalReducer.propertyData,
  };
};

export default connect(mapStateToProps)(MainMenuMobile);