import React from 'react';
import { Stack, Modal } from '@mantine/core';


const TileDetailsModal= (props) => {
    
    return (
    <Modal
    opened={props.open}
    centered
    overlayOpacity={0.8}
    onClose={() => props.toggle()}
    styles={{
        modal: {
            width: '880px'
        }
    }}
    title={'Aushang'}>
        <Stack style={{width: '100%', minHeight: '300px', maxHeight: '100vh'}}>
            {props.tile.title && <span className='tile-preview-title'>{props.tile.title}</span>}
            <div className='tile-detail-preview-body' style={{overflowY: 'scroll'}} dangerouslySetInnerHTML={{__html: props.tile.body}}/>
        </Stack>
    </Modal>
    )
}

export default TileDetailsModal;
