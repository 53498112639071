import {Anchor, Center, Group, Menu, Stack, UnstyledButton} from "@mantine/core";
import { Link } from "react-router-dom";
import { useState } from "react";
import { connect } from "react-redux";
import MainMenuMobile from "./MainMenuMobile";

const AppBottomNavBar = (props) => {
  const [hover, setHover] = useState(false);
  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/login";
  }
  
  return (
    <Group style={{position: 'fixed', bottom: 0, backgroundColor: 'white', height: '62px', width: '100%', boxShadow: '0 1px 3px rgba(0,0,0,.3)'}} grow>
        <MainMenuMobile/>
    </Group>
  )
}

const mapStateToProps = (state) => {
  return {
    profile: state.generalReducer.profileData,
    isMobile: state.generalReducer.isMobile,
    isTablet: state.generalReducer.isTablet,
  };
};

export default connect(mapStateToProps)(AppBottomNavBar);