import { Group, Stack } from '@mantine/core';
import dayjs from 'dayjs';
import { Component } from 'react';
import DateIcon from '../../../assests/svg/DateIcon.svg';
import RentalUnit from '../../../assests/svg/RentalUnit.svg';
import RentalUnitIcon from '../../../assests/svg/RentalUnitIcon.svg';
import TenantIcon from '../../../assests/svg/TenantIcon.svg';
import RentIcon from '../../../assests/svg/RentIcon.svg';
import TenantIconGrey from '../../../assests/svg/TenantIconGrey.svg';
import { formatNumber } from '../../../helpers/Uitls';

class DashboardPropertyCard extends Component {

  constructor(props) {
    super(props)
  }

  componentDidMount() {

  }

  

  render(){
    const {isMobile, isTablet, tenant, rentalUnit, property, rent} = this.props;
    console.log('tenant: ', tenant, rentalUnit, rent);
    return (
        <Group className='dashboard-property-card' //grow={(isMobile === false && isTablet === false) && true} spacing={(isMobile || isTablet) && 35}
        grow={!isMobile}>
            {isMobile ? 
            <Stack style={{width: '100%'}}>
                <Group style={{justifyContent: 'space-between', width: '100%'}} noWrap>
                    <div style={{width: '100%'}}>
                        <Stack spacing={5}>
                            <span className='dashboard-property-card-label'>Mieter</span>
                            <Group spacing={10} noWrap>
                                <img src={TenantIconGrey}/>
                                <span className={`dashboard-property-card-value ${(isTablet || isMobile) && 'dashboard-property-card-value-tablet'}`}>{tenant && tenant.fullName}</span>
                            </Group>
                        </Stack>
                    </div>
                    <div style={{width: '100%'}}>
                        <Stack spacing={5}>
                            <span className='dashboard-property-card-label'>Wohnung</span>
                            <Group spacing={10} noWrap>
                                <img src={RentalUnitIcon}/>
                                <span className={`dashboard-property-card-value ${(isTablet || isMobile) && 'dashboard-property-card-value-tablet'}`}>{rentalUnit && rentalUnit.name}</span>
                            </Group>
                        </Stack>
                    </div>
                </Group>
                <Group style={{justifyContent: 'space-between', width: '100%'}} noWrap>
                    <div style={{width: '100%'}}>
                        <Stack spacing={5}>
                            <span className='dashboard-property-card-label'>Einzug</span>
                            <Group spacing={10} noWrap>
                                <img src={DateIcon}/>
                                <span className={`dashboard-property-card-value ${(isTablet || isMobile) && 'dashboard-property-card-value-tablet'}`}>{tenant && tenant.moveInDate ? dayjs(tenant.moveInDate).format('DD.MM.YYYY') : '-'}</span>
                            </Group>
                        </Stack>
                    </div>
                    <div style={{width: '100%'}}>
                        <Stack spacing={5}>
                            <span className='dashboard-property-card-label'>Auszug</span>
                            <Group spacing={10} noWrap>
                                <img src={DateIcon}/>
                                <span className={`dashboard-property-card-value ${(isTablet || isMobile) && 'dashboard-property-card-value-tablet'}`}>{tenant && tenant.moveOutDate ? dayjs(tenant.moveOutDate).format('DD.MM.YYYY') : '-'}</span>
                            </Group>
                        </Stack>
                    </div>
                </Group>
                <Group style={{justifyContent: 'space-between', width: '100%'}} noWrap>
                    <div style={{width: '100%'}}>
                        <Stack spacing={5}>
                            <span className='dashboard-property-card-label'>Mietfläche</span>
                            <Group spacing={10} noWrap>
                                <img src={RentalUnit}/>
                                <span className={`dashboard-property-card-value ${(isTablet || isMobile) && 'dashboard-property-card-value-tablet'}`}>{`${rentalUnit && formatNumber(rentalUnit.space, false)} m²`}</span>
                            </Group>
                        </Stack>
                    </div>
                    <div style={{width: '100%'}}>
                        <Stack spacing={5}>
                            <span className='dashboard-property-card-label'>Kaltmiete (mtl.)</span>
                            <Group spacing={10} noWrap>
                                <img src={RentIcon}/>
                                <span className={`dashboard-property-card-value ${(isTablet || isMobile) && 'dashboard-property-card-value-tablet'}`}>{`${rent && rent.length > 0 && rent[0].netRent && formatNumber(rent[0].netRent, true)}`}</span>
                            </Group>
                        </Stack>
                    </div>
                </Group>
                <Group style={{justifyContent: 'space-between', width: '100%'}} noWrap>
                    <div style={{width: '100%'}}>
                        <Stack spacing={5}>
                            <span className='dashboard-property-card-label'>Nebenkosten (mtl.)</span>
                            <Group spacing={10} noWrap>
                                <img src={RentIcon}/>
                                <span className={`dashboard-property-card-value ${(isTablet || isMobile) && 'dashboard-property-card-value-tablet'}`}>{rent && rent.length > 0 && rent[0].prePayments !== 0 ? formatNumber(rent[0].prePayments, true) : '0,00 €'}</span>
                            </Group>
                        </Stack>
                    </div>
                    <div style={{width: '100%'}}>
                        <Stack spacing={5}>
                            <span className='dashboard-property-card-label'>Warmmiete (mtl.)</span>
                            <Group spacing={10} noWrap>
                                <img src={RentIcon}/>
                                <span className={`dashboard-property-card-value ${(isTablet || isMobile) && 'dashboard-property-card-value-tablet'}`}>{`${rent && rent.length > 0 && formatNumber((rent[0].netRent !== 0 ? rent[0].netRent : 0) + (rent[0].prePayments !== 0 ? rent[0].prePayments : 0), true)}`}</span>
                            </Group>
                        </Stack>
                    </div>
                </Group>
            </Stack>
            :
            <Stack spacing={25}>
                <Group grow>
                    <Stack spacing={5}>
                        <span className='dashboard-property-card-label'>Mieter</span>
                        <Group spacing={10} noWrap>
                            <img src={TenantIconGrey}/>
                            <span className={`dashboard-property-card-value ${(isTablet || isMobile) && 'dashboard-property-card-value-tablet'}`}>{tenant && tenant.fullName}</span>
                        </Group>
                    </Stack>
                    <Stack spacing={5}>
                        <span className='dashboard-property-card-label'>Wohnung</span>
                        <Group spacing={10} noWrap>
                            <img src={RentalUnitIcon}/>
                            <span className={`dashboard-property-card-value ${(isTablet || isMobile) && 'dashboard-property-card-value-tablet'}`}>{rentalUnit && rentalUnit.name}</span>
                        </Group>
                    </Stack>
                    <Stack spacing={5}>
                        <span className='dashboard-property-card-label'>Einzug</span>
                        <Group spacing={10} noWrap>
                            <img src={DateIcon}/>
                            <span className={`dashboard-property-card-value ${(isTablet || isMobile) && 'dashboard-property-card-value-tablet'}`}>{tenant && tenant.moveInDate ? dayjs(tenant.moveInDate).format('DD.MM.YYYY') : '-'}</span>
                        </Group>
                    </Stack>
                    <Stack spacing={5}>
                        <span className='dashboard-property-card-label'>Auszug</span>
                        <Group spacing={10} noWrap>
                            <img src={DateIcon}/>
                            <span className={`dashboard-property-card-value ${(isTablet || isMobile) && 'dashboard-property-card-value-tablet'}`}>{tenant && tenant.moveOutDate ? dayjs(tenant.moveOutDate).format('DD.MM.YYYY') : '-'}</span>
                        </Group>
                    </Stack>
                </Group>
                <Group grow>
                    <Stack spacing={5}>
                        <span className='dashboard-property-card-label'>Mietfläche</span>
                        <Group spacing={10} noWrap>
                            <img src={RentalUnit} width={18}/>
                            <span className={`dashboard-property-card-value ${(isTablet || isMobile) && 'dashboard-property-card-value-tablet'}`}>{`${rentalUnit && formatNumber(rentalUnit.space, false)} m²`}</span>
                        </Group>
                    </Stack>
                    <Stack spacing={5}>
                        <span className='dashboard-property-card-label'>Kaltmiete (mtl.)</span>
                        <Group spacing={10} noWrap>
                            <img src={RentIcon}/>
                            <span className={`dashboard-property-card-value ${(isTablet || isMobile) && 'dashboard-property-card-value-tablet'}`}>{`${rent && rent.length > 0 && rent[0].netRent !== 0 ? formatNumber(rent[0].netRent, true) : '0,00 €'}`}</span>
                        </Group>
                    </Stack>
                    <Stack spacing={5}>
                        <span className='dashboard-property-card-label'>Nebenkosten (mtl.)</span>
                        <Group spacing={10} noWrap>
                            <img src={RentIcon}/>
                            <span className={`dashboard-property-card-value ${(isTablet || isMobile) && 'dashboard-property-card-value-tablet'}`}>{rent && rent.length > 0 && rent[0].prePayments !== 0 ? formatNumber(rent[0].prePayments, true) : '0,00 €'}</span>
                        </Group>
                    </Stack>
                    <Stack spacing={5}>
                        <span className='dashboard-property-card-label'>Warmmiete (mtl.)</span>
                        <Group spacing={10} noWrap>
                            <img src={RentIcon}/>
                            <span className={`dashboard-property-card-value ${(isTablet || isMobile) && 'dashboard-property-card-value-tablet'}`}>{`${rent && rent.length > 0 && formatNumber((rent[0].netRent !== 0 ? rent[0].netRent : 0) + (rent[0].prePayments !== 0 ? rent[0].prePayments : 0), true)}`}</span>
                        </Group>
                    </Stack>
                </Group>
            </Stack>}
        </Group>
      );
  }
}

export default DashboardPropertyCard;