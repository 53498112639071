import { api_config } from "../apis";
import { showNotification } from "@mantine/notifications";
import { APIClientGET } from "./APIClient";
import { clearStorageRedirectLogin, getAuthToken } from "../Uitls";
import RedCross from '../../assests/svg/RedCross.svg';
import du from "../language/du";

export async function tenantConsumptionSeriesGet(consumptionType, selectedDate, selectedTenant=null, set_loader, res) {
      await APIClientGET({
        url: api_config.meter_management.tenant_portal.tenant_consumption_series + `?tenantId=${selectedTenant}&endDate=${selectedDate}&interval=Month&consumptionType=${consumptionType}`,
        headers: {
            'Content-Type': 'application/json',
            Authorization: getAuthToken(),
          }
      })
        .then(response => {
            console.log('response.result: tenantConsumptionSeriesGet: ', response);
            if (response.status === 200 && response.data.status) {
                res(response.data.result);
            } else {
                showNotification({
                    message: <div dangerouslySetInnerHTML={{__html: du.general.text_general_error}}/>,
id: 'general_error',
                    icon: <img src={RedCross} alt='red-tick'/>,
                });
                res(response.data.result);
                set_loader(false);
            }
        })
        .catch(e => {
            if(e.response.status === 401) {
              clearStorageRedirectLogin();
            }
            console.log('response.result: tenantConsumptionSeriesGet: ', e);
            showNotification({
                message: <div dangerouslySetInnerHTML={{__html: du.general.text_general_error}}/>,
id: 'general_error',
                icon: <img src={RedCross} alt='red-tick'/>,
            });
            set_loader(false);
        });
}  

export async function tenantConsumptionGet(consumptionType, startDate, endDate, selectedTenant=null, set_loader, res) {
    await APIClientGET({
      url: api_config.meter_management.tenant_portal.tenant_consumption + `?tenantId=${selectedTenant}&startDate=${startDate}&endDate=${endDate}&consumptionType=${consumptionType}`,
      headers: {
          'Content-Type': 'application/json',
          Authorization: getAuthToken(),
        }
    })
      .then(response => {
          console.log('response.result: tenantConsumptionGet: ', response);
          if (response.status === 200 && response.data.status) {
              res(response.data.result);
          } else {
              showNotification({
                message: <div dangerouslySetInnerHTML={{__html: du.general.text_general_error}}/>,
                id: 'general_error',
                icon: <img src={RedCross} alt='red-tick'/>,
              });
              res(response.data.result);
              set_loader(false);
          }
      })
      .catch(e => {
          if(e.response.status === 401) {
            clearStorageRedirectLogin();
          }
          console.log('response.result: tenantConsumptionGet: ', e);
          showNotification({
            message: <div dangerouslySetInnerHTML={{__html: du.general.text_general_error}}/>,
            id: 'general_error',
            icon: <img src={RedCross} alt='red-tick'/>,
          });
          set_loader(false);
      });
}

export async function EnergyEfficiencyLevelsGet(endDate, selectedTenant=null, set_loader, res) {
    await APIClientGET({
      url: api_config.meter_management.tenant_portal.energy_efficiency_levels + `?tenantId=${selectedTenant}&endDate=${endDate}`,
      headers: {
          'Content-Type': 'application/json',
          Authorization: getAuthToken(),
        }
    })
      .then(response => {
          console.log('response.result: tenantConsumptionGet: ', response);
          if (response.status === 200 && response.data.status) {
              res(response.data.result);
          } else {
              showNotification({
                message: <div dangerouslySetInnerHTML={{__html: du.general.text_general_error}}/>,
                id: 'general_error',
                icon: <img src={RedCross} alt='red-tick'/>,
              });
              res(response.data.result);
              set_loader(false);
          }
      })
      .catch(e => {
          if(e.response.status === 401) {
            clearStorageRedirectLogin();
          }
          console.log('response.result: tenantConsumptionGet: ', e);
          showNotification({
            message: <div dangerouslySetInnerHTML={{__html: du.general.text_general_error}}/>,
            id: 'general_error',
            icon: <img src={RedCross} alt='red-tick'/>,
          });
          set_loader(false);
      });
}