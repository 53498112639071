import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import { Group, TextInput, Button, Stack, PasswordInput, Popover, Progress, Image, Text, Box } from '@mantine/core';
import {set_profile_data, set_loader} from '../../../redux/action/General/generalActions';
import Logo from '../../../assests/svg/Logo.svg';
import EyeIcon from '../../../assests/svg/EyeIcon.svg';
import { PasswordInstructionsPost, TenantActivationStatusPost, TenantResetPasswordPost } from '../../../helpers/fetchApis/Auth';
import { showNotification } from '@mantine/notifications';
import RedCross from '../../../assests/svg/RedCross.svg';

// Password requirement markup
const PasswordRequirement = ({ checks, label }) => {
    return (
      <Text
        color="white"
        sx={{ display: 'flex', alignItems: 'center', opacity: checks ? 1 : 0.5 }}
        mt={7}
        size="sm"
      >
        <Image width={15} src={`${process.env.PUBLIC_URL}/icons/icon-check-badge.svg`} alt="Check icon" />
        <Box ml={10}>{label}</Box>
      </Text>
    )
};

// Requirements for the password validation
const passwordRequirements = [
    { regex: /[0-9]/, label: 'Eine Nummer haben' },                                                 // one digit
    { regex: /[a-z]/, label: 'Mindestens einen Kleinbuchstaben haben' },                            // lowercase letter
    { regex: /[A-Z]/, label: 'Mindestens einen Großbuchstaben haben' },                             // uppercase letter
    { regex: /[$&+,:;=?@#|'<>.^*()%!-]/, label: 'Ein Sonderzeichen enthalten (z. B. %, &, #)' }     // special chars
  ];
  
// Show password strength
const getStrength = (password) => {
    let multiplier = password.length > 5 ? 0 : 1;

    passwordRequirements.forEach((requirement) => {
    if (!requirement.regex.test(password)) {
        multiplier += 1;
    }
    });

    return Math.max(100 - (100 / (passwordRequirements.length + 1)) * multiplier, 10);
}


class ForgotPassword extends Component {

    state={
        visible: false,
        email: '',
        success: false
    }
    constructor(props) {
        super(props)
    }

    componentDidMount() {
    }

    handleSubmit=(e)=>{
        e.preventDefault();
        this.props.set_loader(true);
        const {email} = this.state;

        if(email){
            PasswordInstructionsPost({email: email}, this.props.set_loader, res=> {
                // window.localStorage.setItem('resetEmail', res.email);
                this.props.set_loader(false);
                this.setState({success: true});
            })
        }else{
            showNotification({
                message: 'Etwas ist schief gelaufen. Bitte kontaktieren Sie den Support.',
                icon: <img src={RedCross} alt='red-tick'/>,
            });
            this.props.set_loader(false);
        }
    }

    handleEmail=(e)=>{
        this.setState({email: e.target.value});
    }

    render() {
        const { email, success} = this.state;
        return (
        <>
            <Stack className="login-view" id="respond-dashboard-page" style={{justifyContent: 'center', alignItems: 'center'}}>
                <img src={'./hello-housing-logo.svg'} alt='hello-housing' style={{marginBottom: '30px'}}/>
                {success ? 
                <Stack className='card' spacing={15} style={{width: '340px'}}>
                    <Group><span className='card-title'>Wir haben Ihnen soeben eine Anleitung zum Zurücksetzen des Passworts an Ihre E-Mail Adresse gesendet!</span></Group>
                    <Group><span className='card-sub-text'>Prüfen Sie jetzt Ihren E-Mail Posteingang und folgen Sie der Anleitung in der E-Mail.</span></Group>
                </Stack>
                :
                <Stack className='card' spacing={15} style={{width: '340px'}}>
                    <Group><span className='card-title'>Passwort vergessen?</span></Group>
                    <Group><span className='card-sub-text'>Bitte geben Sie hier Ihre E-Mail Adresse ein, um Ihr Passwort zurückzusetzen.</span></Group>
                    <Group grow>
                        <TextInput
                            label="E-Mail Adresse"
                            value={email}
                            onChange={this.handleEmail}
                        />
                    </Group>
                    <Group position='apart'>
                        <Button className='gray-btn btn-app-text' onClick={()=> window.location.href='/login'}>Zurück</Button>
                        <Button className='purple-btn btn-app-text' onClick={this.handleSubmit}>Senden</Button>
                    </Group>
                </Stack>}
            </Stack>
        </>
        );
    }
}

const actions = {
    set_profile_data,
    set_loader
};

export default connect(null, actions)(ForgotPassword);
