import { Component } from 'react';
import { Group, Stack, Tabs } from '@mantine/core';
import ConsumptionIcon from '../../../assests/svg/Consumption.svg';
import ProfileImage from '../../../assests/svg/TestImage.svg';
import Chart from 'chart.js/auto'
import dayjs from 'dayjs';
import 'chartjs-plugin-style';
import { render } from '@testing-library/react';


let width, height, gradient;
function getGradient(ctx, chartArea) {
  const chartWidth = chartArea.right - chartArea.left;
  const chartHeight = chartArea.bottom - chartArea.top;
  if (!gradient || width !== chartWidth || height !== chartHeight) {
    // Create the gradient because this is either the first render
    // or the size of the chart has changed
    width = chartWidth;
    height = chartHeight;
    gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
    gradient.addColorStop(0, '#E3E3FC');
    gradient.addColorStop(0.5, '#5555FF');
    gradient.addColorStop(1, '#422F8A');
  }

  return gradient;
}

Array.prototype.max = function() {
  return Math.max.apply(null, this);
};

Array.prototype.min = function() {
  return Math.min.apply(null, this);
};

class ConsumptionChartCardClassComponent extends Component {
    state={
      dataChart: [
        {
          datetime: "2023-01-01T20:22:28.810Z",
          currentConsumption: 0,
          lastConsumption: 3
        },
        {
          datetime: "2023-02-01T20:22:28.810Z",
          currentConsumption: 10,
          lastConsumption: 10
        },
        {
          datetime: "2023-03-01T20:22:28.810Z",
          currentConsumption: 8,
          lastConsumption: 12
        },
        {
          datetime: "2023-04-01T20:22:28.810Z",
          currentConsumption: 12,
          lastConsumption: 8
        },
        {
          datetime: "2023-05-01T20:22:28.810Z",
          currentConsumption: 16,
          lastConsumption: 7
        },
        {
          datetime: "2023-06-01T20:22:28.810Z",
          currentConsumption: 14,
          lastConsumption: 10
        },
        {
          datetime: "2023-07-01T20:22:28.810Z",
          currentConsumption: 12,
          lastConsumption: 14
        },
        {
          datetime: "2023-08-01T20:22:28.810Z",
          currentConsumption: 10,
          lastConsumption: 15
        },
        {
          datetime: "2023-09-01T20:22:28.810Z",
          currentConsumption: 15,
          lastConsumption: 12
        },
        {
          datetime: "2023-10-01T20:22:28.810Z",
          currentConsumption: 18,
          lastConsumption: 10
        },
        {
          datetime: "2023-11-01T20:22:28.810Z",
          currentConsumption: 17,
          lastConsumption: 8
        },
        {
          datetime: "2023-12-01T20:22:28.810Z",
          currentConsumption: 16,
          lastConsumption: 2
        },
      ],
      monthsToShow: 12,
      chartHover: false,
      activeTab: 'first'
    }
    constructor(props){
      super(props);
    }

    componentDidMount(){
      var duration = require('dayjs/plugin/duration')
      var localeData = require('dayjs/plugin/localeData')
      var locale = require('dayjs/locale/de');
      dayjs.locale('de')
      dayjs.extend(localeData)
      dayjs.extend(duration);
      dayjs.duration({ months: 12 });

      this.NewChart();
    }

    onChartHoverInOut = ()=>{
        this.setState({chartHover: !this.state.chartHover});
    }

    getOrCreateTooltip = (chart) => {
        let tooltipEl = chart.canvas.parentNode.querySelector('div');
      
        if (!tooltipEl) {
          tooltipEl = document.createElement('div');
          tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
          tooltipEl.style.borderRadius = '3px';
          tooltipEl.style.color = 'white';
          tooltipEl.style.opacity = 1;
          tooltipEl.style.pointerEvents = 'none';
          tooltipEl.style.position = 'absolute';
          tooltipEl.style.transform = 'translate(-50%, 0)';
          tooltipEl.style.transition = 'all .1s ease';
      
          const table = document.createElement('table');
          table.style.margin = '0px';
      
          tooltipEl.appendChild(table);
          chart.canvas.parentNode.appendChild(tooltipEl);
        }
      
        return tooltipEl;
    };
      
    externalTooltipHandler = (context) => {
        // Tooltip Element
        const {chart, tooltip} = context;
        const tooltipEl = this.getOrCreateTooltip(chart);

        tooltipEl.style.marginTop = '10px'
        tooltipEl.style.backgroundColor = 'white';
        tooltipEl.style.color = '#11263C'
        tooltipEl.style.boxShadow = '0 3px 3px rgba(0,0,0,.2)'
        
        // Hide if no tooltip
        if (tooltip.opacity === 0) {
          tooltipEl.style.opacity = 0;
          return;
        }
      
        // Set Text
        if (tooltip.body) {
          const titleLines = tooltip.title || [];
          const bodyLines = tooltip.body.map(b => b.lines);

          console.log('bodyLines: ', bodyLines);
      
          const tableHead = document.createElement('thead');
      
        //   titleLines.forEach(title => {
        //     const tr = document.createElement('tr');
        //     tr.style.borderWidth = 0;
      
        //     const th = document.createElement('th');
        //     th.style.borderWidth = 0;
        //     const text = document.createTextNode(title);
      
        //     th.appendChild(text);
        //     tr.appendChild(th);
        //     tableHead.appendChild(tr);
        //   });
      
          const tableBody = document.createElement('tbody');
        //   bodyLines.forEach((body, i) => {
            //const colors = tooltip.labelColors[i];

            //console.log('body: ', body);
      
            // const span = document.createElement('span');
            // span.style.background = colors.backgroundColor;
            // span.style.borderColor = colors.borderColor;
            
            // span.style.height = '10px';
            // span.style.width = '10px';
            // span.style.display = 'inline-block';
      
            const tr = document.createElement('tr');
            tr.style.backgroundColor = 'inherit';
            tr.style.borderWidth = 0;
      
            const td = document.createElement('td');
            td.style.borderWidth = 0;

            const div = document.createElement('div');
            div.style.display= 'flex';
            div.style.flexDirection= 'column';
            div.style.width='130px';
      
            const text = document.createTextNode(bodyLines.toString().includes('Aktuell') && bodyLines.toString().includes('Vorjahr') ? 'Aktuell - Vorjahr' : bodyLines.toString().includes('Aktuell') ? 'Aktuell' : bodyLines.toString().includes('Vorjahr') && 'Vorjahr' );
            const span1 = document.createElement('span');
            span1.style.color= '#5041BC';
            span1.style.fontSize= '9px';
            span1.append(text);

            const span2 = document.createElement('span');
            span2.style.color= '#11263C';
            span2.style.fontSize= '16px';
            span2.style.fontWeight= 'bold';
            span2.append((bodyLines.toString().includes('Aktuell') && bodyLines.toString().includes('Vorjahr') ? bodyLines.toString().split('Aktuell:')[1].split(',Vorjahr:')[0] + 'kWh - ' + bodyLines.toString().split('Aktuell:')[1].split(',Vorjahr:')[1] : bodyLines.toString().includes('Aktuell') ? bodyLines.toString().split('Aktuell:')[1] : bodyLines.toString().split('Vorjahr:')[1]) + 'kWh');
            //span.appendChild(body.toString().includes('Aktuell') ? 'Aktuell' : 'Vorjahr');
            //td.appendChild(span);
            const span3 = document.createElement('span');
            span3.style.color= 'rgb(60, 60, 67, 0.6)';
            span3.style.fontSize= '10px';
            span3.append(tooltip.title +' '+ dayjs().format('YY') +' - '+ tooltip.title +' '+ dayjs().subtract(1, 'year').format('YY'));

            div.append(span1);
            div.append(span2);
            div.append(span3);
            td.appendChild(div);
            tr.appendChild(td);
            tableBody.appendChild(tr);
        
      
          const tableRoot = tooltipEl.querySelector('table');
      
          // Remove old children
          while (tableRoot.firstChild) {
            tableRoot.firstChild.remove();
          }
      
          // Add new children
          tableRoot.appendChild(tableHead);
          tableRoot.appendChild(tableBody);
        }
      
        const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;
      
        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1;
        tooltipEl.style.borderRadius='10px';
        tooltipEl.style.left = positionX + tooltip.caretX + 'px';
        tooltipEl.style.top = positionY + tooltip.caretY + 'px';
        tooltipEl.style.font = tooltip.options.bodyFont.string;
        tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
        tooltipEl.style.marginTop = '-120px';
        tooltipEl.style.transition = 'all 0.5s ease 0s';
    };  
    NewChart = async ()=> {
      const {monthsToShow, dataChart} = this.state;  
      const data = dataChart.map((dt)=> dt.currentConsumption)
      const oldData = dataChart.map((dt)=> dt.lastConsumption) 
      const months = dataChart.map((dt)=> dayjs(dt.datetime).format('MMM YY'))
      months.map((month)=> month.includes('.') === false && month.substring )
      new Chart(
        document.getElementById('acquisitions'),
        {
          type: 'line',
          data: {
            labels: months,//dayjs.monthsShort(), //dayjs.monthsShort()
            datasets: [
              {
                  label: 'Aktuell',
                  pointHoverRadius: 6,
                  pointHoverBackgroundColor: 'rgb(66, 47, 138, 0.87)',
                  pointHoverBorderColor: '#ffffff',
                  pointHoverBorderWidth: 2,
                  pointBackgroundColor: 'transparent',
                  pointBorderColor: 'transparent',
                  data: data,
                  fill: true,
                  backgroundColor: function(context) {
                      const chart = context.chart;
                      const {ctx, chartArea} = chart;
                      var gradient = ctx.createLinearGradient(0, 0, 0, 600);
                      
                      gradient.addColorStop(0, 'rgb(116, 89, 217, 0.1)');   
                      gradient.addColorStop(1, 'rgb(255, 255, 255, 0)');
                      

                      return gradient;
                  },
                  borderColor: function(context) {
                      const chart = context.chart;
                      const {ctx, chartArea} = chart;
              
                      if (!chartArea) {
                      // This case happens on initial chart load
                      return;
                      }
                      return getGradient(ctx, chartArea);
                  },
                  tension: 0.4,
                  //pointStyle: false,
                  //labels: [data.min(),10,15,data.max()]
              },
              {
                  label: 'Vorjahr',
                  pointHoverRadius: 6,
                  pointHoverBackgroundColor: '#E3DBF1',
                  pointHoverBorderColor: '#ffffff',
                  pointHoverBorderWidth: 2,
                  pointBackgroundColor: 'transparent',
                  pointBorderColor: 'transparent',
                  data: oldData,
                  fill: false,
                  borderColor: '#E3DBF1',
                  tension: 0.4,
              }
            ]
          },
          options: {
              animation: {
                  duration: 100
              },
              responsive: true,
              interaction: {
                  intersect: false,
                  mode: 'index',
              },
              plugins: {
                  legend: {
                      position: 'top',
                      align: 'end'
                  },
                  
                  events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
                  tooltip: {
                      enabled: false,
                      position: 'nearest',
                      external: this.externalTooltipHandler,
                      //intersect: false
                  },
                  legend: {
                      align: 'end',
                      labels:{
                          boxHeight: 1,
                          //color: '#131358', '#A9A9A9']
                      }
                  }
              },
              scales: {
                  x: {
                      min: 12 - monthsToShow,
                      max: 12,
                      grid: {
                          color: "rgba(0, 0, 0, 0)",
                      },
                      border: {
                          display: false
                      },
                      ticks: {
                          padding: 15,
                          color: '#727293'
                      },
                  },
                  y: {
                      min: data.min() < oldData.min() ? data.min() : oldData.min(),
                      max: data.max() > oldData.max() ? Math.ceil(data.max() / 10) * 10 : Math.ceil(oldData.max() / 10) * 10,
                      ticks: {
                          maxTicksLimit: 5,
                          padding: 15,
                          color: '#727293'
                      },
                      title: {
                          text: 'kWh',
                          display: true,
                          color: '#A9A9A9'
                      },
                      border: {
                          display: false
                      },
                      
                  },
                  yAxes: [{
                      
                  }]
              },
        }
      })
    };

    setMonths= (activeTab)=>{
      switch(activeTab){
        case 'first':
          return 3;
        case 'second':
          return 6;
        case 'third':
          return 9;
        case 'fourth':
          return 12;
        default:
          return 12;
      }
    }
    setActiveTab=(value)=>{
      this.setState({activeTab: value, monthsToShow: this.setMonths(value)});
    }
  render(){
    const {activeTab} = this.state;
    return (
    <Stack className='consumption-chart-card' spacing={'xs'}>
        <Group className='consumption-chart-card-heading' style={{width: '100%', justifyContent: 'space-between'}}>
            <span className='consumption-chart-card-heading-text'>Verbräuche Gesamt</span>
            <Tabs value={activeTab} onTabChange={this.setActiveTab}>
              <Tabs.List>
                <Tabs.Tab value="first"><span className='consumption-chart-card-heading-month-selector'>3 Monat</span></Tabs.Tab>
                <Tabs.Tab value="second"><span className='consumption-chart-card-heading-month-selector'>6 Monat</span></Tabs.Tab>
                <Tabs.Tab value="third"><span className='consumption-chart-card-heading-month-selector'>9 Monat</span></Tabs.Tab>
                <Tabs.Tab value="fourth"><span className='consumption-chart-card-heading-month-selector'>12 Monat</span></Tabs.Tab>
              </Tabs.List>
            </Tabs>
        </Group>
        <Group className='consumption-chart-card-sub-heading'>
            <span className='consumption-chart-card-sub-heading-text'><span style={{color: this.props.highConsumption ? '#E8193C' : '#059266', fontWeight: '700'}}>{this.props.highConsumption ? '+'+this.props.percentage : '-'+this.props.percentage}</span> durchschnittlich niedriger als im Vorjahr</span>
        </Group>
        <Group grow>
            <div><canvas style={{width: 'auto', cursor: 'pointer' }} onMouseEnter={()=> this.onChartHoverInOut()} onMouseLeave={()=> this.onChartHoverInOut()} id="acquisitions"></canvas></div>
        </Group>
    </Stack>
  )}
}

export default ConsumptionChartCardClassComponent;